import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../Classes/global';

@Injectable({
  providedIn: 'root'
})
export class ChangepasswordService {
  formData: any;
  userFormData:any;
  readonly rootURL = GlobalVariable.ONLINE_CORE_URL;
  constructor(private http: HttpClient) { }

updateUserPasswordByAdmin()
{
    return this.http.put(this.rootURL + 'user/UpdateUserPasswordByAdmin/'+this.formData.empCode, this.formData)
}

updatePasswordByUser()
{
  return this.http.put(this.rootURL +'user/UpdatePasswordByUser/'+this.userFormData.userName,this.userFormData);
}

}
