import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Pizzamia';
  loginValue : any = null;
  constructor(){
    this.loginValue = JSON.parse(localStorage.getItem("isLogin"));
    this.loadScripts();    
    
  }
  // ngOnInit() {
    
  // }
  loadScripts() {
    const externalScriptArray = [
      '../assets/built_in/pages/js/pages.min.js',
      '../assets/js/jquery.nice-select.min.js'
    ];
    for (let i = 0; i < externalScriptArray.length; i++) {
      const scriptTag = document.createElement('script');
      scriptTag.src = externalScriptArray[i];
      scriptTag.type = 'text/javascript';
      scriptTag.async = false;
      scriptTag.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    }
  }
}

