
<div class="page-container ">
    <app-header></app-header>
    <div class="page-content-wrapper ">
      <div class="content ">
        <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <ul class="breadcrumb">
                  <li>
                    <p>Security</p>
                  </li>
                  <li><a class="active" style="cursor: pointer;">Page</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
        <div class="container-fluid container-fixed-lg">
          <!-- BEGIN PlACE PAGE CONTENT HERE -->

<div class="panel panel-transparent">
    <div class="panel-heading">
        <div class="panel-title">
            Page
        </div>

        <div class="pull-right m-t-15">
            <div id="show-modal" class="btn btn-success" (click)="redirectionFunction()">
                <i class="fa fa-plus pr1"></i>
                <a>Add New</a>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
<div>
<input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchText" style="width: 200px; float: right;" />
</div>
    <div class="panel-body">
        <table class="table table-striped cust_table_unSch">
            <thead>
                <tr>

                    <th>Sr.No</th>
                    <th>Module Name</th>
                    <th>Name</th>
                    <th>Page Name</th>
                    <th style="text-align:center;">Edit </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of pageList|filter:searchText| paginate:{itemsPerPage:10,currentPage:p};let i=index">
                    <td>{{i+1}}</td>
                    <td>{{row.moduleName}}</td>
                    <td>{{row.name}}</td>
                    <td>{{row.uiPageName}}</td>
                    
                    <td align="center">
                        <button (click)="populateForm(row)" type="button" class="btn btn-success btn-xs">
                            <i class="fa fa-pencil"></i>
                        </button>
                        <button type="button" class="btn btn-danger btn-xs">
                            <i class="fa fa-remove"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="5">
                        <div style="text-align: right;">
                            <pagination-controls (pageChange)="p=$event"></pagination-controls>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>


    </div>
</div>


</div>
<!-- END CONTAINER FLUID -->
</div>

</div>
<!-- END PAGE CONTENT WRAPPER -->
</div>

<div class="overlay hide" data-pages="search">
</div>
