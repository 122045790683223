import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../Classes/global';


@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  formData: any;
  readonly rootUrl = GlobalVariable.ONLINE_CORE_URL;
  constructor(private http: HttpClient) { }

  loadItemList(kitchenID){
    return this.http.get(this.rootUrl + 'item/GetItems/' + kitchenID )
  }
  
  insertItemsInfo(){
    return this.http.post(this.rootUrl + 'item/AddItem', this.formData)
  }
  updateItemsInfo(){
    return this.http.put(this.rootUrl + 'item/UpdateItem/'+ this.formData.itemID, this.formData)
  }

}
