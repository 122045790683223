<!-- <app-navbar></app-navbar> -->

<div class="page-container">
    
    <app-header></app-header>
    
    <div class="page-content-wrapper ">
        <div class="content ">
          <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <!-- START BREADCRUMB -->
                <ul class="breadcrumb">
                  <li>
                    <p>Devour</p>
                  </li>
                  <li><a class="active" style="cursor: pointer;">Kitchen</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
            <div class="container-fluid container-fixed-lg custom-container">
<div class="panel panel-transparent">
    <div class="panel-heading">
        <div class="panel-title">
            Kitchen
        </div>
        <div class="pull-right m-t-15">
            <div id="show-modal" class="btn btn-success" (click)="redirectionFunction()">
                <i class="fa fa-plus pr1"></i>
                <span>Add New</span>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>

    <div class="panel-body">  
        <div class="search_content">
            <input class="input-sm form-control topsearch" type="search" placeholder="Search" [(ngModel)]="searchText" style="width: 200px; float: right;border:1px solid rgba(0,0,0,0.07)">
        </div>                    
        <table id="example" class="table" style="width:100%">
            <thead>
                <tr>
                    <th>Restaurant Name</th>
                    <th style="width:10%;text-align:right">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let kitchen of kitchenList | filter:searchText">
                    <td>{{kitchen.name}}</td>
                    <td align="right">
                        <span class="btn btn-sm btn-success" (click)="updateForm(kitchen)">
                            <i class="fa fa-pencil"></i>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>


    </div>
</div>
</div>
</div>
<!-- Start Body -->
</div>

</div>