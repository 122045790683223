<!-- <app-navbar></app-navbar> -->

<div class="page-container">
    
    <app-header></app-header>
    
    <div class="page-content-wrapper ">
        <div class="content ">
          <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <!-- START BREADCRUMB -->
                <ul class="breadcrumb">
                  <li>
                    <p>Devour/p>
                  </li>
                  <li><a class="active" style="cursor: pointer;">Item</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
            <div class="container-fluid container-fixed-lg custom-container">


<div class="panel panel-transparent">
    <div class="panel-heading">
        <div class="panel-title">
            Item
        </div>
        <div class="pull-right m-t-15">
            <div id="show-modal" class="btn btn-success" (click)="redirectionFunction()">
                <i class="fa fa-plus pr1"></i>
                <span>Add New</span>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>

    <div class="panel-body">        
        
        
        <div class="search_content">
            <div class="form-group form-group-default form-group-default-select2 required" style="width: 700px; float: left;border:1px solid rgba(0,0,0,0.07)">
                <label class="">Restaurant</label>
                <ng-select bindLabel="name"
                    placeholder="Select Restaurant"
                    appendTo="body"                            
                    [searchable]="false" 
                    [clearable]="true"
                    [(ngModel)]="kitchenID"
                    name="KitchenID"
                    #KitchenID="ngModel"
                    required
                    (change)="loadItemList($event)"
                    [ngModelOptions]="{standalone: true}">                            
                    <ng-option [value]="item.kitchenID" *ngFor="let item of kitchenList">
                        {{item.name}}
                    </ng-option>
                </ng-select>                                          
            </div>

            <input class="input-sm form-control topsearch" type="search" placeholder="Search" [(ngModel)]="searchText" style="width: 200px; float: right;border:1px solid rgba(0,0,0,0.07)">
        </div>                
        <table id="example" class="table" style="width:100%">
            <thead>
                <tr>
                    <th style="width:30%">Item Name</th>
                    <th style="width:30%">Item Type</th>
                    <th style="width:10%">Have Flavour</th>
                    <th style="width:10%">Is Active</th>
                    <th style="width:20%;text-align:right">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of itemList | filter:searchText">
                    <td>{{item.name}}</td>
                    <td>{{item.itemType}}</td>
                    <td *ngIf="item.haveFlavour == 1">Yes</td>
                    <td *ngIf="item.haveFlavour == 0">No</td>
                    <td style="color: green;" *ngIf="item.isActive == 1">Active</td>
                    <td style="color: red;" *ngIf="item.isActive == 0">Inactive</td>
                    <td align="right">
                        <span class="btn btn-sm btn-success" (click)="updateForm(item)">
                            <i class="fa fa-pencil"></i>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>


    </div>
</div>
</div>
</div>
<!-- Start Body -->
</div>

</div>