import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ItemsService } from 'src/app/Services/items.service';
import { formatDate } from '@angular/common';
import { OrderService } from 'src/app/Services/order.service';
import { KitchenService } from 'src/app/Services/kitchen.service';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styles: []
})
export class ItemListComponent implements OnInit {
  userId:number;
  kitchenID: number;
  itemList: any = [];
  kitchenList: any = [];  
  editItem: any;
  searchText;

  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  constructor(private router: Router, public orderService: OrderService
    ,private kitchenService: KitchenService    
    ,private service: ItemsService) {
      this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
     }

  ngOnInit(){

    let value = JSON.parse(localStorage.getItem("isLogin"));
    debugger   
    if(value != null){
      
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId=value.employeeID;
        this.loadKitchens();
        

      }
      else {
        this.logoutFunction();
      }
    }
    else{

      this.router.navigate(["login"]);
    }   
  }
  
  updateForm(item: any){
    debugger

    if(item.haveFlavour == null){
      item.haveFlavour = 0;
    }

    this.editItem= {
      itemID: item.itemID,
      kitchenID: item.kitchenID,
      name: item.name,
      itemType: item.itemType,
      unitRate: item.unitRate,
      sizeId: item.sizeId,
      haveFlavour: item.haveFlavour,
      isActive: item.isActive
    }
    this.service.formData = Object.assign({}, this.editItem);
    this.router.navigate(["Item"]);
  }
  redirectionFunction()
  {
    this.router.navigate(["Item"]);
  }
  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);     
  }

  loadKitchens(){
    debugger
    this.kitchenService.getKitchens().subscribe((data)=>{ 
      debugger     
      this.kitchenList = data;
      this.kitchenID = this.kitchenList[0].kitchenID;
        this.loadItemList(this.kitchenID);

    })
  }

  loadItemList($event){
    this.kitchenID= $event;    
    this.service.loadItemList(this.kitchenID).subscribe((data)=>{
      debugger
      this.itemList = data;
    })
  }

}
