<div class="page-container">
    
    <app-header></app-header>
    
    <div class="page-content-wrapper ">
        <div class="content ">
          <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <!-- START BREADCRUMB -->
                <ul class="breadcrumb">
                  <li>
                    <p>Devour</p>
                  </li>
                  <li><a  class="active" style="cursor: pointer;">Total Ingredient</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
            <div class="container-fluid container-fixed-lg custom-container">


<div class="panel panel-transparent">
    <div class="panel-heading">
        <div class="panel-title">
            Total Ingredient List
        </div>
    </div>

    <div class="panel-body">
        <div class="row">
            <div class="col-lg-4">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-action div-info">
                        Total Ingredient
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Tinned Tomato
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Sugar
                    </li>                    
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Salt
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Oregano
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Vegetable oil
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Onions
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Ciarlic
                      </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Mozzarella
                    </li>
                    
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Parmesan
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Cheddar
                      </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Panir
                      </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Basil
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                          Jalapeno
                        </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                          Corn
                        </li>
                  </ul>
            </div>
            <div class="col-lg-4">
              <ul class="list-group">
                  <li class="list-group-item list-group-item-action div-info">
                      Total Ingredient
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Vinegar
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Chiken Breast
                  </li>                    
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                      Pepperoni
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Chilli Flakes
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Beef Mince
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Cumin
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Chilli Powder
                    </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Garlic Powder
                  </li>
                  
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                      Onion Powder
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                      Local Paprika
                    </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                      Black Pepper
                    </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                      Coriander
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Milk
                      </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Nutmeg
                      </li>
                </ul>
          </div>
          <div class="col-lg-4">
            <ul class="list-group">
                <li class="list-group-item list-group-item-action div-info">
                    Total Ingredient
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Oyster Mushrooms
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Shiitake
                </li>                    
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    Button
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Black Olives
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Shrimp
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Lemon
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Mayo
                  </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Tomatoes
                </li>
                
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Corn Flour
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  Shred Cheese
                  </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    Bacon
                  </li>
                <li class="list-group-item d-flex justify-content-between align-items-center">
                    Bread Crumbs
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                      Baguette
                    </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Pizza base
                    </li>
                    
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                    Wings
                    </li>
              </ul>
        </div>

        </div>
    </div>

</div>
</div>
</div>
<!-- Start Body -->
</div>

</div>