<!-- <app-navbar></app-navbar> -->

<div class="page-container">
    
    <app-header></app-header>
    
    <div class="page-content-wrapper ">
        <div class="content ">
          <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <!-- START BREADCRUMB -->
                <ul class="breadcrumb">
                  <li>
                    <p>Devour</p>
                  </li>
                  <li><a (click)="commonRedirectionFunction()" style="cursor: pointer;">Kitchen</a>
                  </li>
                  <li><a class="active">Kitchen Add</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
            <div class="container-fluid container-fixed-lg custom-container">

<div class="panel panel-transparent">
    <div class="panel-heading">
        <div class="panel-title">
            Restaurant
        </div>
    </div>

    <div class="panel-body">
        <div class="row xl-mlr10 clearfix">
            <form id="form-personal" #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 xl-mb30">
                    <input type="hidden" name="KitchenID" [value]="service.formData.kitchenID">

                    <div class="form-group form-group-default required">
                        <label>Restaurant Name</label>
                        <input name="Name" #Name="ngModel" [(ngModel)]="service.formData.name"
                                    class="form-control" placeholder="Kitchen Name" required />
                    </div>
                    
                    <div class="form-group form-group-default">
                        <label>Address</label>
                        <textarea name="Address" rows="4" class="form-control" #Address="ngModel" [(ngModel)]="service.formData.address"></textarea>
                    </div>
                   
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group">
                                <div class="form-inner12">
                                    <label for="fname" class="left-col control-label"></label>
                                    <div class="right-col mt-org1" style="text-align:right">
                                        <button class="btn btn-success custom-btn1" type="submit" [disabled]="form.invalid">Submit</button>
                                        <button submit="button" [disabled]="form.invalid" class="btn btn-default custom-btn1" name="Clear" (click)="clearData()"><i class="pg-close"></i> Clear</button>
                                    </div>
                                    <div class="clr"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            


            </form>
            
        
        
           
        </div>
        
    </div>
</div>

</div>
</div>
<!-- Start Body -->
</div>

</div>

