
<div class="page-container ">
    <!-- START HEADER -->
    <app-header></app-header>
    <div class="page-content-wrapper ">
      <div class="content ">
        <div class="jumbotron" data-pages="parallax">
          <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
            <div class="inner">
              <!-- START BREADCRUMB -->
              <ul class="breadcrumb">
                <li>
                  <p>Security</p>
                </li>
                <li (click)="redirectionFunction('UserList')" style="cursor: pointer;"><a class="active">User</a>
                </li>
                
                <li><a class="active">Add New</a>
                </li>
              </ul>
              <!-- END BREADCRUMB -->
            </div>
          </div>
        </div>
        <!-- END JUMBOTRON -->
        <!-- START CONTAINER FLUID -->
        <div class="container-fluid container-fixed-lg">
          <!-- BEGIN PlACE PAGE CONTENT HERE -->


<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)" id="form-personal">
    <div class="panel panel-transparent">
        <div class="panel-heading">
            <div class="panel-title">
                User
            </div>
        </div>
        <div class="panel-body">
            <div class="row xl-mlr10">
                <input type="hidden" name="UserID" [value]="service.formData.id" />
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 xl-mb30">

                    <div class="form-group form-group-default required">
                        <label>User Full Name</label>
                            <input type="text" name="UserFullName" class="form-control" #UserFullName="ngModel"
                            id="userFullName" [(ngModel)]="service.formData.userFullName"
                            placeholder="Max. 20 Characters." required />
                    </div>

                    <div class="form-group form-group-default required">
                        <label>User Name</label>
                            <input type="text" name="UserName" class="form-control" #UserName="ngModel"
                            id="userName" [(ngModel)]="service.formData.userName"
                            placeholder="Max. 20 Characters. User lowercase" required />
                    </div>

                    <div class="form-group form-group-default required">
                        <label>User Group</label>
                            <select class="form-control" name="UserGroupId" #UserGroupId="ngModel"
                                    [(ngModel)]="service.formData.userGroupId" id="UserGroupId" required>

                                    <option value="0">Select User Group</option>
                                    <option *ngFor="let u of userGrouplist" [attr.data-id]="u.id" [value]="u.id">
                                        {{u.name}}</option>
                                </select>
                    </div>

                    <div class="form-group form-group-default required">
                        <label>Address</label>
                            <input type="text" name="Address" class="form-control" #Address="ngModel"
                            id="address" [(ngModel)]="service.formData.address"
                            placeholder="Max. 50 Characters" required />
                    </div>

                    <div class="form-group form-group-default required">
                        <label>Email</label>
                            <input type="text" name="Email" class="form-control" #Email="ngModel"
                            id="email" [(ngModel)]="service.formData.email"
                            placeholder="Max. 50 Characters. Use lowercase"  required />
                    </div>

                    <div class="form-group form-group-default required">
                        <label>Phone No</label>
                            <input type="text" name="PhoneNo" class="form-control" #PhoneNo="ngModel"
                            id="phoneNo" [(ngModel)]="service.formData.phoneNo"
                            placeholder=" Use Digit"  required />
                    </div>

                    <div class="form-group form-group-default required">
                        <label>Password</label>
                                <input type="password" name="password" #password="ngModel"
                                [(ngModel)]="service.formData.password" id="password" class="form-control"
                                minlength="6" placeholder="Min. 6 Characters" required />
                    </div>
                    
                    <div class="form-group form-group-default required">
                        <label class="left-col control-label" [class.green-label]="ConfirmPassword.valid"
                                [class.red-label]="ConfirmPassword.invalid && ConfirmPassword.touched">Confirm
                                Password</label>
                                <input type="password" id="ConfirmPassword" name="ConfirmPassword"
                                [(ngModel)]="service.formData.confirmPassword"
                                appConfirmequalvalidator="password"
                                   #ConfirmPassword="ngModel" class="form-control " minlength="6"
                                   placeholder="Min. 6 Characters" required />
                                   <span *ngIf="service.formData.confirmPassword !='' && service.formData.confirmPassword != service.formData.password" [class.red-label]="service.formData.confirmPassword != service.formData.password">not match</span>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group">
                                <div class="form-inner12">
                                    <label for="fname" class="left-col control-label"></label>
                                    <div class="right-col mt-org1" style="text-align:right">
                                        <button class="btn btn-success custom-btn1" type="submit" [disabled]="form.invalid">Submit</button>
                                        <button submit="button" [disabled]="form.invalid" class="btn btn-default custom-btn1" name="Clear" (click)="clearData()"><i class="pg-close"></i> Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


</form>

</div>
</div>

</div>
</div>

<div class="overlay hide" data-pages="search">
</div>