import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/Services/login.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: []
})
export class LoginComponent implements OnInit {

  constructor(public service:LoginService,private router:Router, private toster:ToastrService) { }
  slideloginHeight = $(window).outerHeight();
  loginValue: any;
  public branchList: any = [];

  ngOnInit(): void {
    this.resetForm();
    localStorage.removeItem("isLogin");
    localStorage.clear();    

    debugger

    this.branchList = [{'BranchID': 0, 'Name': 'All'},{'BranchID': 1, 'Name': 'Banani'}, {'BranchID': 2, 'Name': 'Dhanmondi'}, {'BranchID': 3, 'Name': 'Uttara'}];
  }

  resetForm(form?: NgForm) {
    // debugger;
    this.service.formData = {
      branchID: '',
      userName: '',
      userPassword: ''
     }
   }

   onSubmit(form:NgForm){
    debugger;
   var isValidUser = false;
 
isValidUser = true;
   if(isValidUser){
    this.service.userlogin().subscribe(
      
      (response:any) => {
        debugger;
        if(response != null){
          this.resetForm(form);
          var token='Bearer '+ response.userInfo.token;
  
          localStorage.setItem('isLogin', JSON.stringify(response.userInfo));
          localStorage.setItem('acriveSMS',  JSON.stringify(response.smsText));
          localStorage.setItem('activeCoupons',  JSON.stringify(response.coupon));
  
          var counter = 0;
          localStorage.setItem('pageCounter', JSON.stringify(counter));
          this.router.navigate(["OrderList"]);
        }else{
          this.toster.error("Sorry! Invalid User ID, Password.");
        }
                
      },
      err => {
        debugger;
        console.log(err);
      }
    )
   }else{
    this.toster.error("Sorry! Invalid User ID, Password.");
   } 
  }

 changeBranchData = function (branchID) {
   debugger
   var id = branchID.value;
  localStorage.setItem('branchID',JSON.stringify(branchID.value));

 }

}
