

<nav class="page-sidebar" data-pages="sidebar" *ngIf="loginValue">
    <div class="sidebar-header">
        <a>
            <img src="../assets/img/Zurhem-logo1.png" alt="logo" class="brand" style="max-width:160px; height:50px;padding-left:10px">
        </a>
  
        <div class="sidebar-header-controls">
            <button type="button" class="btn btn-link visible-lg-inline" data-toggle-pin="sidebar" id="togglePin">
                <i class="fa fs-12"></i>
            </button>
        </div>
    </div>
    <!-- Left Side top Menu -->
    <!--- Menu -->
    <div class="sidebar-menu">
        <ul class="menu-items">
            <li *ngIf="showOrder" class="m-t-30 open active" style="cursor: pointer;" (click)="redirectionFunction('OrderList')">
                <a>Order</a>
                <span class="bg-success icon-thumbnail"><i class="fa fa-desktop" aria-hidden="true"></i></span>
            </li>
            <li *ngIf="showIngredient" (click)="redirectionFunction('Ingredient')"><a>Ingredient</a></li>  
            <li *ngIf="showTotalIngredient" (click)="redirectionFunction('TotalIngredientList')"><a>Total Ingredient</a></li>  
            <li *ngIf="customerList" (click)="redirectionFunction('CustomerList')"><a>Customer List</a></li>  
            <li *ngIf="showReports">
                <a href="javascript:void(0);">
                    <span class="">Reports</span>
                    <span class="arrow"></span>
                </a>
                <span class="icon-thumbnail"><i class="pg-menu_lv"></i></span>
                <ul class="sub-menu">
                    <li (click)="redirectionFunction('SalesReport')"><a>Sales Report</a></li>
                </ul>
                <!-- <ul class="sub-menu">
                    <li (click)="redirectionFunction('VATReport')"><a>VAT Report</a></li>
                </ul> -->
                <div class="clearfix"></div>
            </li>  
            <li *ngIf="showSettings">
                <a href="javascript:void(0);">
                    <span class="">Settings</span>
                    <span class="arrow"></span>
                </a>
                <span class="icon-thumbnail"><i class="pg-menu_lv"></i></span>
                <ul class="sub-menu">
                    <li (click)="redirectionFunction('KitchenList')"><a>Kitchen</a></li>  
                    <li (click)="redirectionFunction('ItemList')"><a>Items</a></li>  
                </ul>
                <div class="clearfix"></div>
            </li>
            <li *ngIf="showSecurity">
                <a href="javascript:void(0);">
                    <span class="">Security</span>
                    <span class="arrow"></span>
                </a>
                <span class="icon-thumbnail"><i class="pg-menu_lv"></i></span>
                <ul class="sub-menu">
                    <li (click)="redirectionFunction('UserList')"><a>User</a></li>
                    <li (click)="redirectionFunction('PageList')"><a>Page</a></li>
                    <li (click)="redirectionFunction('UserGroupEdit')"><a>Page Permission</a></li>
                    <li (click)="redirectionFunction('ChangePassword')"><a>Change Own Password</a></li>
                    <li (click)="redirectionFunction('PasswordChangeByAdmin')"><a>Reset Password</a></li>
  
                </ul>
                <div class="clearfix"></div>
            </li>
            <li *ngIf="showInventory" style="background-color: #80562c;"><a href="http://124.6.225.175:78/?redirectFrom=devour">Inventory System</a>           
            </li>
            <!-- <li style="background-color: #80562c;"><a href="http://localhost:1141?redirectFrom=devour">Inventory System</a>           
            </li> -->
        </ul>
        <div class="clearfix"></div>
    </div>
  
  </nav>


