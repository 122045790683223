import { Component, OnInit } from '@angular/core';
import { ChangepasswordService } from 'src/app/Services/changepassword.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  loginValue: any;
  counter: any;
  userId: any;

  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  
  constructor( public service:ChangepasswordService,private toastr: ToastrService,private router: Router) {
    this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
   }

  ngOnInit(): void {
    this.resetForm();

    this.loginValue = JSON.parse(localStorage.getItem("isLogin"));
    if (this.loginValue != null) {
      this.expirationTime = formatDate(this.loginValue.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId = this.loginValue.employeeID;
        console.log(this.userId);
      }
      else {
        this.logoutFunction();
      }

    }
    else {
      this.router.navigate(["login"]);
    }

  }
  resetForm(form?: NgForm) {
    if (this.service.userFormData == null) {
      this.service.userFormData = {
        userName: '',
        newPassword: '',
        oldPassword:'',
        updatedBy:1,
        confirmPassword:''
         
       }
    }
   }

   clearData(){
    this.resetForm();
  }

  onSubmit(form:NgForm){
    debugger;
    this.service.userFormData.userName = this.loginValue.userName; 
    this.service.userFormData.updatedBy = this.userId;

    if(this.service.userFormData.userName !='' || this.service.userFormData.userName !=null)
      {
        this.updatePasswordByUser(form);
      }
  }

  updatePasswordByUser(form:NgForm){
    //debugger
    this.service.updatePasswordByUser().subscribe(
      res => {
       // debugger;
        this.resetForm(form);
        this.toastr.success('updated');
        
      },
      err => {
        //debugger;
        console.log(err);
        this.toastr.error(err.message);
      }
    )
  }
  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);
      
  }



}
