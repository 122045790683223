<!-- <app-navbar></app-navbar> -->

<div class="page-container">
    
    <app-header></app-header>
    
    <div class="page-content-wrapper ">
        <div class="content ">
          <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <!-- START BREADCRUMB -->
                <ul class="breadcrumb">
                  <li>
                    <p>Devour</p>
                  </li>
                  <li><a (click)="commonRedirectionFunction()" class="active" style="cursor: pointer;">Customer</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
            <div class="container-fluid container-fixed-lg custom-container">
<div class="panel panel-transparent">
    <div class="panel-heading">
        <div class="panel-title">
            Customer
        </div>
    </div>

    <div class="panel-body">  
        <div class="search_content">
            <input class="input-sm form-control topsearch" type="search" placeholder="Search" [(ngModel)]="searchText" style="width: 200px; float: right;border:1px solid rgba(0,0,0,0.07)">
        </div>                  
        <table id="example" class="table" style="width:100%">
            <thead>
                <tr>
                    <th>Customer Name</th>
                    <th>Mobile</th>
                    <th>Address</th>
                    <th style="width:10%;text-align:right">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let b of customerList| paginate: { itemsPerPage: perPage, currentPage: p } | filter:searchText">
                    <td>{{b.name}}</td>
                    <td>{{b.mobile}}</td>
                    <td>{{b.address}}</td>
                    <td align="right">
                        <button class="btn btn-xs btn-success" (click)="updateForm(b)">
                            <i class="fa fa-pencil"></i>
                        </button>
                        <button class="btn btn-xs btn-primary" (click)="openCustomerOrderModal(b)" data-toggle="modal" data-target="#myModal">
                            <i class="fa fa-eye"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4" class="Page-align">
                        <div><pagination-controls (pageChange)="onPageChange($event)"></pagination-controls></div>
                    </td>
                </tr>
                
            </tfoot>
        </table>


    </div>
</div>

   <!-- The Modal -->
<div class="modal fade" id="myModal" data-backdrop="static" data-keyboard="false" role="dialog" aria-hidden="false">
    <div class="modal-dialog">
      <div class="modal-content">  
        <!-- Modal Header -->
        <div class="modal-header text-left">
            <button type="button" class="close" (click)="modalClose()" aria-hidden="true">
                <i class="pg-close fs-14"></i>
            </button>
            <div class="bold">Customer Order</div>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
            <table id="example" class="table table-bordered table-striped" style="width:100%;margin-top: 25px;">
                <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Item Type</th>
                        <th>Qty</th>
                        <th style="text-align: right;">Unit Rate</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let c of customerOrderList">
                        <td>{{c.itemName}}</td>
                        <td>{{c.itemType}}</td>
                        <td>{{c.quantity}}</td>
                        <td style="text-align: right;">{{c.unitRate}}</td>
                    </tr>
                </tbody>
                <tfoot *ngIf="customerOrderData">
                    <tr>
                        <td colspan="3" style="text-align: right;border-bottom: none;border-top: none;">Sub Total</td>
                        <td style="text-align: right;border-bottom: none;border-top: none;">{{customerOrderData.subTotal}}</td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: right;border-bottom: none;border-top: none;">Vat</td>
                        <td style="text-align: right;border-bottom: none;border-top: none;">{{customerOrderData.vat}}</td>
                    </tr>
                    <tr>
                        <td colspan="3" style="text-align: right;border-bottom: none;border-top: none;">Discount</td>
                        <td style="text-align: right;border-bottom: none;border-top: none;">{{customerOrderData.discount}}</td>
                    </tr>
                    <tr >
                        <td colspan="3" style="text-align: right;border-bottom: none;border-top: none;">Grand Total</td>
                        <td style="text-align: right;border-bottom: none;border-top: none;">{{customerOrderData.grandTotal}}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
  
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="modalClose()">Close</button>  
          <!-- this button is hidden, used to close from typescript -->
          <button type="button" id="close-modal" data-dismiss="modal" style="display: none">Close</button>
        </div>
      </div>
    </div>
  </div>

</div>
</div>
<!-- Start Body -->
</div>

</div>