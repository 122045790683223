import { Component, OnInit } from '@angular/core';
import { PagesService } from 'src/app/Services/pages.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-page-list',
  templateUrl: './page-list.component.html',
  styleUrls: ['./page-list.component.css']
})
export class PageListComponent implements OnInit {
  pageList:any=[];  
  p:number=1;
  userId: number;
  searchText;

  today = new Date();
  currentDateTime: any;
  expirationTime: any;
    constructor(public service:PagesService,private router:Router) { 
      this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
    }
  
    ngOnInit() {
      
      let value = JSON.parse(localStorage.getItem("isLogin"));
    if (value != null) {
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId = value.employeeID;
        this.getAllPages();
      }
      else {
        this.logoutFunction();
      }

    }
    else {
      this.router.navigate(["login"]);
    }
    }
    getAllPages(){
       debugger;
      this.pageList = [];
      this.service.getAllPages().subscribe((data) => {
        this.pageList = data;
      });
    }
    populateForm(pages: any) {
      debugger
      this.service.formData = Object.assign({}, pages);
      this.router.navigate(["Page"]);
    }
    redirectionFunction()
    {
      this.router.navigate(["Page"]);
    }
    logoutFunction(){
      localStorage.removeItem("isLogin");
      localStorage.clear();
      this.router.navigate(["login"]);
        
    }

}
