import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
declare var $: any;
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas';  

import { OrderService } from 'src/app/Services/order.service';
import { KitchenService } from 'src/app/Services/kitchen.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { DatePipe, formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerAdapterService } from 'src/app/Services/datepicker-adapter.service';
import { CommonService } from 'src/app/Services/common.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styles: [],
  providers: [DatePipe]
})

export class OrderComponent implements OnInit {
  userId:number;
  // includingTaxWithTotal: any;
  // deliveryCharge: any = 0;
  public kitchenList: any = [];
  public couponList: any =[];
  public smsList: any =[];
  public selectedKitchenID: any;
  public itemsList: any = [];

 // itemType : string = "Main Course";
//  public  isItemVisible: boolean=true;
//  @ViewChild('content') content:ElementRef;
//  public itemType:any =[];

  public qtyList: any = [];
  public orderDetailsDataModels: any = [];
  public orderDetailsEditDataModels: any = [];
  public orderDetailsList: any = [];

  public flavourList: any = [];
  public selectedFlavourList: any = [];
  public selectedFlavour: any;
  public tmpList: any = [];
  public flavour: any = {};
  public selectedItemID: any;
  public selectedItemName: any;
  public selectedItemNameFlavour: any;

  public orderFlavourDataModels: any = [];

  public deliveryTime : any;

  background = 'transparent';
  font = 'Montserrat';

  clDisabled : boolean = true;
  date: Date = new Date();

  searchValue: any;
  searchValueArea: any;
  fullAddress: any;
  area: any;
  //orderType: any;
  customerDropList: any = [];
  areaDropList: any = [];
  pageIndex: any = 1;
  pageSize: any = 10;

  reportOrderData: any;
  reportOrderDetailsData: any = [];
  countReceipt: any = [];
  countSticker: any = [];
  customerAddressList: any = [];
  customerAddress: any = { };

  today = new Date();
  currentDateTime: any;
  expirationTime: any;

  public branchList: any = [];

  //modal show hide
  isFriedChicken: any = true;
  isStripsChicken: any = true;
  isWingsChicken: any = true;
  isBurgerChicken: any = true;
  isBowlChicken: any = true;
  isSidesChicken: any = true;
  isDrink:any = true;
  isDips:any =true;


  constructor(public service: OrderService
    ,private kitchenService: KitchenService
    ,private commonService: CommonService
    ,private toastr: ToastrService
    ,private router: Router
    ,private datePipe: DatePipe
    ,private ngbCalendar: NgbCalendar
    ,private datepicker: DatepickerAdapterService) { 
      this.resetForm();
      this.resetParameters();
      this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
    }

  ngOnInit(){
    
    let value = JSON.parse(localStorage.getItem("isLogin"));
    this.service.formData.disableSubmit = false;
    if(value != null){
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {        
        if(!this.service.formData){
          this.resetForm();
        }
        this.userId=value.employeeID;

        //this.branchList = [{'BranchID': '0', 'Name': 'Select Branch'},{'BranchID': 1, 'Name': 'Baridhara'}, {'BranchID': 2, 'Name': 'Dhanmondi'}, {'BranchID': 3, 'Name': 'Uttara'}];
        this.branchList = [{'BranchID': '0', 'Name': 'Select Branch'},{'BranchID': 1, 'Name': 'Banani'}, {'BranchID': 2, 'Name': 'Dhanmondi'}, {'BranchID': 3, 'Name': 'Uttara'}];
        this.service.formData.branchID = this.branchList[0].BranchID;

        this.searchArea();
        this.loadKitchens();
        this.setDiscount(); 
        this.loadQuntitys(100);  
        this.loadCoupon();
        
       
        if (this.service.formData.orderID) {
          this.getKitchenItemDetails(this.service.formData.kitchenID);     
          this.getOrderDetailsInformation(this.service.formData.orderID);
          this.fillCustomerDropdownEdit();          
          this.searchValue = this.service.formData.customerName;
          this.fullAddress = this.service.formData.fullAddress;
        }
      }
      else {
        //this.logoutFunction();
      }
      
    }
    else{
      this.router.navigate(["login"]);
    }      

  }

  setDiscount= function(){     
    this.service.formData.coupon = null;
    let loginInfo = JSON.parse(localStorage.getItem("isLogin"));
    if(loginInfo.couponValue > 0 && loginInfo.isAuto == '1' && loginInfo.kitchenID == '0'){ // KitchenID == '0' for all kitchen
      this.service.formData.discount = loginInfo.couponValue;
      this.service.formData.coupon = loginInfo.couponCode;
    }
  }

  //pdf
  // @ViewChild('report_viewer') content: ElementRef;

      //Search Dropdown
      searchCustomerDropDown(){        
        if (this.searchValue.length > 2) {
          this.service.parameters.searchValue = this.searchValue;
          this.service.parameters.pageSize = this.pageSize;
          this.service.parameters.pageIndex = this.pageIndex;
          this.service.formData.customerName = this.searchValue;
          this.searchCustomerList(this.service.parameters);
        }else{
          this.customerDropList = [];
          this.service.formData.customerName = "";
          this.service.formData.customerID = 0;
          this.service.formData.customerMobile = "";
          this.service.formData.flatNo = "";
          this.service.formData.houseNo = "";
          this.service.formData.apartmentNo = "";
          this.service.formData.roadNo = "";
          this.service.formData.area = "";
        }        
      }

      //Search Dropdown
      searchAddressDropDown(){        
        if (this.fullAddress.length > 2) {
          this.service.parameters.fullAddress = this.fullAddress;          
          this.searchCustomerList(this.service.parameters);
        }else{
          this.customerAddressList = [];
          this.service.formData.fullAddress = "";
          this.service.formData.flatNo = "";
          this.service.formData.houseNo = "";
          this.service.formData.apartmentNo = "";
          this.service.formData.roadNo = "";
          this.service.formData.area = "";
        }        
      }

      searchArea(){ 
        this.searchAreaDropDown('');       
        // if (this.searchValueArea.length > 2) {
        //   this.searchAreaDropDown(this.searchValueArea);
        // }else{
        //   this.areaDropList = [];
        //   this.service.formData.area = "";
        // }        
      }

      searchCustomerList(customer: any){[
        this.service.searchCustomerList().subscribe((data) =>{
          this.customerDropList = data;
        })
      ]} 
      
      searchCustomerAddressList(customer: any) {[
        this.service.getCustomerAddress().subscribe((data) =>{
          this.customerAddressList = data;
        })
      ]}
      
      searchAreaDropDown(keyword) {
        var branchID = this.service.formData.branchID;
        this.service.getArea(keyword, branchID).subscribe((data) =>{
          debugger          
          this.areaDropList = data;
          for(var i = 0; i< this.areaDropList.length; i++){
            this.areaDropList[i].areaTime = this.areaDropList[i].areaName + ' - ' + this.areaDropList[i].deliveryTime
          }

        })
      }

      setValueArea = function () {
        debugger
        if(this.service.formData.areaID > 0) {
          var area = this.areaDropList.filter( x => x.areaID == this.service.formData.areaID);
          //this.deliveryTime = area[0].deliveryTime;
          this.service.formData.requiredDeliveryTime = area[0].deliveryTime;
          this.service.formData.area = area[0].areaName;
          this.searchValueArea = area[0].areaName;
          localStorage.setItem('areaName', JSON.stringify(area[0].areaName));

        }       
      }

  //   setDeliveryTime = function (index, $event) {
  //     debugger
  //     this.service.formData.area = this.areaDropList[index].areaName;
  //     this.searchValueArea = this.areaDropList[index].areaName;;
  //     this.areaDropList = [];
  //     $event.stopPropagation();          
  // }

      searchBoxClicked = function ($event) {
          $event.stopPropagation();
          //this.fullAddress = null;
      }
      // Set value to search box
      setValue = function (index, $event) {
        var customerName = this.customerDropList[index].name.split('(')[0];        
          this.searchValue = this.customerDropList[index].name;
          this.service.formData.customerName = customerName;
          this.service.formData.customerID = this.customerDropList[index].customerID;
          this.service.formData.customerMobile = this.customerDropList[index].mobile;
          this.service.formData.customerAddress = this.customerDropList[index].address;
          this.customerDropList = [];
          debugger
          this.loadCustomerAddress();
          $event.stopPropagation();          
      }      

      fillCustomerDropdownEdit = function () {        
          var orderInfo = this.service.formData;        
          //var customerName = orderInfo.customerName;        
          this.searchValue = orderInfo.customerName;
          this.service.formData.customerName = orderInfo.customerName;
          this.service.formData.customerID = orderInfo.customerID;
          this.service.formData.customerMobile = orderInfo.customerMobile;
          this.service.formData.customerAddress = orderInfo.fullAddress;
          this.service.formData.addressID = orderInfo.addressID;
         
          this.customerDropList = [];
          
          this.loadCustomerAddress();          
          //$event.stopPropagation();          
      }

      // Set value to search box
      setAddressDtls = function () {   
             
        if(this.service.formData.addressID > 0){
          this.customerAddress = this.customerAddressList.filter( x => x.addressID == this.service.formData.addressID);
          
          this.service.formData.flatNo = this.customerAddress[0].flatNo;
          this.service.formData.houseNo = this.customerAddress[0].houseNo;
          this.service.formData.apartmentNo = this.customerAddress[0].apartmentNo;
          this.service.formData.roadNo = this.customerAddress[0].roadNo;
          this.service.formData.block = this.customerAddress[0].block;

          if(this.customerAddress[0].areaID > 0){
            var area = this.areaDropList.filter( x => x.areaID == this.customerAddress[0].areaID);            
            this.service.formData.requiredDeliveryTime = area[0].deliveryTime;            
            this.service.formData.areaID = area[0].areaID;
            this.service.formData.area = area[0].areaName;
            localStorage.setItem('areaName', JSON.stringify(area[0].areaName));
          }
          this.searchValueArea =  this.customerAddress[0].area;
        }else{
          this.service.formData.addressID = 0;
          this.service.formData.flatNo = '';
          this.service.formData.houseNo = '';
          this.service.formData.apartmentNo = '';
          this.service.formData.roadNo = '';
          this.service.formData.block = '';
          this.service.formData.area = '';
        }               
        event.stopPropagation();           
    }

    
    

  loadKitchens(){
    debugger
    this.kitchenService.getKitchens().subscribe((data)=>{ 
      debugger     
      this.kitchenList = data;
      if(!this.service.formData.orderID){
        this.service.formData.kitchenID = this.kitchenList[0].kitchenID;
        this.getKitchenItemDetails(this.service.formData.kitchenID);  
      }
    })
  }

  loadCoupon(){    
    this.kitchenService.getCoupons().subscribe((data)=>{            
      this.couponList = data;
    })
  }

  // loadCustomerAddress(){
  //   this.service.getCustomerAddress().subscribe((data) => {
  //     debugger
  //     this.customerAddressList = data; 
  //   })
  // }

  loadCustomerAddress(){
    debugger
    this.service.getCustomerAddress().subscribe((data) => {
      debugger
      this.service.formData.fullAddress = null;

      this.customerAddressList = data; 
      if(this.service.formData.orderID > 0 && this.customerAddressList.length > 0){
        this.setAddressDtls();
      }      
    })
  }


  loadQuntitys(id: any){
    for (let index = 1; index < id; index++) {
      this.qtyList.push({
        id: index,
        qtyName: index
      });
      
    }
  }
  serviceCharge=0;
  serviceNotCharge=0;
 

  deliveryChargeCount = 0;
  deliveryNotChargeCount = 0;
  checkOrderType(orderType: any){
    debugger
    if(orderType == 1){
      this.deliveryChargeCount = 1;
      this. deliveryNotChargeCount = 0;      
      if(!this.service.formData.deliveryCharge){
        //this.service.formData.deliveryCharge = 50;
        this.service.formData.deliveryCharge = 80;
        this.service.formData.grandTotal = this.service.formData.grandTotal + this.service.formData.deliveryCharge;
      }      
    }

    else if(orderType == 7){
      this.deliveryChargeCount = 0;
      this.deliveryNotChargeCount = 1;      
      if(this.service.formData.deliveryCharge){        
        this.service.formData.grandTotal = this.service.formData.grandTotal - this.service.formData.deliveryCharge;
        this.service.formData.deliveryCharge = 0;
      }      
    }
    else if(orderType == 8){
      this.deliveryChargeCount = 0;
      this.deliveryNotChargeCount = 1;      
      if(this.service.formData.deliveryCharge){        
        this.service.formData.grandTotal = this.service.formData.grandTotal - this.service.formData.deliveryCharge;
        this.service.formData.deliveryCharge = 0;
      }      
    }       
    else{
      this.deliveryChargeCount = 0;
      this. deliveryNotChargeCount = 1;
      if(this.service.formData.deliveryCharge){
        this.service.formData.grandTotal = this.service.formData.grandTotal - this.service.formData.deliveryCharge;
        this.service.formData.deliveryCharge = 0;
      }
      
    }
    this.calculateDiscount();
  }
  customerModal(){
    this.customerName();
  }

  getKitchenItemDetails($event){    
    debugger
    this.service.formData.kitchenID = $event;    
    // this.isFriedChicken = false;
    // this.isStripsChicken = false;
    // this.isWingsChicken = false;
    // this.isBurgerChicken = false;
    // this.isBowlChicken = false;
    // this.isSidesChicken = false;
    // this.isDrink = false;
    // this.isDips = false;

    // if (this.service.formData.kitchenID == 2) {
    //   this.isFriedChicken = true;
    //   this.isStripsChicken = true;
    //   this.isWingsChicken = true;
    //   this.isBurgerChicken = true;

    //   this.isDrink = true;
    //   this.isDips =true;
    //   this.isSidesChicken = false;
    // }
    // else if (this.service.formData.kitchenID == 6) {
    //   this.isBowlChicken = true;
      
    // }
    // else if (this.service.formData.kitchenID == 3) {
    //   this.isSidesChicken = true;
      
    // }
    // else if (this.service.formData.kitchenID== 4){
    //   this.isWingsChicken=true;
    // }

    this.service.getKitchenItemDetails().subscribe((data)=>{     
      
      this.itemsList = data;
      for(let item of this.itemsList){
        item.total = item.unitRate;
        item.selected = false;
        item.qtyDisabled = true;
      }
      if (this.service.formData.orderDetailsDataModels.length > 0) {
        for(let details of this.itemsList){
          debugger
          var item =  this.service.formData.orderDetailsDataModels.filter(function(d) {return d.itemID == details.itemID;})[0];
            if (item != null && item.itemID > 0) {
              details.selected = true;
              details.qtyDisabled = false;
              details.total = item.unitRate;
              details.quantity = item.quantity;
              details.itemNameNflavour = item.itemNameNflavour;
              this.changeItemData(item);
            }
        }
      }
    })
  }

  

  getOrderDetailsInformation(id:any){
    this.service.formData.orderDetailsDataModels = [];
    this.service.loadSalesOrderDetailsInformation().subscribe((data)=>{
      this.orderDetailsList = data;      
      for(let details of this.orderDetailsList){
        this.service.formData.orderDetailsDataModels.push({
          orderDtlsID: details.orderDtlsID,
          orderID: details.orderID,
          itemID: details.itemID,
          quantity: details.quantity,
          unitRate: details.unitRate,
          itemNameNflavour: details.itemNameNflavour
        })
      }
      if (this.itemsList.length > 0) {
        for(let details of this.itemsList){          
          var item =  this.service.formData.orderDetailsDataModels.filter(function(d) {return d.itemID == details.itemID;})[0];
            if (item != null && item.itemID > 0) {
              details.selected = true;
              details.qtyDisabled = false;
              details.total = item.unitRate;
              details.quantity = item.quantity;
              details.itemNameNflavour = item.itemNameNflavour;
              this.changeItemData(item);
            }
        }
      }
    })
  }
  
  createItemsData = function (sl, item) {
    debugger
    this.service.formData.subTotal = 0;
    this.service.formData.vat = 0;
    this.service.formData.discount = 0;   
    this.service.formData.incomeTax = 0;
    this.service.formData.serviceCharge = 0;
    this.service.formData.grandTotal = 0;
      if (item.selected == true) {
        item.qtyDisabled = false;
        item.quantity = 1;
        if (this.itemsList.length > 0) {
          for (let j = 0; j < this.itemsList.length; j++) {
            if (this.itemsList[j].itemID == item.itemID) {
                this.itemsList[j].total = this.itemsList[j].unitRate * item.quantity;
            }
          }
        }

        this.service.formData.orderDetailsDataModels.push({
            orderDtlsID: 0,
            orderID: 0,
            itemID: item.itemID,
            // kitchenID: item.itemType.kitchenID,
            kitchenID: item.kitchenID,
            itemName: item.name + ' ' + item.itemType,
            quantity: item.quantity,
            
            unitRate: item.total
        });

          // Open Flavour Choose Modal 
          //this.openFlavourPopup(item);
          
        
      } else {
          item.qtyDisabled = true;
          for (let i = 0; i < this.service.formData.orderDetailsDataModels.length; i++) {
              if (this.service.formData.orderDetailsDataModels[i].itemID == item.itemID) {
                this.service.formData.orderDetailsDataModels.splice(i, 1);
                this.orderDetailsEditDataModels.push(item.itemID);
              }
          }          
      }    
      if (this.service.formData.orderDetailsDataModels.length > 0) {
          for (let j = 0; j < this.service.formData.orderDetailsDataModels.length; j++) {
              this.service.formData.subTotal += this.service.formData.orderDetailsDataModels[j].unitRate;
              this.service.formData.incomeTax = (this.service.formData.subTotal * 5)/100;
              if (this.service.formData.orderType == 8) {
                this.service.formData.serviceCharge = (this.service.formData.subTotal * 7.5) /100;
              } 

              this.service.formData.grandTotal = (this.service.formData.subTotal + this.service.formData.incomeTax  + this.service.formData.serviceCharge + this.service.formData.discount + this.service.formData.deliveryCharge);
          }          
      }  

      this.selectedItemID = item.itemID;
      this.calculateDiscount();
}

    //Change Edit Items List
    changeItemData = function (item) {
      debugger
      this.service.formData.subTotal = 0;
      this.service.formData.vat = 0;
      this.service.formData.discount = 0;
      this.service.formData.incomeTax = 0;
      this.service.formData.serviceCharge = 0;
      this.service.formData.grandTotal = 0;
      for (let j = 0; j < this.itemsList.length; j++) {
          if (this.itemsList[j].itemID == item.itemID) {
              this.itemsList[j].total = this.itemsList[j].unitRate * item.quantity;

              for (let i = 0; i < this.service.formData.orderDetailsDataModels.length; i++) {
                  if (this.service.formData.orderDetailsDataModels[i] != null && this.service.formData.orderDetailsDataModels[i].itemID == item.itemID) {
                      if (item.quantity) {
                        this.service.formData.orderDetailsDataModels[i].unitRate = this.itemsList[j].unitRate * item.quantity;
                      }
                      this.service.formData.orderDetailsDataModels[i].quantity = item.quantity;                    
                      
                  }
                //   if (this.service.formData.orderType == 8) {
                //     this.service.formData.serviceCharge = (this.service.formData.subTotal * 5) / 100;

                // } 
                
                  if (this.service.formData.orderDetailsDataModels[i] != null) {
                    this.service.formData.subTotal += this.service.formData.orderDetailsDataModels[i].unitRate;
                    this.service.formData.incomeTax = (this.service.formData.subTotal * 5)/100;
                      if (this.service.formData.orderType == 8) {
                        this.service.formData.serviceCharge = (this.service.formData.subTotal * 7.5) / 100;
                      }
                    else{
                      this.service.formData.serviceCharge =(this.service.formData.grandTotal * 0);
                    }

                    this.service.formData.grandTotal = (this.service.formData.subTotal + this.service.formData.incomeTax  + this.service.formData.discount +this.service.formData.serviceCharge +  this.service.formData.deliveryCharge);
                  }
              }

              // Open Flavour Choose Modal 
              //this.openFlavourPopup(item);
              this.calculateDiscount();
              return;
          }
          
      }
      
      this.service.formData.orderDetailsDataModels.push({
          orderDtlsID: item.orderDtlsID,
          orderID: item.orderID,
          itemName: item.Name,
          itemID: item.itemID,
          quantity: item.quantity,
          unitRate: item.unitRate
      });      
      
  }

  customerName(){
    if (this.service.formData.customerName && this.service.formData.customerMobile)
        {
            this.clDisabled = false;
        } else {
            this.clDisabled = true;
        }
  }

  _keyPress(event: any) {
    debugger
    // const pattern = /[0-9\+\-\ ]/;
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) || this.service.formData.customerMobile.length > 10) {
      event.preventDefault();      
    }
    this.customerName(); 
  }
  
  // openCustomerModal() {    
  //   this.service.formData = {
  //     id: user.customerID
  //   }
  //  }
   modalClose():void{
    document.getElementById('close-modal').click();
   }

  resetForm(form?: NgForm){
    
    if(this.service.formData == null){
      this.service.formData= {
        orderID: 0,
        branchID: 0,
        orderDate: this.ngbCalendar.getToday(),
        orderType: 0,
        kitchenID: null,
        customerID: 0,
        addressID:0,
        customerName: "",
        customerMobile: "",
        flatNo: "",
        houseNo: "",
        apartmentNo: "",
        roadNo: "",
        area: "",
        coupon: "",
        orderTime: "",
        orderStatus: "",
        preparingStartBarCode: "",
        foodReadyBarCode: "",
        dispatchedBarCode: "",
        searchBarCode: "",
        subTotal: 0,
        vat: 0,
        discount: 0,
        deliveryCharge: 0,
        incomeTax: 0,
        serviceCharge: 0,
        grandTotal: 0,
        orderDetailsDataModels: [],
        orderFlavourDataModels: [],
        deliveryTime: "",
        requiredDeliveryTime: "",
        comments: ""
      }
    }
    this.searchValueArea = null;
  }

  clearData(){
    this.resetForm();
    this.searchValue = "";
    this.selectedFlavourList = [];
    this.getKitchenItemDetails(1);
  }

  onSubmit(form: NgForm){ 
    //var branchID = JSON.parse(localStorage.getItem("branchID"));
    //this.service.formData.branchID = branchID;    
    if(!this.searchValue || this.searchValue == ""){
      this.toastr.error("Please select customer name !!!");
      return
    }      
      this.service.formData.orderDate = this.datepicker.toModel(this.service.formData.orderDate);
      this.service.formData.orderStatus = 0;
         
    if (this.service.formData.orderID == 0) {
      if ((this.service.formData.orderDetailsDataModels.length > 0) && (this.service.formData.orderType > 0) && (this.service.formData.areaID > 0)){        
        this.service.formData.orderTime = formatDate(this.date, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600'); 
        this.service.formData.area = this.searchValueArea;         
        
        //if(this.service.formData.customerID == 0  && this.service.formData.kitchenID == 3 && this.service.formData.coupon == null)
        if(this.service.formData.customerID == 0 && this.service.formData.coupon == null)
        {
          //this.toastr.error("New customer have discount offer from pimentos, Please select discount.");
          this.toastr.error("New customer have discount offer, Please select discount.");
          this.service.formData.orderDate = this.ngbCalendar.getToday();
        }else{
          this.insertOrderInformation(form);
        }     

        
      }else{
        this.toastr.error("Please fill-up all required filed !!!");
        this.service.formData.orderDate = this.ngbCalendar.getToday();
      }
    }else{
      
        if ((this.service.formData.orderDetailsDataModels.length > 0) && (this.service.formData.orderType > 0) && (this.service.formData.areaID > 0)) {          
          this.updateOrderInformation(form);
        }else{
          this.toastr.error("Please fill-up all required filed !!!");
        }       
    }   
  }

  insertOrderInformation(form: NgForm){
    this.service.insertOrderInformation().subscribe(res=>{
      this.service.formData.disableSubmit = false;
      this.toastr.success("Save Successfully");      
      this.countReceipt = [];
      this.countSticker = [];
      let isConfirmed = true;
      if (isConfirmed) {
        this.reportOrderData = res;
        this.orderWiseAllOrderInformation(this.reportOrderData.orderDataModels.orderID); 
        this.selectedKitchenID = this.service.formData.kitchenID;
      }      
      // Sending greeting message for First time order.
      if(this.service.formData.customerID == 0){  // kamrul for testing        
        this.sentSMSForFirstTimeOrder(this.service.formData);
      }  
      
      this.service.formData = null;
      this.itemsList = [];
      this.clearData();  
    },
    err=>
    {
      this.toastr.error(err);
    } 
    )
  }

  updateOrderInformation(form: NgForm){
    this.service.updateOrderInformation().subscribe(res=>{
      this.toastr.success("Update Successfully");      
      this.countReceipt = [];
      this.countSticker = [];
      let isConfirmed = true;
      if (isConfirmed) {  
        this.reportOrderData = res;
        this.orderWiseAllOrderInformation(this.reportOrderData.orderDataModels.orderID);        
        this.selectedKitchenID = this.service.formData.kitchenID;
      }
      this.service.formData = null;      
      this.itemsList = [];
      this.clearData();
      //this.setDiscount();
    },
    err=>
    {
      debugger
      this.toastr.error(JSON.stringify(err));
      this.service.formData.orderDate = this.ngbCalendar.getToday();
    } 
    )
  }

  orderWiseAllOrderInformation2(id: any) {

    debugger
    this.service.parameters.orderID = id;
    this.service.orderWiseAllOrderInformation().subscribe((data) => {
      
      this.countReceipt = [];
      this.countSticker = [];
      this.reportOrderData = data;
        if (this.reportOrderData.orderDataModels.orderType == 8) {
         this.reportOrderData.orderDataModels.serviceCharge = (this.reportOrderData.orderDataModels.subTotal * 7.5) / 100; 
        } else{
          this.reportOrderData.orderDataModels.serviceCharge = (this.reportOrderData.orderDataModels.grandTotal * 0); 
        }
      debugger
      this.reportOrderData.orderDataModels.grandTotal = (this.reportOrderData.orderDataModels.subTotal  + this.reportOrderData.orderDataModels.serviceCharge + this.reportOrderData.orderDataModels.deliveryCharge) - this.reportOrderData.orderDataModels.discount;
      // 15% tax Calculation
      //this.reportOrderData.orderDataModels.incomeTax = ((this.reportOrderData.orderDataModels.subTotal * 10)/100);
      //  this.reportOrderData.orderDataModels.serviceCharge = ((this.reportOrderData.orderDataModels.subTotal * 5)/100);

      this.reportOrderData.orderDataModels.orderTypeName = this.commonService.convertOrderTypeText(this.reportOrderData.orderDataModels.orderType);
      this.reportOrderData.orderDataModels.kitchenName = this.commonService.convertKitchenText(this.reportOrderData.orderDataModels.kitchenID);
      
      this.countReceipt.push(this.reportOrderData.orderDataModels.preparingStartBarCode);
      this.countReceipt.push(this.reportOrderData.orderDataModels.foodReadyBarCode);
      this.countReceipt.push(this.reportOrderData.orderDataModels.dispatchedBarCode);
      // this.countReceipt.push(this.reportOrderData.orderDataModels.searchBarCode);
 
      this.reportOrderData.orderDataModels.customerAddress = "";
        if(this.reportOrderData.orderDataModels.flatNo){
          this.reportOrderData.orderDataModels.customerAddress += "Flat " + "#" + this.reportOrderData.orderDataModels.flatNo;
        }
        if(this.reportOrderData.orderDataModels.houseNo){
          this.reportOrderData.orderDataModels.customerAddress += ", " + "House " + "#" + this.reportOrderData.orderDataModels.houseNo;
        }       
        if(this.reportOrderData.orderDataModels.roadNo){
          this.reportOrderData.orderDataModels.customerAddress +=  ", " +"Road " + "#" +  this.reportOrderData.orderDataModels.roadNo;
        }
        if(this.reportOrderData.orderDataModels.block){
          this.reportOrderData.orderDataModels.customerAddress += ", " + "Block " + " - " + this.reportOrderData.orderDataModels.block;
        } 
        if(this.reportOrderData.orderDataModels.area){
          this.reportOrderData.orderDataModels.customerAddress += ", " + "Area" + " - " + this.reportOrderData.orderDataModels.area;
        }


        //this.reportOrderData.orderDetailsDataModels
        for(var i =0; i <this.reportOrderData.orderDetailsDataModels.length; i++){
          if(this.reportOrderData.orderDetailsDataModels[i].itemNameNflavour == null){
            this.reportOrderData.orderDetailsDataModels[i].itemNameNflavour = this.reportOrderData.orderDetailsDataModels[i].itemName;
          } 
        }      
      $("#myModalReport").modal('show');
    })
  }

  resetParameters(){
    this.service.parameters = {
      searchValue: "",
      pageSize: 0,
      pageIndex: 0
    }
  }

  reportClose():void{
    document.getElementById('closeModalReport').click();
   }

  captureScreen(){  
    debugger
    const printContent = document.getElementById("report_viewer");
    const WindowPrt = window.open('', '_blank', 'left=0,top=0,toolbar=0,scrollbars=0,height=100%,width=auto,status=0');
    WindowPrt.document.open();  
    WindowPrt.document.write(printContent.innerHTML);   
    WindowPrt.document.close();  
    WindowPrt.print();  
  } 

  commonRedirectionFunction() {
    this.router.navigate(["OrderList"]);
  }

  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.removeItem("branchID");
    localStorage.clear();
    this.router.navigate(["login"]);      
  }

  // Start:: Choose Flavour Modal Function  

  flavourModalClose():void{
    document.getElementById('closeFlavourModal').click();
   }


   loadFlavours(item) {
    this.service.getFlavours().subscribe((data) => { 

      debugger

      this.tmpList = data;

      this.tmpList = this.tmpList.filter( x => x.kitchenID == item.kitchenID);
      this.flavourList = [];
      this.selectedItemID = item.itemID;    

      for (let i = 0; i < this.tmpList.length; i++) {
        this.flavour.flavourID = this.tmpList[i].flavourID;
        this.flavour.flavour = this.tmpList[i].flavour;
        this.flavour.description = this.tmpList[i].description;
        this.flavour.flavourQty = '';

        debugger
        
        // var selectedFlavour =  this.selectedFlavourList.filter(function(d) {return d.flavourID == this.flavour.flavourID;})[0];
        this.selectedFlavour = this.selectedFlavourList.filter( x => x.flavourID == this.flavour.flavourID && x.itemID == item.itemID)[0];

        if(this.selectedFlavourList.length > 0 && this.selectedFlavour){
            this.flavour.selected = true;
            this.flavour.itemID = this.selectedFlavour.itemID;
            this.flavour.flavourQty = this.selectedFlavour.flavourQty;
        }
        else{
          this.flavour.selected = false;
          this.flavour.itemID = '0';
        }
        
        this.flavourList.push(this.flavour);
        this.flavour = {};
      }
    })
  }

  createFlavourData = function (sl, item) {      

    debugger
    // Remove existing flavour
    if(item.itemID && item.itemID > 0){
      this.selectedItemID = item.itemID; 
    }


    for (let i = 0; i < this.service.formData.orderFlavourDataModels.length; i++) {
      if (this.service.formData.orderFlavourDataModels[i].flavourID == item.flavourID && this.service.formData.orderFlavourDataModels[i].itemID == this.selectedItemID ) {
        this.service.formData.orderFlavourDataModels.splice(i, 1);
        //this.orderDetailsEditDataModels.push(item.itemID);
      }
  }  

  // Remove existing flavour from selectedFlavourList
  for (let i = 0; i < this.selectedFlavourList.length; i++) {
    if (this.selectedFlavourList[i].flavourID == item.flavourID && this.selectedFlavourList[i].itemID == this.selectedItemID) {
      this.selectedFlavourList.splice(i, 1);
      //this.selectedFlavourList[i].selected = false; 
    }
  } 
  
      item.flavourQty = '';
      if (item.selected == true) {
        item.flavourQty = 1;        
  
        this.service.formData.orderFlavourDataModels.push({
          salesOrderFlavourID: 0,
          orderID: 0,
          itemID: this.selectedItemID,
          flavourID: item.flavourID,
          flavour: item.flavour,
          flavourQty: item.flavourQty
        }); 
        
        item.itemID = this.selectedItemID;
      // build itemName with flavour Qty
      this.createItemNameWithFlavour(item, 0);

      for (let i = 0; i < this.flavourList.length; i++) {
        if (this.flavourList[i].flavourID == item.flavourID) {
          this.flavourList[i].selected = true; 
          this.flavourList[i].itemID = item.itemID;
          this.selectedFlavourList.push(item);
        }
      }        
    }      

}
  
  changeFlavourQty = function(sl, item){
    debugger
    if (item.selected == true){
      for (let i = 0; i < this.service.formData.orderFlavourDataModels.length; i++) {
        if (this.service.formData.orderFlavourDataModels[i].flavourID == item.flavourID && this.service.formData.orderFlavourDataModels[i].itemID == item.itemID) {
            this.service.formData.orderFlavourDataModels[i].flavourQty = item.flavourQty;             
        }

        if (this.selectedFlavourList[i].flavourID == item.flavourID && this.selectedFlavourList[i].itemID == item.itemID) {
          this.selectedFlavourList[i].flavourQty = item.flavourQty;             
         }
      }
      
      this.createItemNameWithFlavour(item, item.flavourQty);
    }else{
      item.flavourQty = '';
    }    
  }

  openFlavourPopup = function(item){    
    if(item.selected){
      $("#flavourModal").modal('show');            
      this.selectedItemName = ' ' + item.name + ' ' + item.itemType;
      this.selectedItemNameFlavour = item.itemNameNflavour;
      this.loadFlavours(item);
    }else{
      this.toastr.error('Please select this item first.');
    }
  }


  createItemNameWithFlavour = function(item, changeQty){
    debugger
    var itemNameWithFlavourQty = this.selectedItemName;
    for (let i = 0; i < this.service.formData.orderFlavourDataModels.length; i++) {

      if(changeQty> 0 && this.service.formData.orderFlavourDataModels[i].itemID == item.itemID && this.service.formData.orderFlavourDataModels[i].flavourID == item.flavourID){
        itemNameWithFlavourQty += ' ' + this.service.formData.orderFlavourDataModels[i].flavour + '-' + item.flavourQty + ' '
      }
      else if (this.service.formData.orderFlavourDataModels[i].itemID == item.itemID) {
        itemNameWithFlavourQty += ' ' + this.service.formData.orderFlavourDataModels[i].flavour + '-' + this.service.formData.orderFlavourDataModels[i].flavourQty + ' '
        
      }
  } 
  
    for (let i = 0; i < this.service.formData.orderDetailsDataModels.length; i++) {
      if (this.service.formData.orderDetailsDataModels[i].itemID == item.itemID) {
        this.service.formData.orderDetailsDataModels[i].ItemNameNflavour = itemNameWithFlavourQty;      
      }
  }
  }

  orderWiseAllOrderInformation(id: any) {
    this.service.parameters.orderID = id;
    debugger
    this.service.orderWiseAllOrderInformation().subscribe((data) => {
      debugger
      this.countReceipt = [];
      this.countSticker = [];
      this.reportOrderData = data;
      
      // 15% tax Calculation
      //this.reportOrderData.orderDataModels.incomeTax = ((this.reportOrderData.orderDataModels.subTotal * 15)/100);
      this.reportOrderData.orderDataModels.incomeTax = ((this.reportOrderData.orderDataModels.subTotal * 5)/100);
      // this.reportOrderData.orderDataModels.serviceCharge = ((this.reportOrderData.orderDataModels.subTotal * 5)/100);
      if (this.reportOrderData.orderDataModels.orderType == 8) {
        this.reportOrderData.orderDataModels.serviceCharge = (this.reportOrderData.orderDataModels.subTotal * 7.5) / 100; 
        } else {
            this.reportOrderData.orderDataModels.serviceCharge = (this.reportOrderData.orderDataModels.grandTotal * 0); 
        }
      this.reportOrderData.orderDataModels.total = (this.reportOrderData.orderDataModels.subTotal + this.reportOrderData.orderDataModels.incomeTax + this.reportOrderData.orderDataModels.serviceCharge + this.reportOrderData.orderDataModels.deliveryCharge);
      this.reportOrderData.orderDataModels.grandTotal = (this.reportOrderData.orderDataModels.subTotal + this.reportOrderData.orderDataModels.incomeTax + this.reportOrderData.orderDataModels.serviceCharge + this.reportOrderData.orderDataModels.deliveryCharge) - this.reportOrderData.orderDataModels.discount;

      this.reportOrderData.orderDataModels.orderTypeName = this.commonService.convertOrderTypeText(this.reportOrderData.orderDataModels.orderType);
      this.reportOrderData.orderDataModels.orderTypeName = this.commonService.convertOrderTypeText(this.reportOrderData.orderDataModels.orderType);
      
      this.countReceipt.push(this.reportOrderData.orderDataModels.preparingStartBarCode);
      this.countReceipt.push(this.reportOrderData.orderDataModels.foodReadyBarCode);
      this.countReceipt.push(this.reportOrderData.orderDataModels.dispatchedBarCode);
      //this.countReceipt.push(this.reportOrderData.orderDataModels.searchBarCode);

      

      this.reportOrderData.orderDataModels.customerAddress = "";
        if(this.reportOrderData.orderDataModels.flatNo){
          this.reportOrderData.orderDataModels.customerAddress += "Flat " + "#" + this.reportOrderData.orderDataModels.flatNo;
        }
        if(this.reportOrderData.orderDataModels.houseNo){
          this.reportOrderData.orderDataModels.customerAddress += ", " + "House " + "#" + this.reportOrderData.orderDataModels.houseNo;
        }       
        if(this.reportOrderData.orderDataModels.roadNo){
          this.reportOrderData.orderDataModels.customerAddress +=  ", " +"Road " + "#" +  this.reportOrderData.orderDataModels.roadNo;
        }
        if(this.reportOrderData.orderDataModels.block){
          this.reportOrderData.orderDataModels.customerAddress += ", " + "Block " + " - " + this.reportOrderData.orderDataModels.block;
        } 

        debugger

        if(this.reportOrderData.orderDataModels.areaID > 0){
         var area = this.areaDropList.filter( x => x.areaID == this.reportOrderData.orderDataModels.areaID);
       

          if(area.areaID > 0){
            this.reportOrderData.orderDataModels.area = area.areaName;
            this.reportOrderData.orderDataModels.customerAddress += ", " + "Area" + " - " + area.areaName;
          }
        }

        debugger

        var combine = 'false';

        //this.reportOrderData.orderDetailsDataModels
        for(var i =0; i <this.reportOrderData.orderDetailsDataModels.length; i++){
          if(this.reportOrderData.orderDetailsDataModels[i].itemNameNflavour == null){
            this.reportOrderData.orderDetailsDataModels[i].itemNameNflavour = this.reportOrderData.orderDetailsDataModels[i].itemName;
          }
          
          if(this.selectedKitchenID != this.reportOrderData.orderDetailsDataModels[i].kitchenID && combine != 'true'){
            combine = 'true'
            this.selectedKitchenID = 0;
          }
        }

        


      //var unqueList = this.getUniqueList(this.reportOrderData.orderDetailsDataModels);


      for (let details of this.reportOrderData.orderDetailsDataModels) {
        let i = 0;
        i = (+details.quantity);
        
        for (let index = 0; index < i; index++) {
          this.countSticker.push({
            id: i,
            itemName: details.itemNameNflavour,
            orderID: 'ORD' + this.reportOrderData.orderDataModels.orderID,
            itemType: details.itemType

          });
        }
      }
      
      $("#myModalReport").modal('show');
    })
  }

  getUniqueList(value: any, args?: any): any {

    // Remove the duplicate elements
    let uniqueArray = value.filter(function (el, index, array) { 
      return array.indexOf (el) == index;
    });
    return uniqueArray;
  }

  calculateDiscount = function(){
    debugger
    this.service.formData.discount = 0;
    let total = this.service.formData.subTotal + this.service.formData.deliveryCharge + this.service.formData.incomeTax + this.service.formData.serviceCharge
    this.service.formData.grandTotal = total;
    
    if(this.service.formData.coupon != "" && this.service.formData.coupon != null){

      //this.service.formData.discount = total * this.service.formData.couponValue;
      this.service.formData.discount = this.service.formData.subTotal * this.service.formData.couponValue;
      this.service.formData.grandTotal = total - this.service.formData.discount;

      // let loginInfo = JSON.parse(localStorage.getItem("isLogin"));
      // if(loginInfo.couponCode == this.service.formData.coupon && loginInfo.couponValue > 0){
      //   this.service.formData.discount = total * loginInfo.couponValue;
      //   this.service.formData.grandTotal = total - this.service.formData.discount;
      // }else{
      //   this.toastr.error('Sorry! Invalid Coupon');
      // }
    }       
  }

  getCouponCode($event){  
    debugger
    this.service.formData.couponValue = $event.amount;
      this.service.formData.coupon = $event.couponCode;
      this.calculateDiscount();
  }

  sentSMSForFirstTimeOrder(formData){   
    debugger
    
    this.smsList = JSON.parse(localStorage.getItem('acriveSMS'));
    var smsText = this.smsList.filter( x => x.code == 'FIRSTORDER')[0];
    if(smsText){
      var kitchenID = this.service.formData.kitchenID;            
      var masking = "";
      var brand = "";
      if (kitchenID == 2) {
          masking = "HUNGRY ROOS";
          brand = "Hungry Rooster.";
      }
      else if (kitchenID == 3) {
          masking = "PIMENTOS";
          brand = "Pimentos.";
      }
      else if (kitchenID == 4) {
          masking = "SHUKRAAN";
          brand = "Shukraan.";
      }
      else if (kitchenID == 6) {
        masking = "CLEAN CRAVINGS";
        brand = "Clean Cravings.";
    }

      var messagePart1 = ", " + smsText.messagePart1;
      var messagePart2 = "Thank you" + '\n' + brand;    
      var message = 'Dear ' + this.service.formData.customerName + messagePart1 + messagePart2;      
      $.ajax({
          url: 'https://smpp.ajuratech.com:7790/sendtext?',
          data: { apikey: 'bdbf8d55380f630f', secretkey: '986f81c6', callerID: masking, toUser: this.service.formData.customerMobile, messageContent: message },                
          cache: false,
          type: "GET",
          success: function (response) {
              //alert('Success - ' + JSON.stringify(response))
          },
          error: function (req, status, err) {
              //alert('Error - ' + JSON.stringify(err) + JSON.stringify(req) + JSON.stringify(status))
          }
      });
    }
  }

  setBranchID($event){
    debugger
    this.service.formData.branchID = $event;  
  }
  
}
