import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: [
  ],
})
export class NavbarComponent implements OnInit {
  loginValue: any;
  showOrder: boolean = true;
  showIngredient: boolean = true;
  showTotalIngredient: boolean = true;
  showSettings: boolean = true;
  showSecurity: boolean = true;
  showInventory: boolean = true;
  customerList: boolean = true;
  showOrderHidenField: boolean = true;
  showReports: boolean = true;

  constructor(private router: Router) { }

  ngOnInit() {    
    this.loginValue = JSON.parse(localStorage.getItem("isLogin"));
    if(this.loginValue.userGroupID == 10){  //sales
      this.showIngredient = false;
      this.showTotalIngredient = false;
      this.showSettings = false;
      this.showSecurity = false;
    }else if(this.loginValue.userGroupID == 11){ // accounts
      this.showOrder = false;
      this.showIngredient = false;
      this.showTotalIngredient = false;
      this.showSettings = false;
      this.showSecurity = false;
      this.redirectionFunction('SalesReport');
    }else if(this.loginValue.userGroupID == 12){  //Marketing
      this.showIngredient = false;
      this.showTotalIngredient = false;
      this.customerList = false;
      this.showSettings = false;
      this.showSecurity = false;
      this.showInventory = false;
      this.showOrderHidenField = false;   
    }else if(this.loginValue.userGroupID == 13){  //Developer
    this.showIngredient = false;
    this.showTotalIngredient = false;
    this.customerList = false;
    this.showSecurity = false;
    this.showInventory = false;
    this.showReports = false;     
  }
    localStorage.setItem('orderHidenField',JSON.stringify(this.showOrderHidenField));
  }
  
  redirectionFunction(requesLink:any){
    this.router.navigate([requesLink]);
  }

}
