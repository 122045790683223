import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UsersService } from 'src/app/Services/users.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
  userGrouplist:any=[];
  updateList:any=[];

  userId: number;

  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  constructor( public service :UsersService,private router:Router,private toastr: ToastrService) { 
    this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
  }

  ngOnInit() {
    
    let value = JSON.parse(localStorage.getItem("isLogin"));
    if (value != null) {
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId = value.employeeID;        
        this.getAllUserGroup();
      }
      else {
        this.logoutFunction();
      }

    }
    else {
      this.router.navigate(["login"]);
    }
  }

  getAllUserGroup()
  {
    debugger
    this.userGrouplist=[];
    this.service.getAllUserGroup().subscribe((data) => {
      debugger
      this.userGrouplist = data;
    });
  }

  clearData()
  {
    this.service.updateData={
      id:0,
      employeeID:0,
      employeeName:'',
      isActive:false,
      groupName:'',
      userName:'',
      userGroupID:0
    }
    this.router.navigate(["user"]);
 
  }

  onSubmit(form:NgForm){
    this.updateList=[];
   debugger
    if(this.service.updateData.id>0)
    {
      this.updateList.id=this.service.updateData.id;
      this.updateList.userName=this.service.updateData.userName;
      this.updateList.userGroupID=this.service.updateData.userGroupID;
      
    this.service.updateUserInfo(this.updateList.id,this.updateList).subscribe(
      () => {
        this.clearData();
        this.toastr.success('update');
        this.router.navigate(["user"]);
        
      },
      err => {
        this.toastr.error(err)
        
      }
    )
    }
  }

  redirectionFunction(requesLink:any){
    this.router.navigate([requesLink]);
  }

  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);
      
  }
}
