<!-- start: LOGIN -->
<!-- <link href="../../../assets/user_define/form-style/fonts.css" rel="stylesheet" />
<link href="../../../assets/user_define/form-style/flexslider.css" rel="stylesheet" /> -->
<style>
    .login-branch {
        width: 310px !important;
    }

    .branch-form {
        display: none;
    }

    .login-form {
        display: block;
    }

    .inv_mkl_left {
        margin-left: -6%;
        font-family: 'Montserrat' !important;
        color: #999999;
        font-size: 7px;
        font-weight: 400;
    }

    .content-login {
        position: absolute;
        z-index: 15;
        top: 47%;
        left: 0px;
        padding-left: 37px;
        margin: -100px 0 0 0px;
    }

    .slidelogin {
        overflow: hidden;
        height: 100vh;
    }

    .slidelogin img {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }
    #loading {
        position: absolute;
        opacity: 0.5;
        background-color: #fff !important;
        z-index: 99999;
        width: 100%;
        height: 100%;
    }

    #imagen {
        position: absolute;
        top: 7%;
        left: 77%;
        z-index: 100;
    }
    .login_body {
        padding: 15px;
        width: 100%;
        display: block;
        position: relative;
        height: 100vh;
    }

    
    body.menu-pin .page-sidebar{
    display: none !important;
}
body.menu-pin .page-container .page-content-wrapper .content{
    height: 100vh !important;
}
.form-control {
    font-family: 'Segoe UI' !important;
    font-weight: normal;
    font-size: 13px !important;
    color: #626262;
    min-height: 1em;
}
</style>

<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 nopad">
        <div class="slidelogin hidden-xs" [ngStyle]="{'height': slideloginHeight}">
            <img src="../../../assets/user_define/images/slid4.png" alt="invogue" title="invogue" />
        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 nopad">
        <div class="login-container bg-white ">
            <div>

                <!-- <div id="loading">
                    <img id="imagen" src="../../../assets/built_in/pages/img/progress/progress-circle-success.svg" alt="Loading..."/>
                </div> -->

                <div class="p-l-20 p-r-20">
                    <div class="panel-body login_body">
                        <form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)" id="form-personal">
                            <div class="content-login">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 xl-mb30">
                                        <div class="form-group" style="font-size: 20px; font-family: Segoe UI; margin-bottom: 40px;">
                                            <strong style="color: #e36204;text-transform: uppercase;">Devour</strong>
                                        </div>
                                    </div>
                                </div>

                                <div class="login-form">

                                    <!-- <div class="row">
                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 xl-mb30">
                                            <div class="form-group">
                                                <select2 style="width: 100%;" ng-model="login.CompanyID" s2-options="work.CompanyID as work.Name for work in companyList" ng-change="companyWiseBranch()" required placeholder="Select Company">
                                                </select2>

                                                <div class="clr"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 xl-mb30">
                                            <div class="form-group">
                                                <select2 style="width: 100%;" ng-model="login.BranchID" s2-options="work.BranchID as work.Name for work in branchList" required
                                                         placeholder="Select Branch">
                                                </select2>

                                                <div class="clr"></div>
                                            </div>
                                        </div>
                                    </div> -->
                                

                                <div class="form-group form-group-default required"> 
                                    <label>Branch</label>  
                                    <ng-select bindLabel="branchName"
                                        placeholder="Select Branch"
                                        appendTo="body"  
                                        [(ngModel)]="service.formData.branchID" 
                                        name="BranchID"
                                        #BranchID="ngModel"
                                        (change)="changeBranchData(BranchID)"
                                        [ngModelOptions]="{standalone: true}">                            
                                        <ng-option [value]="branch.BranchID" *ngFor="let branch of branchList">
                                            {{branch.Name}}
                                        </ng-option>
                                    </ng-select>
                                </div>

                                <div class="form-group form-group-default required">     
                                        <label>User Name</label>                                    
                                        <input name="UserName" #UserName="ngModel" [(ngModel)]="service.formData.userName"
                                            class="form-control" placeholder="User Name" required minlength="2" maxlength="50" />
                                </div>
            
                                <div class="form-group form-group-default required">     
                                    <label>Password</label>
                                
                                    <input name="UserPassword" #UserPassword="ngModel" [(ngModel)]="service.formData.userPassword" type="password"
                                        class="form-control" placeholder="Password" required />
                                </div>
            
            
                                
                                    <div class="row">
                                        <div class="col-md-6 no-padding">
                                            <div class="checkbox ">
                                                <input type="checkbox" value="1" id="checkbox1">
                                                <label for="checkbox1" style="font-family: 'Segoe UI' !important;">Keep Me Signed in</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div style="margin-bottom: 20px"></div>
                                    <div>
                                        <button class="btn btn-success custom-btn1" style="text-transform: uppercase;" type="submit" [disabled]="form.invalid">Sign in</button>
                                    </div>

                                </div>
                            </div>

                            <div style="bottom: 30px; left: 32px; position: absolute; right: 32px;">
                                <div style="float: left;width: 50%;;" class="">
                                    <img style="float: left;" src="../../../assets/img/Zurhem-logo1.png" width="20" height="30">

                                    <div class="inv_mkl_left" style="width: 200px; margin-top: 8px;"> invoguesoft.com (880) 989-3572</div>
                                </div>
                                <div class="inv_mkl_left" style="float: right; margin-top: 8px; right: 0;width: 50%;;">a INVOGUE SOFTWARE LIMITED Company</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- end: LOGIN -->
<!-- <script src="../../../assets/user_define/js/custom.js"></script>
<script src="../../../assets/user_define/js/jquery.flexslider.js"></script>
<script src="../../../assets/user_define/js/jquery.cookie.js"></script> -->

<script>
     $(document).ready(function () {
        var slideloginHeight = $(window).outerHeight();
    $(".slidelogin").css({ "height": slideloginHeight });

    $(".slidelogin img").css({ "height": slideloginHeight + 100 });
    // Hide Loader
    $('#loading').hide();

    $(window).load(function () {
        $('.flexslider').flexslider({
            controlsContainer: ".slider-num",
            //animation: "slide",
            animation: "fade",
            animationSpeed: 1000,
            slideshowSpeed: 5000,
            directionNav: false,
            slideshow: true,
            sync: ".flexslider-manual-controls-second"
        });
    });
}) 
</script>
