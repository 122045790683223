import { Component, OnInit, Input } from '@angular/core';
import { CustomerService } from 'src/app/Services/customer.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styles: []
})
export class CustomerListComponent implements OnInit {
  userId:number;
  customerList: any = [];
  customerOrderList: any = [];
  customerOrderDataList: any = [];
  customerOrderData: any;
  searchText;
  editItem: any;

  p: number = 1;
  perPage: any = 10;

  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  constructor(private service: CustomerService ,private router: Router) {
    this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
  }

  ngOnInit() {
    let value = JSON.parse(localStorage.getItem("isLogin"));
    if(value != null){
      
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId=value.employeeID;
        this.loadCustomers();
      }
      else {
        this.logoutFunction();
      }
    }
    else{
      this.router.navigate(["login"]);
    }
  }

  commonRedirectionFunction() {
    this.router.navigate(["CustomerList"]);
  }

  loadCustomers(){

    this.service.getCustomers().subscribe((data)=>{
      this.customerList = data;
      for (let details of this.customerList) {
        var address = "";
        //Flat #A1, House #39, Apart #A, Road #04, Area - Block D, 
        if(details.flatNo){
          address += "Flat " + "#" + details.flatNo;
        }
        if(details.houseNo){
          address += ", " + "House " + "#" + details.houseNo;
        }
        if(details.apartmentNo){
          address += ", " + "Apart " + "#" + details.apartmentNo;
        }
        if(details.roadNo){
          address +=  ", " +"Road " + "#" +  details.roadNo;
        } 
        if(details.area){
          address += ", " + "Area" + " - " + details.area;
        }
        debugger
        if(address == ''){
          details.address = details.address;
        }else{
          details.address = address;
        }       
       
        
      }
    })
  }

  openCustomerOrderModal(user) {    
    this.service.formData = {
      id: user.customerID
    }
    this.getCustomerOrderDetails(this.service.formData.id);
    this.getCustomerOrder(this.service.formData.id);
   }
   modalClose():void{
    document.getElementById('close-modal').click();
   }
   onPageChange(number: number){
    debugger
    this.p = number;
  }
   getCustomerOrderDetails(id: any){
    this.service.getCustomerOrderDetails().subscribe((data)=>{
      debugger
      this.customerOrderList = data;
      
    });    
   }
   
   getCustomerOrder(id: any){
    let sumSubTotal = 0;
    let sumVat = 0;
    let sumDiscount = 0;
    let sumGrandTotal = 0;

    this.service.getCustomerOrder().subscribe((data)=>{
      this.customerOrderDataList = data;

      for (let details of this.customerOrderDataList) {
        sumSubTotal = details.subTotal + sumSubTotal
        //sumVat = details.vat + sumVat
        sumDiscount = details.discount + sumDiscount
        sumGrandTotal = details.grandTotal + sumGrandTotal
        
      }
    this.customerOrderData = {
      subTotal: sumSubTotal,
      vat: sumVat,
      discount: sumDiscount,
      grandTotal: sumGrandTotal
    }
    })    
   }
   updateForm(data) {
    debugger
    this.editItem = {
      customerID: data.customerID,
      name: data.name,
      mobile: data.mobile,
      flatNo: data.flatNo,
      houseNo: data.houseNo,
      apartmentNo: data.apartmentNo,
      roadNo: data.roadNo,
      area: data.area,
      UpdatedBy: data.UpdatedBy
    }
debugger
    if(!this.editItem.area){
      this.editItem.area = data.address;
    }

    this.service.formData = Object.assign({}, this.editItem);
    this.router.navigate(["Customer"]);
  }
   resetForm(form?: NgForm) {

    if (this.service.formData == null) {
      this.service.formData = {
        customerID: 0,
        name: "",
        mobile: "",
        flatNo: "",
        houseNo: "",
        apartmentNo: "",
        roadNo: "",
        area: "",
        UpdatedBy: 0
      }
    }
  }
   logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);
      
  }
}
