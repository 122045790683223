import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ingredient-list',
  templateUrl: './ingredient-list.component.html',
  styles: []
})
export class IngredientListComponent implements OnInit {

  constructor(private router: Router) { }
  ngOnInit(): void {
  }

  commonRedirectionFunction() {
    this.router.navigate(["Ingredient"]);
  }

}
