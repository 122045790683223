
<div class="page-container ">
    <app-header></app-header>

    <div class="page-content-wrapper ">
      <!-- START PAGE CONTENT -->
      <div class="content ">
        <!-- START JUMBOTRON -->
        <div class="jumbotron" data-pages="parallax">
          <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
            <div class="inner">
              <!-- START BREADCRUMB -->
              <ul class="breadcrumb">
                <li>
                  <p>Security</p>
                </li>
                <li (click)="redirectionFunction('PageList')" style="cursor: pointer;"><a class="active">Page</a>
                </li>
                
                <li><a class="active">Add New</a>
                </li>
              </ul>
              <!-- END BREADCRUMB -->
            </div>
          </div>
        </div>
        <div class="container-fluid container-fixed-lg">
          <!-- BEGIN PlACE PAGE CONTENT HERE -->

<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)" id="form-personal">
    <div class="panel panel-transparent">
        <div class="panel-heading">
            <div class="panel-title">
                Page Entry
            </div>
        </div>
        <div class="panel-body">
            <div class="row xl-mlr10">
                <input type="hidden" name="ID" [value]="service.formData.id" />
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 xl-mb30">
                    <div class="form-group form-group-default required">
                        <label>Module</label>
                            <select class="form-control" name="ModuleId" #ModuleId="ngModel"
                                    [(ngModel)]="service.formData.moduleId" id="ModuleId" required>
                                    <option value="0">Select Module</option>
                                    <option *ngFor="let u of moduleList" [attr.data-id]="u.moduleId" [value]="u.moduleId">
                                        {{u.name}}</option>
                                </select>
                    </div>

                    <div class="form-group form-group-default required">
                        <label>Name</label>
                            <input type="text" name="Name" #Name="ngModel"
                                    [(ngModel)]="service.formData.name" id="Name" class="form-control" placeholder="Max. 50 Characters" required />
                    </div>
                    <div class="form-group form-group-default required">
                        <label>Page Name</label>
                            <input type="text" name="UIPageName" #UIPageName="ngModel"
                                    [(ngModel)]="service.formData.uiPageName" id="Name" class="form-control" placeholder="Max. 50 Characters" required />
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group">
                                <div class="form-inner12">
                                    <label for="fname" class="left-col control-label"></label>
                                    <div class="right-col mt-org1" style="text-align:right">
                                        <button class="btn btn-success custom-btn1" type="submit" [disabled]="form.invalid">Submit</button>
                                        <button submit="button" [disabled]="form.invalid" class="btn btn-default custom-btn1" name="Clear" (click)="clearData()"><i class="pg-close"></i> Clear</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


</form>

</div>
<!-- END CONTAINER FLUID -->
</div>

</div>
</div>

<div class="overlay hide" data-pages="search">
</div>
