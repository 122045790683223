import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common'
//import { DatePipe } from '../../node_modules/@angular/common';


@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  //private datePipe: DatePipe;

  constructor() { }

  generateExcel(data){    
    //Excel Title, Header, Data
    const title = 'Sales Summary Report';
    var row = ["Sl.No.", "Order ID", "Order Date", "Customer Name", "Amount"]   

     //Create workbook and worksheet
     let workbook = new Workbook();
     let worksheet = workbook.addWorksheet('Sales Data');
     //Add Row and formatting
     let titleRow = worksheet.addRow([title]);
     titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true }
     worksheet.addRow([]);
     //let subTitleRow = worksheet.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')])
     let subTitleRow = worksheet.addRow(['Date : 01-01-2023'])
     //Add Image
     
    //  let logo = workbook.addImage({
    //    base64: 'fsdfasdfasdffsd', //logoFile.logoBase64,
    //    extension: 'png',
    //  });

     //worksheet.addImage(logo, 'E1:F3');
     worksheet.mergeCells('A1:D2');
     //Blank Row 
     worksheet.addRow([]);
     //Add Header Row
     let headerRow = worksheet.addRow(row);
     
     // Cell Style : Fill and Border
     headerRow.eachCell((cell, number) => {
       cell.fill = {
         type: 'pattern',
         pattern: 'solid',
         fgColor: { argb: 'FFFFFF00' },
         bgColor: { argb: 'FF0000FF' }
       }
       cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
     })
     // worksheet.addRows(data);
     // Add Data and Conditional Formatting
     var serial = 0;
     data.forEach(d => {
          debugger
          serial += 1; 
          
          var grandTotal = d.grandTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");  
          
          // Formatting date
         
          //let formatedDate =  this.datepipe.transform(d.orderDate, 'yyyy-MM-dd');
          
          const rowData = [serial, 'ORD' + d.orderID, d.orderDate, d.customerName, grandTotal];
          let row = worksheet.addRow(rowData);

          //change even rows color
         if(serial % 2 == 0){
            row.getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8F8FF' } }
            row.getCell(2).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8F8FF' } }
            row.getCell(3).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8F8FF' } }
            row.getCell(4).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8F8FF' } }
            row.getCell(5).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8F8FF' } }            
         }

          //let qty = row.getCell(5);
          //let color = 'FF99FF99';
          //  if (+qty.value < 500) {
          //    color = 'FF9999'
          //  }
          //  qty.fill = {
          //    type: 'pattern',
          //    pattern: 'solid',
          //    fgColor: { argb: color }
          //  }
        }
     );

     worksheet.getColumn(1).width = 8;
     worksheet.getColumn(2).width = 15;
     worksheet.getColumn(3).width = 30;
     worksheet.getColumn(4).width = 30;
     worksheet.getColumn(5).width = 10;

     //row = [null, null, null, null, "0.00"]
     let currentRow = worksheet.addRow([]);
     //worksheet.mergeCells(`A${currentRow.number}:D${currentRow.number}`);
     currentRow.getCell(4).value = 'In-House Order';
     //currentRow.getCell(1).alignment.horizontal =  "center";


     currentRow = worksheet.addRow([]);
     currentRow.getCell(4).value = 'Foodpanda Order';

     currentRow = worksheet.addRow([]);
     currentRow.getCell(4).value = 'Shohoz Foods Order';

     currentRow = worksheet.addRow([]);
     currentRow.getCell(4).value = 'Pathao Order';

     currentRow = worksheet.addRow([]);
     currentRow.getCell(4).value = 'Foodi Order';

     currentRow = worksheet.addRow([]);
     currentRow.getCell(4).value = 'Hungrynaki Order';

     currentRow = worksheet.addRow([]);
     currentRow.getCell(4).value = 'HGRAND TOTAL';

     currentRow = worksheet.addRow([]);
     currentRow.getCell(4).value = 'TOTAL COMMISSION (-)';

     currentRow = worksheet.addRow([]);
     currentRow.getCell(4).value = 'GRAND TOTAL (AFTER COMMISSION)';

     currentRow = worksheet.addRow([]);
     currentRow.getCell(4).value = 'BRAND WISE SALES DETAILS';

     // Add blank row
     worksheet.addRow([]);

     row = ["Kitchen Name", "Hungry Rooster", "Pimentos", "Shukraan","Clean Cravings", "Combine"];
     currentRow = worksheet.addRow(row);

     //Footer Row
    //  let footerRow = worksheet.addRow(['This is system generated excel sheet.']);
    //  footerRow.getCell(1).fill = {
    //    type: 'pattern',
    //    pattern: 'solid',
    //    fgColor: { argb: 'FFCCFFE5' }
    //  };
    //  footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
     
     //Merge Cells
     //worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

     //Generate Excel File with given name
     workbook.xlsx.writeBuffer().then((data) => {
       let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
       fs.saveAs(blob, 'SalesSummary.xlsx');
     })

  }

}
