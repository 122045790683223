import { Injectable } from '@angular/core';
import { GlobalVariable } from '../Classes/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  formData: any;
  customerList: any = [];
  customerOrderList : any = [];
  customerOrderDataList : any = [];
  readonly rootUrl = GlobalVariable.ONLINE_CORE_URL;
  constructor(private http: HttpClient) { }
  getCustomers(){
    var response = this.http.get(this.rootUrl + 'customer/GetCustomers');
    response.toPromise().then(res=> this.customerList = res)
    return response;
  }
  getCustomerOrderDetails(){
    var response = this.http.get(this.rootUrl + 'customer/GetCustomerOrderDetails/' + this.formData.id);
    response.toPromise().then(res=> this.customerOrderList = res)
    return response;
  }
  getCustomerOrder(){
    var response = this.http.get(this.rootUrl + 'customer/GetCustomerOrderInfo/' + this.formData.id);
    response.toPromise().then(res=> this.customerOrderDataList = res)
    return response;
  }
  updateCustomerInformation(){
    let id = this.formData.customerID;
    return this.http.put(this.rootUrl + 'customer/UpdateCustomer/'+ id, this.formData);
  }
}
