import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalVariable } from './global';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
     //debugger;
     KEY = 'isLogin';

     readonly rootUrl = GlobalVariable.ONLINE_CORE_URL;
      requestUrl:any= this.rootUrl + 'user/userlogin';
      requestUrl2:any=this.rootUrl+'user/UserRegistration';
     intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
         // add authorization header with jwt token if available
         if(request.url !=this.requestUrl || request.url !=this.requestUrl2)
         {
         let currentUser = JSON.parse(localStorage.getItem(this.KEY));
     //console.log(currentUser.token);
 
         if (currentUser && currentUser.token) {
             request = request.clone({
                 setHeaders: { 
                     Authorization: `Bearer ${currentUser.token}`
                 }
             });
         }
        }
         return next.handle(request);
     }
}