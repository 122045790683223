<div class="page-container">  
    <app-header></app-header> 
    <div class="page-content-wrapper ">
        <div class="content ">
          <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <!-- START BREADCRUMB -->
                <ul class="breadcrumb">
                  <li>
                    <p>Devour</p>
                  </li>
                  <li><a class="active" style="cursor: pointer;">Sales Report</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
            <div class="container-fluid container-fixed-lg custom-container">
                <div class="panel panel-transparent">
                    <div class="panel-heading">
                        <div class="panel-title">
                            Sales Report
                        </div>                
                    </div>
                
                    <div class="panel-body">
                        <div class="filter_container">
                            <div class="filter_content">
                                <div class="filter_col">
                                    <div class="form-group form-group-default input-group angular-date">
                                        <label>From Date</label>
                                        <input class="form-control" placeholder="yyyy-mm-dd"
                                                 name="dp" [(ngModel)]="service.sealsOrderReportBodyModel.fromDate" ngbDatepicker #d="ngbDatepicker">
                                        <span class="input-group-addon" (click)="d.toggle()"><i class="fa fa-calendar"></i></span>
                                      </div>
                                      
                                </div>
                                <div class="filter_col">
                                    <div class="form-group form-group-default input-group angular-date">
                                        <label>To Date</label>
                                        <input class="form-control" placeholder="yyyy-mm-dd"
                                                 name="dp" [(ngModel)]="service.sealsOrderReportBodyModel.toDate" ngbDatepicker #t="ngbDatepicker">
                                        <span class="input-group-addon" (click)="t.toggle()"><i class="fa fa-calendar"></i></span>
                                      </div> 
                                      
                                </div>                                
                                
                                <div class="filter_col">
                                    <div class="form-group form-group-default form-group-default-select2">
                                        <label class="">Order Type</label>
                                        <ng-select bindLabel="name"
                                            placeholder="Select Order Type"
                                            appendTo="body"                            
                                            [searchable]="false" 
                                            [clearable]="true"
                                            [(ngModel)]="service.sealsOrderReportBodyModel.orderType"
                                            name="OrderType"
                                            #OrderType="ngModel">                            
                                            <ng-option [value]="item.id" *ngFor="let item of orderTypeList">
                                                {{item.name}}
                                            </ng-option>
                                        </ng-select>
                                          
                                    </div>
                                </div>
                                
                                <div class="filter_col">
                                    <div class="form-group form-group-default" style="overflow: visible">
                                        <label class="">Customer</label>
                                        <input type='text'
                                               (keyup)="searchCustomerDropDown()"
                                               (click)="searchBoxClicked($event)"
                                               [(ngModel)]="searchValue"
                                               name="SearchValue"
                                                #SearchValue="ngModel"
                                                minlength="3"
                                               placeholder='Search Name/Mobile' class="form-control cust_intput">
                                        <ul id='customerDropList' class="cust_dropdown" *ngIf="customerDropList">
                                            <li (click)='setValue(i, $event)'
                                                *ngFor="let customer of customerDropList; let i = index">
                                                {{ customer.name }}
                                            </li>
                                        </ul>
                                          
                                    </div>
                                </div>               
                                <div class="filter_col">
                                    <div class="form-group form-group-default form-group-default-select2">
                                        <label class="">Status</label>
                                        <ng-select bindLabel="name"
                                            placeholder="Select Status"
                                            appendTo="body"                            
                                            [searchable]="false" 
                                            [clearable]="true"
                                            [(ngModel)]="service.sealsOrderReportBodyModel.orderStatusId"
                                            name="OrderStatusId"
                                            #OrderStatusId="ngModel">                            
                                            <ng-option [value]="item.id" *ngFor="let item of statusList">
                                                {{item.name}}
                                            </ng-option>
                                        </ng-select>
                                          
                                    </div>
                                </div>

                                <div class="filter_col">
                                    <div class="form-group form-group-default form-group-default-select2">
                                        <label>Branch</label>                                        
                                    <ng-select bindLabel="branchName"                                    
                                        placeholder="Select Branch"
                                        appendTo="body"  
                                        [(ngModel)]="service.sealsOrderReportBodyModel.branchID" 
                                        name="BranchID"
                                        #BranchID="ngModel"
                                        [disabled]= "disableBranch"
                                        [ngModelOptions]="{standalone: true}">                                                                
                                        <ng-option [value]="branch.BranchID" *ngFor="let branch of branchList">
                                            {{branch.Name}}
                                        </ng-option>
                                    </ng-select>
                                    </div>
                                </div> 

                                <div class="filter_col">
                                    <div class="form-group form-group-default form-group-default-select2">
                                        <label>Amount Sorting</label>                                        
                                        <ng-select bindLabel="amountSorting"                                    
                                            placeholder="Select Sorting"
                                            appendTo="body"  
                                            [(ngModel)]="service.sealsOrderReportBodyModel.amountSorting" 
                                            name="AmountSorting"
                                            #BranchID="ngModel"
                                            [ngModelOptions]="{standalone: true}">                                                                
                                            <ng-option [value]="sorting.Value" *ngFor="let sorting of sortingList">
                                                {{sorting.Name}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>

                                <div class="filter_col">
                                    <div class="form-group form-group-default form-group-default-select2">
                                        <label class="">Restaurant</label>
                                        <ng-select bindLabel="name"
                                            placeholder="Select Restaurant"
                                            appendTo="body"                            
                                            [searchable]="false" 
                                            [clearable]="true"
                                            [(ngModel)]="service.sealsOrderReportBodyModel.kitchenID"
                                            name="KitchenID"
                                            #KitchenID="ngModel"
                                            [ngModelOptions]="{standalone: true}">                            
                                            <ng-option [value]="item.kitchenID" *ngFor="let item of kitchenList">
                                                {{item.name}}
                                            </ng-option>
                                        </ng-select> 
                                    </div>                                                                             
                                </div>

                            </div>                            

                            <div class="filter_content_btn">
                                <div style="float: left;width:100%;">
                                    <button class="btn btn-success" style="width: 150px;" (click)="searchOrder('details')" [disabled]="disabledReportBtn">Details Report</button>                                    
                                </div>
                                <div style="float:right">
                                    <button class="btn btn-primary" style="width: 150px;" (click)="searchOrder('summary')" [disabled]="disabledReportBtn">Summary Report</button>
                                </div>
                            </div>
                        </div>
                    </div>
                
                <!-- Summary Report Design -->               
                <div class="sales_report_container" style="width: 100%;display: flex;flex-direction: column;" *ngIf="reportOrderData.length > 0">
                    <div id="htmlData" class="htmlData" #htmlData style="width: 720px;height: auto; margin: 0 auto;display: block;background-color: #fff;">
                        <!-- height: 950px; -->
                        <style>
                            .report_order_details_data .table tbody tr td, .report_order_details_data .table thead tr th, .report_order_details_data .table tfoot tr td {
                                font-size: 12px !important;
                            }
                        </style>
                        <div class="report_container report_viewer" style="background: #fff;padding-right: 20px;width: 100%; height: auto;margin: 0;display: inline-block;flex-direction: column;float: left;padding-top: 0px;">
                            <div class="report_company_container" style="text-align: center;margin: 35px 0;">
                                <h2 style="font-weight: bold;margin-bottom: 0px;">DEVOUR</h2>
                                <p style="margin-bottom: 0px;color: rgba(0,0,0,.8);">Address:<span> House # 90, Road#17A, Banani, Dhaka-1213.</span></p>
                                <p style="margin-bottom: 0px;color: rgba(0,0,0,.8);">Phone NO:<span> 01713029996</span>, Email:<span>devour@gmail.com
                                </span></p>
                                <h2 style="font-weight: bold;margin-bottom: 0px;text-decoration: underline;">Sales Summary Report</h2>
                            </div>
                            <div id="excelPart">
                                <div class="report_order_content" style="padding: 0 8px; margin-bottom: 20px;padding-left: 50px;padding-top: 50px;">
                                    <div style="font-family: 'Montserrat';font-size: 12px;margin-bottom: 2px;"><span style="width: 120px;display: inline-block;">From Date:</span><span style="font-family: Arial, sans-serif;color: rgba(0,0,0,.8);"> {{orderService.parameters.fromDate}}</span><span style="padding: 0 10px;">To</span><span style="font-family: Arial, sans-serif;color: rgba(0,0,0,.8);">{{orderService.parameters.toDate}}</span></div>
                                    <div style="font-family: 'Montserrat';font-size: 12px;margin-bottom: 2px;"><span style="width: 120px;display: inline-block;">Order Type:</span><span style="font-family: Arial, sans-serif;color: rgba(0,0,0,.8);"> {{orderService.parameters.orderTypeName}}</span></div>
                                    <div style="font-family: 'Montserrat';font-size: 12px;margin-bottom: 2px;"><span style="width: 120px;display: inline-block;">Customer Name:</span><span style="font-family: Arial, sans-serif;color: rgba(0,0,0,.8);"> {{orderService.parameters.customerName}}</span></div>
                                    <div style="font-family: 'Montserrat';font-size: 12px;margin-bottom: 2px;"><span style="width: 120px;display: inline-block;">Status:</span><span style="font-family: Arial, sans-serif;color: rgba(0,0,0,.8);"> {{orderService.parameters.orderStatusName}}</span></div>
                                </div>
                                <div class="report_order_details_content">
                                    <div class="report_order_details_data">
                                        <table id="example" class="table" style="width:100%; margin-top: 0px;background: #fff;border-spacing: 0;border-collapse: collapse;padding-left: 50px;">
                                            <thead>
                                                <tr>
                                                    <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 14px 6px !important;">Sl.No.</th>
                                                    <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 14px 6px !important;">Order ID</th>
                                                    <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 14px 6px !important;">Order Date</th>
                                                    <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: left;font-family: 'Montserrat';padding: 14px 6px !important;width: 30%;">Customer Name</th>
                                                    <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 14px 6px !important;">Amount</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let c of reportOrderData; let sl = index">
                                                    <td style="border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;text-align: center;color: rgba(0,0,0,.8);">{{sl+1}}</td>
                                                    <td style="border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;text-align: center;color: rgba(0,0,0,.8);">ORD{{c.orderID}}</td>
                                                    <td style="border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;text-align: center;color: rgba(0,0,0,.8);">{{c.orderDate | date: 'd-M-yy, h:mm a'}}</td>
                                                    <td style="border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;text-align: left;color: rgba(0,0,0,.8);">{{c.customerName}}</td>
                                                    <td style="text-align: right;border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;color: rgba(0,0,0,.8);">{{c.grandTotal | number}}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot style="padding-left:50px !important;">                                            
                                                <tr>
                                                    <td colspan="4" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">In-House Order</td>
                                                    <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{inHouseTotalAmount | number}}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="4" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Foodpanda Order <span class="commission-color">(Commission: {{foodPandaCommission| number:'1.2-2'}})</span></td>
                                                    <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{foodPandaTotalAmount | number}}</td>
                                                </tr>                                            
                                                <!-- <tr>
                                                    <td colspan="4" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Shohoz Foods Order <span class="commission-color">(Commission: {{shohozFoodCommission| number:'1.2-2'}})</span></td>
                                                    <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{shohozFoodTotalAmount | number}}</td>
                                                </tr> -->
                                                <tr>
                                                    <td colspan="4" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Pathao Order <span class="commission-color">(Commission: {{pathaoCommission | number:'1.2-2'}})</span></td>
                                                    <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{pathaoTotalAmount | number}}</td>
                                                </tr>

                                                <tr>
                                                    <td colspan="4" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Foodi Order <span class="commission-color">(Commission: {{foodiCommission | number:'1.2-2'}})</span></td>
                                                    <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{foodiTotalAmount | number}}</td>
                                                </tr>
                                                <!-- <tr>
                                                    <td colspan="4" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Hungrynaki Order <span class="commission-color">(Commission: {{hungrynakiCommission| number:'1.2-2'}})</span></td>
                                                    <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{hungrynakiTotalAmount | number}}</td>
                                                </tr>                                             -->
                                                <!-- <tr *ngIf="vatTotal">
                                                    <td colspan="4" style="padding: 8px !important;font-weight: normal;text-align: center;border: 1px solid #000;font-family: 'Montserrat';text-transform: uppercase;color: rgba(0,0,0,.8);"></td>
                                                    <td style="padding: 8px !important;font-weight: normal;text-align: right;border: 1px solid #000;color: rgba(0,0,0,.8);"></td>
                                                </tr> -->
                                                <tr *ngIf="grandTotal">
                                                    <td colspan="4" class="total">Grand Total <span style="color: rgb(214, 96, 18);">(Including 10% Tax: {{vatTotal | number}})</span></td>
                                                    <td style="text-align: right;border: 1px solid #000 !important;padding: 8px !important;color: rgba(0,0,0,.8);">{{grandTotal | number}}</td>
                                                </tr>
                                                <tr *ngIf="totalCommission">
                                                    <td colspan="4" style="text-align: center;border: 1px solid #000 !important;font-family: 'Montserrat';padding: 8px !important;text-transform: uppercase;color: rgba(0,0,0,.8);">Total Commission (-)</td>
                                                    <td style="text-align: right;border: 1px solid #000 !important;padding: 8px !important;color: rgba(2, 2, 2, 0.8);">{{totalCommission | number}}</td>
                                                </tr>
                                                <tr *ngIf="netTotal">
                                                    <td colspan="4" class="total">Grand Total (After Commission)</td>
                                                    <td style="text-align: right;border: 1px solid #000 !important;padding: 8px !important;font-weight: bold;color: rgba(0,0,0,.8);" class="commission-color">{{netTotal | number}}</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="5" class="total" style="font:bolder;border-bottom: 0 !important;background-color: rgb(230, 227, 227);">Brand  Wise Sales Details</td>                                                
                                                </tr>
                                                <tr style="background-color: rgb(230, 227, 227);">
                                                    <td colspan="5" style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">
                                                        <table>
                                                                    <tr>
                                                                        <td class="td text-bold">Kitchen Name</td>
                                                                        <!-- <td class="td text-bold">Pizzamia</td> -->
                                                                        <td class="td text-bold">Hungry Rooster</td>
                                                                        <td class="td text-bold">Pimentos</td>
                                                                        <!-- <td class="td text-bold">Shukraan</td> -->
                                                                        <td class="td text-bold">Jhotpot</td>
                                                                        <td class="td text-bold">Clean Cravings</td>
                                                                        <td class="td text-bold">Combine</td>
                                                                        <td class="td text-bold">Total</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="td">Banani</td>
                                                                        <!-- <td class="td text-right">{{pizzamiaTotalSalesBaridhara | number}}</td> -->
                                                                        <td class="td text-right">{{hungryRoosterTotalSalesBaridhara | number}}</td>
                                                                        <td class="td text-right">{{pimentosTotalSalesBaridhara | number}}</td>
                                                                        <!-- <td class="td text-right">{{shukraanTotalSalesBaridhara | number}}</td> -->
                                                                        <td class="td text-right">{{jhotpotTotalSalesBaridhara | number}}</td>
                                                                        <td class="td text-right">{{cleanCravingsTotalSalesBaridhara | number}}</td>
                                                                        <td class="td text-right">{{CombineBaridhara | number}}</td>
                                                                        <td class="td text-right">{{baridharaTotalAmount | number}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="td">Dhanmondi</td>
                                                                        <!-- <td class="td text-right">{{pizzamiaTotalSalesDhanmondi | number}}</td> -->
                                                                        <td class="td text-right">{{hungryRoosterTotalSalesDhanmondi | number}}</td>
                                                                        <td class="td text-right">{{pimentosTotalSalesDhanmondi | number}}</td>
                                                                        <!-- <td class="td text-right">{{shukraanTotalSalesDhanmondi | number}}</td> -->
                                                                        <td class="td text-right">{{jhotpotTotalSalesDhanmondi | number}}</td>
                                                                        <td class="td text-right">{{cleanCravingsTotalSalesDhanmondi | number}}</td>
                                                                        <td class="td text-right">{{CombineDhanmondi | number}}</td>
                                                                        <td class="td text-right">{{dhanmondiTotalAmount | number}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="td">Uttara</td>
                                                                        <!-- <td class="td text-right">{{pizzamiaTotalSalesUttara | number}}</td> -->
                                                                        <td class="td text-right">{{hungryRoosterTotalSalesUttara | number}}</td>
                                                                        <td class="td text-right">{{pimentosTotalSalesUttara | number}}</td>
                                                                        <!-- <td class="td text-right">{{shukraanTotalSalesUttara | number}}</td> -->
                                                                        <td class="td text-right">{{jhotpotTotalSalesUttara | number}}</td>
                                                                        <td class="td text-right">{{cleanCravingsTotalSalesUttara | number}}</td>
                                                                        <td class="td text-right">{{CombineUttara | number}}</td>
                                                                        <td class="td text-right">{{uttaraTotalAmount | number}}</td>
                                                                    </tr>  
                                                                    <tr>
                                                                        <td class="td">Total</td>
                                                                        <!-- <td class="td text-right">{{pizzamiaTotal | number}}</td> -->
                                                                        <td class="td text-right">{{hungryRoosterTotal | number}}</td>
                                                                        <td class="td text-right">{{pimentosTotal | number}}</td>
                                                                        <!-- <td class="td text-right">{{shukraanTotal | number}}</td> -->
                                                                        <td class="td text-right">{{jhotpotTotal | number}}</td>
                                                                        <td class="td text-right">{{cleanCravingsTotal | number}}</td>
                                                                        <td class="td text-right">{{combineTotal | number}}</td>
                                                                        <td class="td text-right">{{(pizzamiaTotal + hungryRoosterTotal + pimentosTotal + jhotpotTotal + cleanCravingsTotal + combineTotal) | number}}</td>
                                                                    </tr>  
                                                            </table>
                                                        </td>
                                                </tr>
                                                <!-- <tr *ngIf="pizzamiaTotalSales" style="background-color: rgb(225, 234, 239);">
                                                    <td colspan="3" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Pizzamia Total Sales (Item Only)</td>
                                                    <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{pizzamiaTotalSales | number}}</td>
                                                    <td style="border: 1px solid #000"></td>
                                                </tr>
                                                <tr *ngIf="hungryRoosterTotalSales" style="background-color: rgb(225, 234, 239);">
                                                    <td colspan="3" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Hungry Rooster Total Sales  (Item Only</td>
                                                    <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{hungryRoosterTotalSales | number}}</td>
                                                    <td style="border: 1px solid #000"></td>
                                                </tr> -->
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                    <div class="btn_container" style="display: flex;width: 300px;margin: 0 auto;align-items: center;justify-content: center;margin-top: 15px;">
                        <button type="button" class="btn btn-success" (click)="captureScreen()" id="report" 
                                style="width: 100%;margin-bottom: 15px;margin-right: 0px;margin-right: 15px;">Print</button>

                        <button type="button" class="btn btn-primary" onclick="exportTableToExcel('excelPart', 'Sales-Data', 'Sales-report.xls')" id="excelID" 
                                style="width: 100%;margin-bottom: 15px;margin-right: 0px;margin-right: 15px;">Export Excel</button>

                        <button type="button" class="btn btn-primary hidden" (click)="downloadPDF()" id="report" 
                                style="width: 100%;margin-bottom: 15px;margin-right: 0px;margin-left: 0px;">Pdf Download</button>
                    </div>
                </div>

                <!-- Details Report Design -->               
                <div class="sales_report_container" style="width: 100%;display: flex;flex-direction: column;" *ngIf="detailsReportData.length > 0">
                    <div id="htmlData2" class="htmlData" #htmlData style="width: 720px;height: auto; margin: 0 auto;display: block;background-color: #fff;">
                        <!-- height: 950px; -->
                        <style>
                            .report_order_details_data .table tbody tr td, .report_order_details_data .table thead tr th, .report_order_details_data .table tfoot tr td {
                                font-size: 12px !important;
                            }
                        </style>
                        <div class="report_container report_viewer" style="background: #fff;padding-right: 20px;width: 100%; height: auto;margin: 0;display: inline-block;flex-direction: column;float: left;padding-top: 0px;">
                            <div class="report_company_container" style="text-align: center;margin: 35px 0;">
                                <h2 style="font-weight: bold;margin-bottom: 0px;">DEVOUR</h2>
                                <p style="margin-bottom: 0px;color: rgba(0,0,0,.8);">Address:<span> House # 90, Road#17A, Banani, Dhaka-1213.</span></p>
                                <p style="margin-bottom: 0px;color: rgba(0,0,0,.8);">Phone NO:<span> 01713029996</span>, Email:<span> devour@gmail.com
                                </span></p>
                                <h2 style="font-weight: bold;margin-bottom: 0px;text-decoration: underline;">Sales Details Report</h2>
                            </div>
                            <div class="report_order_content" style="padding: 0 8px; margin-bottom: 20px;">
                                <div style="font-family: 'Montserrat';font-size: 12px;margin-bottom: 2px;"><span style="width: 120px;display: inline-block;">From Date:</span><span style="font-family: Arial, sans-serif;color: rgba(0,0,0,.8);"> {{orderService.parameters.fromDate}}</span><span style="padding: 0 10px;">To</span><span style="font-family: Arial, sans-serif;color: rgba(0,0,0,.8);">{{orderService.parameters.toDate}}</span></div>
                                <div style="font-family: 'Montserrat';font-size: 12px;margin-bottom: 2px;"><span style="width: 120px;display: inline-block;">Order Type:</span><span style="font-family: Arial, sans-serif;color: rgba(0,0,0,.8);"> {{orderService.parameters.orderTypeName}}</span></div>
                                <div style="font-family: 'Montserrat';font-size: 12px;margin-bottom: 2px;"><span style="width: 120px;display: inline-block;">Customer Name:</span><span style="font-family: Arial, sans-serif;color: rgba(0,0,0,.8);"> {{orderService.parameters.customerName}}</span></div>
                                <div style="font-family: 'Montserrat';font-size: 12px;margin-bottom: 2px;"><span style="width: 120px;display: inline-block;">Status:</span><span style="font-family: Arial, sans-serif;color: rgba(0,0,0,.8);"> {{orderService.parameters.orderStatusName}}</span></div>
                            </div>
                            <div class="report_order_details_content">
                                <div class="report_order_details_data">
                                    <table id="example" class="table" style="width:100%; margin-top: 0px;background: #fff;border-spacing: 0;border-collapse: collapse;">
                                        <thead>
                                            <tr>
                                                <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 10px 6px !important;">Sl</th>
                                                <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 10px 6px !important;">Order ID</th>
                                                <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 10px 28px !important;">Date</th>
                                                <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: left;font-family: 'Montserrat';padding: 10px 6px !important;width: 20%;">Customer Name</th>
                                                <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 10px 6px !important;">S.Total</th>
                                                <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 10px 6px !important;">VAT</th>
                                                <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 10px 6px !important;width: 6%;">Disc.</th>
                                                <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 10px 6px !important;">D.Char.</th>
                                                <th style="font-weight: bold;color: #000;text-transform: capitalize;border: 1px solid #000;text-align: center;font-family: 'Montserrat';padding: 10px 6px !important;">G.Total</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let c of detailsReportData; let sl = index">
                                                <td style="border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;text-align: center;color: rgba(0,0,0,.8);">{{sl+1}}</td>
                                                <td style="border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;text-align: center;color: rgba(0,0,0,.8);">ORD{{c.orderID}}</td>
                                                <td style="border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;text-align: center;color: rgba(0,0,0,.8);">{{c.orderDate | date: 'd-M-yy, h:mm a'}}</td>
                                                <td style="border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;text-align: left;color: rgba(0,0,0,.8);">{{c.customerName}}</td>
                                                <td style="text-align: right;border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;color: rgba(0,0,0,.8);">{{c.subTotal | number}}</td>
                                                <td style="text-align: right;border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;color: rgba(0,0,0,.8);">{{c.incomeTax | number}}</td>
                                                <td style="text-align: right;border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;color: rgba(0,0,0,.8);">{{c.discount | number}}</td>
                                                <td style="text-align: right;border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;color: rgba(0,0,0,.8);">{{c.deliveryCharge | number}}</td>
                                                <td style="text-align: right;border: 1px solid #000;font-family: Arial, sans-serif;padding: 10px 6px !important;color: rgba(0,0,0,.8);">{{c.grandTotal | number}}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="4" style="text-align: center;border: 1px solid #000 !important;font-family: 'Montserrat';padding: 8px !important;text-transform: uppercase;font-weight: bold;color: rgba(0,0,0,.8);">Grand Total</td>
                                                <td style="text-align: right;border: 1px solid #000 !important;padding: 8px !important;font-weight: bold;color: rgba(0,0,0,.8);">{{subTotal | number}}</td>
                                                <td style="text-align: right;border: 1px solid #000 !important;padding: 8px !important;font-weight: bold;color: rgba(0,0,0,.8);">{{vatTotal | number}}</td>
                                                <td style="text-align: right;border: 1px solid #000 !important;padding: 8px !important;font-weight: bold;color: rgba(0,0,0,.8);">{{discountTotal | number}}</td>
                                                <td style="text-align: right;border: 1px solid #000 !important;padding: 8px !important;font-weight: bold;color: rgba(0,0,0,.8);">{{deliveryChargeTotal | number}}</td>
                                                <td style="text-align: right;border: 1px solid #000 !important;padding: 8px !important;font-weight: bold;color: rgba(0,0,0,.8);">{{grandTotal | number}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">In-House Order</td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{inHouseTotalAmount | number}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Foodpanda Order <span style="color: rgb(255, 158, 31);">(Commission: {{foodPandaCommission| number:'1.2-2'}})</span></td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{foodPandaTotalAmount | number}}</td>
                                            </tr>

                                             <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Pathao Order <span style="color: rgb(255, 158, 31);">(Commission: {{pathaoCommission| number:'1.2-2'}})</span></td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{pathaoSubtotal | number}}</td>
                                            </tr>

                                            <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Foodi Order <span style="color: rgb(255, 158, 31);">(Commission: {{foodiCommission| number:'1.2-2'}})</span></td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{foodiSubtotal | number}}</td>
                                            </tr>
                                            <!-- <tr>
                                                <td  *ngIf="totalCommission" colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Total Commission (-) <span style="color: rgb(255, 158, 31);"></span></td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{totalCommission | number}}</td>
                                            </tr> -->
                                        
                                            <!-- <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Pathao Order <span style="color: rgb(255, 158, 31);">(Commission: {{pathaoCommission | number:'1.2-2'}})</span></td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{pathaoTotalAmount | number}}</td>
                                            </tr> -->
                                            <!-- <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Pathao Order <span class="commission-color">(Commission: {{pathaoCommission| number:'1.2-2'}})</span></td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{pathaoTotalAmount | number}}</td>
                                            </tr> -->

                                            <!-- <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Hungrynaki Order <span style="color: rgb(255, 158, 31);">(Commission: {{hungrynakiCommission| number:'1.2-2'}})</span></td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{hungrynakiTotalAmount | number}}</td>
                                            </tr> -->
                                            <!-- <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Shohoz Foods Order</td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{shohozFoodTotalAmount | number}}</td>
                                            </tr> -->
                                            <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Banani Total Sales</td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{baridharaTotalAmount | number}}</td>
                                                <!-- <td style="border: 1px solid #000"></td> -->
                                            </tr>
                                            <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Dhanmondi Total Sales</td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{dhanmondiTotalAmount | number}}</td>
                                                <!-- <td style="border: 1px solid #000"></td> -->
                                            </tr>
                                            <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Uttara Total Sales</td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{uttaraTotalAmount | number}}</td>
                                                <!-- <td style="border: 1px solid #000"></td> -->
                                            </tr>
                                            <tr>
                                                <td colspan="8" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;text-transform: uppercase;font-weight: bold;color: rgba(0,0,0,.8);">Grand Total</td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);font-weight: bold;">{{grandTotal | number}}</td>
                                                <!-- <td style="border: 1px solid #000"></td> -->
                                            </tr>
                                            <!-- <tr *ngIf="pizzamiaTotalSales" style="background-color: rgb(225, 234, 239);">
                                                <td colspan="7" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Pizzamia Total Sales</td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{pizzamiaTotalSales | number}}</td>
                                                <td style="border: 1px solid #000"></td>
                                            </tr>
                                            <tr *ngIf="hungryRoosterTotalSales" style="background-color: rgb(225, 234, 239);">
                                                <td colspan="7" style="text-align: center;border: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;color: rgba(0,0,0,.8);">Hungry Rooster Total Sales</td>
                                                <td style="text-align: right;border: 1px solid #000;padding: 8px !important;color: rgba(0,0,0,.8);">{{hungryRoosterTotalSales | number}}</td>
                                                <td style="border: 1px solid #000"></td>
                                            </tr> -->
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="btn_container" style="display: flex;width: 300px;margin: 0 auto;align-items: center;justify-content: center;margin-top: 15px;">
                        <button type="button" class="btn btn-success" (click)="captureScreen()" id="report" 
                                style="width: 100%;margin-bottom: 15px;margin-right: 0px;margin-right: 15px;">Print</button>
                        <button type="button" class="btn btn-primary hidden" (click)="downloadPDF()" id="report" 
                                style="width: 100%;margin-bottom: 15px;margin-right: 0px;margin-left: 0px;">Pdf Download</button>
                    </div>
                </div>             
                
            </div>

            <div style="text-align:center; z-index: -1;"  *ngIf="showLoader">
                <img id="imagen" src="~/../../../../../assets/img/loader.gif"  />
            </div>
</div>



