import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import * as jsPDF from 'jspdf'
// import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

import { DatepickerAdapterService } from 'src/app/Services/datepicker-adapter.service';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from 'src/app/Services/order.service';
import { CommonService } from 'src/app/Services/common.service';
import { CustomerService } from 'src/app/Services/customer.service';
import { KitchenService } from 'src/app/Services/kitchen.service';
import { SalesReportService } from 'src/app/Services/reports/sales-report.service';
import { ExcelService } from 'src/app/Services/excel.service';

@Component({
  selector: 'app-sales-order-report',
  templateUrl: './sales-order-report.component.html',
  styleUrls: ['./sales-order-report.component.css']
})
export class SalesOrderReportComponent implements OnInit {

  @ViewChild('htmlData') htmlData:ElementRef;

  loginValue: any;
  counter: any;
  userId: number=0;
  public statusList: any = [];
  editItem: any;
  searchText;
  p: number = 1;
  perPage: any = 10;


  fDate: number;
  tDate: number;

  searchValue: any;
  customerDropList: any = [];
  orderTypeList: any = [];
  pageIndex: any = 1;
  pageSize: any = 10;
  disableBranch: boolean;

  reportOrderData: any = [];
  detailsReportData: any = [];
  
  subTotal: any = 0;
  vatTotal: any = 0;
  discountTotal: any = 0;
  deliveryChargeTotal: any = 0;
  grandTotal: any = 0;

  inHouseTotalAmount: any = 0;  

  
  baridharaTotalAmount: any = 0 ;
  dhanmondiTotalAmount: any = 0;
  uttaraTotalAmount: any = 0;

  foodPandaTotalAmount: any = 0;
  foodPandaCommission: any = 0;

  pandaGoTotalAmount: any = 0;
  pandaGoCommission: any = 0;

  pathaoTotalAmount: any = 0;
  pathaoCommission: any = 0;

  foodiTotalAmount: any = 0;
  foodiCommission: any = 0;

  shohozFoodTotalAmount: any = 0;
  shohozFoodCommission: any = 0;

  hungrynakiTotalAmount: any = 0;
  hungrynakiCommission: any = 0;

  foodPandaSubtotal: any = 0;
  pandaGoSubtotal: any = 0;

  shohozFoodSubTotal: any = 0;
  pathaoSubtotal: any = 0;
  foodiSubtotal:any = 0;
  hungryNakiSubtotal: any = 0;

  totalCommission: any = 0;
  netTotal: any = 0;

  pizzamiaTotalSalesBaridhara: any = 0;
  pizzamiaTotalSalesDhanmondi: any = 0;
  pizzamiaTotalSalesUttara: any = 0;

  hungryRoosterTotalSalesBaridhara: any = 0;  
  hungryRoosterTotalSalesDhanmondi: any = 0;
  hungryRoosterTotalSalesUttara: any = 0;

  pimentosTotalSalesBaridhara: any = 0;
  pimentosTotalSalesDhanmondi: any = 0;
  pimentosTotalSalesUttara: any = 0;

  shukraanTotalSalesBaridhara: any = 0;
  shukraanTotalSalesDhanmondi: any = 0;
  shukraanTotalSalesUttara: any = 0;

  jhotpotTotalSalesBaridhara: any = 0;
  jhotpotTotalSalesDhanmondi: any = 0;
  jhotpotTotalSalesUttara: any = 0;

cleanCravingsTotalSalesBaridhara: any;
cleanCravingsTotalSalesDhanmondi: any;
cleanCravingsTotalSalesUttara: any;


  CombineBaridhara: any = 0;
  CombineDhanmondi: any = 0;
  CombineUttara: any = 0;

  pizzamiaTotal: any = 0;
  hungryRoosterTotal: any = 0;
  pimentosTotal: any = 0 ;
  shukraanTotal: any = 0 ;
  jhotpotTotal: any = 0;
  cleanCravingsTotal: any;
  combineTotal: any = 0;
  
  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  branchList: any = [];
  public sortingList: any = [];

  showLoader: boolean = false;
  disabledReportBtn: boolean = false;
  public kitchenList: any = [];


  constructor(private router: Router
    , private commonService: CommonService
    , public service: SalesReportService
    , public orderService: OrderService
    , private kitchenService: KitchenService
    , private excelService: ExcelService
    , private datepicker: DatepickerAdapterService
    , private ngbCalendar: NgbCalendar
    , private toastr: ToastrService) {      
      this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      //this.showLoader = false;
  }

  ngOnInit() {  
    this.counter = JSON.parse(localStorage.getItem("pageCounter"));
      if (this.counter == 0) {
        location.reload();
        localStorage.removeItem("pageCounter");
      }

    this.resetParameters();
    this.resetSealsOrderReportBodyModel();

    this.loginValue = JSON.parse(localStorage.getItem("isLogin"));

    this.branchList = [{'BranchID': 0, 'Name': 'All'},{'BranchID': 1, 'Name': 'Banani'}, {'BranchID': 2, 'Name': 'Dhanmondi'}, {'BranchID': 3, 'Name': 'Uttara'}];
    
    this.sortingList = [{'Value': 'ASC', 'Name': 'Ascending'},{'Value': 'DESC', 'Name': 'Descending'}];


    this.disableBranch = false;  
    if (this.loginValue != null) {

      if(this.loginValue.groupName == 'Employee'){
        this.disableBranch = true;
        this.service.sealsOrderReportBodyModel.branchID = JSON.parse(localStorage.getItem("branchID")); 
      }
      
      this.expirationTime = formatDate(this.loginValue.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId = this.loginValue.employeeID;
        this.loadStatus();
        this.loadOrderType();
        this.loadAllCustomerAdd();
        this.loadKitchens();
        if(this.service.sealsOrderReportBodyModel.customerID == 0){
          this.searchValue = "All Customer";
        }
        
        
      }
      else {
        //this.logoutFunction();
      }

    }
    else {
      this.router.navigate(["login"]);
    }
  }

  loadKitchens(){
    debugger
    this.kitchenService.getKitchens().subscribe((data)=>{ 
      debugger     
      this.kitchenList = data;
    })
  }

  modalClose(): void {
    document.getElementById('close-modal').click();
  }
  loadAllCustomerAdd(){
    this.service.sealsOrderReportBodyModel.customerID = 0;
  }
  loadStatus(){
    this.statusList = this.commonService.getStatusList();
    this.statusList.push({
      id: -1,
      name: "All Status"
    });
    this.service.sealsOrderReportBodyModel.orderStatusId =  this.statusList[this.statusList.length-1].id;
  }
  loadOrderType(){
    this.orderTypeList = this.commonService.getOrderTypeList();
    this.orderTypeList.push({
      id: -1,
      name: "All Order Type"
    });
    this.service.sealsOrderReportBodyModel.orderType =  this.orderTypeList[this.orderTypeList.length - 1].id;
  }

  
  searchOrder(reportType) {
    if (this.service.sealsOrderReportBodyModel.fromDate != null && this.service.sealsOrderReportBodyModel.toDate != null) {
      let fDate = Date.parse(this.datepicker.toModel(this.service.sealsOrderReportBodyModel.fromDate));
      let tDate = Date.parse(this.datepicker.toModel(this.service.sealsOrderReportBodyModel.toDate));
      if (fDate > tDate) {
        this.toastr.error("from date can't be greater then to date !!");
        return;
      }      
      //get parameter
      //this.service.sealsOrderReportBodyModel.branchID = JSON.parse(localStorage.getItem("branchID"));

      this.orderService.parameters.fromDate = this.datepicker.toModel(this.service.sealsOrderReportBodyModel.fromDate);
      this.orderService.parameters.toDate = this.datepicker.toModel(this.service.sealsOrderReportBodyModel.toDate);
      if(this.statusList.length > 0){
        for(let status of this.statusList){
          if(status.id == this.service.sealsOrderReportBodyModel.orderStatusId){
            this.orderService.parameters.orderStatusName = status.name;
          }
        }
        
      }
      if(this.orderTypeList.length > 0){
        for(let order of this.orderTypeList){
          if(order.id == this.service.sealsOrderReportBodyModel.orderType){
            this.orderService.parameters.orderTypeName = order.name;
          }
        }
        
      }
      this.orderService.parameters.customerName = this.searchValue;


      if(reportType == 'summary'){
        this.salesReportOrderInformation(this.service.sealsOrderReportBodyModel);
      }else{
        this.detailsReport(this.service.sealsOrderReportBodyModel);
      }

      
    }else{
      this.toastr.error("Please Enter your From date and To Date");
      return;
    }
    
  }

  salesReportOrderInformation(data: any) {
    debugger
    this.reportOrderData = [];
    this.detailsReportData = [];
    
    this.showLoader = true;
    this.disabledReportBtn = true;
    this.service.sealsOrderReportBodyModel.fromDate = this.datepicker.toModel(data.fromDate);
    this.service.sealsOrderReportBodyModel.toDate = this.datepicker.toModel(data.toDate);        

    this.service.getSealsOrderReport().subscribe((data) => {     
      
      this.reportOrderData = data;
      this.service.sealsOrderReportBodyModel.fromDate = this.datepicker.fromModel(this.service.sealsOrderReportBodyModel.fromDate);
      this.service.sealsOrderReportBodyModel.toDate = this.datepicker.fromModel(this.service.sealsOrderReportBodyModel.toDate);
      
      //this.service.sealsOrderReportBodyModel.toDate = this.service.sealsOrderReportBodyModel.toDate + ' 23:59:59';
      
      this.subTotal = 0;
      this.vatTotal = 0;
      this.discountTotal = 0;
      this.deliveryChargeTotal = 0;
      this.grandTotal = 0;


      this.inHouseTotalAmount = 0;     
      this.shohozFoodTotalAmount = 0;
      this.dhanmondiTotalAmount = 0; 
      this.baridharaTotalAmount = 0;
      this.uttaraTotalAmount =0;
      this.foodPandaTotalAmount = 0;

      this.pathaoTotalAmount = 0;
      this.foodiTotalAmount = 0;
      this.hungrynakiTotalAmount = 0;

      this.foodPandaSubtotal = 0; 
      this.pandaGoSubtotal = 0; 
      this.shohozFoodSubTotal = 0;
      this.pathaoSubtotal = 0;
      this.foodiSubtotal =0;
      this.hungryNakiSubtotal = 0;

      this.pizzamiaTotalSalesBaridhara = 0;
      this.pizzamiaTotalSalesDhanmondi = 0;

      this.hungryRoosterTotalSalesBaridhara = 0;
      this.hungryRoosterTotalSalesDhanmondi = 0;

      this.pimentosTotalSalesBaridhara = 0;
      this.pimentosTotalSalesDhanmondi = 0;

      this.jhotpotTotalSalesBaridhara = 0;
      this.jhotpotTotalSalesDhanmondi = 0;

      this.cleanCravingsTotalSalesBaridhara = 0;
      this.cleanCravingsTotalSalesDhanmondi = 0;

      this.CombineBaridhara = 0;
      this.CombineDhanmondi = 0;
      this.CombineUttara = 0;

      for (let order of this.reportOrderData) { 
        if(order.subTotal){
          //this.allSubTotalCalculation(order.subTotal);
          //this.subTotal += order.subTotal;
        }
        if(order.incomeTax){
          //this.allIncomeTaxCalculation(order.incomeTax);
          this.vatTotal += order.incomeTax;
        }
        if(order.discount){
          //this.allDiscountCalculation(order.discount);
          //this.discountTotal += order.discount;
        }
        if(order.deliveryCharge){
          //this.deliveryChargeTotal(order.deliveryCharge);
        }
        if(order.grandTotal){
          this.allTotalCalculation(order.grandTotal);
        }        
        if(order.orderType == 1){
          this.allInHouseCalculation(order.grandTotal);
        }
        if(order.orderType == 2){
          this.allFoodPandaCalculation(order.grandTotal);
          this.foodPandaSubtotal += order.subTotal;
        }
        if(order.orderType == 3){
          this.allShohozFoodCalculation(order.grandTotal);
          this.shohozFoodSubTotal += order.subTotal;
        }
        if(order.orderType == 4){          
          this.allPathaoCalculation(order.grandTotal);
          this.pathaoSubtotal += order.subTotal;
        }
       
        if(order.orderType == 6){
          this.allHungrynakiCalculation(order.grandTotal);
          this.hungryNakiSubtotal += order.subTotal;
        }
        if(order.orderType == 9){
          //this.allPandagoCalculationtion(order.grandTotal);
          this.pandaGoSubtotal += order.subTotal;
        }
         if(order.orderType == 11){          
          this.allFoodiCalculation(order.grandTotal);
          this.foodiSubtotal += order.subTotal;
        }

        if(order.branchID == 1){
          this.baridharaTotalAmount += order.grandTotal;
        }
        if(order.branchID == 2){
          this.dhanmondiTotalAmount += order.grandTotal;
        }
        if(order.branchID == 3){
          this.uttaraTotalAmount += order.grandTotal;
        }
        

        order.customerName  += "-" + order.mobile;
      } 

      
      if(this.reportOrderData.length > 0){
        this.pizzamiaTotalSalesBaridhara = this.reportOrderData[0].pizzamiaSalesBaridhara;
        //this.pizzamiaTotalSalesBaridhara += (this.pizzamiaTotalSalesBaridhara * 0.10);

        this.pizzamiaTotalSalesDhanmondi = this.reportOrderData[0].pizzamiaSalesDhanmondi;
        //this.pizzamiaTotalSalesDhanmondi += (this.pizzamiaTotalSalesDhanmondi * 0.10);

        this.pizzamiaTotalSalesUttara = this.reportOrderData[0].pizzamiaSalesUttara;
        //this.pizzamiaTotalSalesUttara += (this.pizzamiaTotalSalesUttara * 0.10);


        this.hungryRoosterTotalSalesBaridhara = this.reportOrderData[0].hungryRoosterSalesBaridhara;
        //this.hungryRoosterTotalSalesBaridhara += (this.hungryRoosterTotalSalesBaridhara * 0.10);

        this.hungryRoosterTotalSalesDhanmondi = this.reportOrderData[0].hungryRoosterSalesDhanmondi;
        //this.hungryRoosterTotalSalesDhanmondi += (this.hungryRoosterTotalSalesDhanmondi * 0.10);

        this.hungryRoosterTotalSalesUttara = this.reportOrderData[0].hungryRoosterSalesUttara;
        //this.hungryRoosterTotalSalesUttara += (this.hungryRoosterTotalSalesUttara * 0.10);


        this.pimentosTotalSalesBaridhara = this.reportOrderData[0].pimentosSalesBaridhara;
        //this.pimentosTotalSalesBaridhara += (this.pimentosTotalSalesBaridhara * 0.10);        

        this.pimentosTotalSalesDhanmondi = this.reportOrderData[0].pimentosSalesDhanmondi;
        //this.pimentosTotalSalesDhanmondi += (this.pimentosTotalSalesDhanmondi * 0.10);

        this.pimentosTotalSalesUttara = this.reportOrderData[0].pimentosSalesUttara;
        //this.pimentosTotalSalesUttara += (this.pimentosTotalSalesUttara * 0.10);

        //Shukraan
        this.shukraanTotalSalesBaridhara = this.reportOrderData[0].shukraanSalesBaridhara;
        this.shukraanTotalSalesDhanmondi = this.reportOrderData[0].shukraanSalesDhanmondi;
        this.shukraanTotalSalesUttara = this.reportOrderData[0].shukraanSalesUttara;

        //Shukraan
        this.jhotpotTotalSalesBaridhara = this.reportOrderData[0].jhotpotSalesBaridhara;
        this.jhotpotTotalSalesDhanmondi = this.reportOrderData[0].jhotpotSalesDhanmondi;
        this.jhotpotTotalSalesUttara = this.reportOrderData[0].jhotpotSalesUttara;

        //Clean Cravings
        this.cleanCravingsTotalSalesBaridhara = this.reportOrderData[0].cleanCravingsSalesBaridhara;
        this.cleanCravingsTotalSalesDhanmondi = this.reportOrderData[0].cleanCravingsSalesDhanmondi;
        this.cleanCravingsTotalSalesUttara = this.reportOrderData[0].cleanCravingsSalesUttara;
 
        debugger
        this.CombineBaridhara = this.reportOrderData[0].combineBaridhara;
        this.CombineDhanmondi = this.reportOrderData[0].combineDhanmondi;
        this.CombineUttara = this.reportOrderData[0].combineUttara;

       this.dhanmondiTotalAmount = (this.pizzamiaTotalSalesDhanmondi + this.hungryRoosterTotalSalesDhanmondi + this.pimentosTotalSalesDhanmondi+ this.jhotpotTotalSalesDhanmondi +this.cleanCravingsTotalSalesDhanmondi+ this.CombineDhanmondi);        
       this.baridharaTotalAmount = (this.pizzamiaTotalSalesBaridhara + this.hungryRoosterTotalSalesBaridhara + this.pimentosTotalSalesBaridhara + this.jhotpotTotalSalesBaridhara + this.cleanCravingsTotalSalesBaridhara+ this.CombineBaridhara);
       this.uttaraTotalAmount = (this.pizzamiaTotalSalesUttara + this.hungryRoosterTotalSalesUttara + this.pimentosTotalSalesUttara + this.jhotpotTotalSalesUttara + this.cleanCravingsTotalSalesUttara + this.CombineUttara);

        this.pizzamiaTotal = (this.pizzamiaTotalSalesDhanmondi + this.pizzamiaTotalSalesBaridhara + this.pizzamiaTotalSalesUttara);
        this.hungryRoosterTotal = (this.hungryRoosterTotalSalesDhanmondi + this.hungryRoosterTotalSalesBaridhara + this.hungryRoosterTotalSalesUttara);
        this.pimentosTotal = (this.pimentosTotalSalesDhanmondi + this.pimentosTotalSalesBaridhara + this.pimentosTotalSalesUttara);
        this.shukraanTotal = (this.shukraanTotalSalesDhanmondi + this.shukraanTotalSalesBaridhara + this.shukraanTotalSalesUttara);
        this.jhotpotTotal = (this.jhotpotTotalSalesDhanmondi + this.jhotpotTotalSalesBaridhara + this.jhotpotTotalSalesUttara);
        this.cleanCravingsTotal = (this.cleanCravingsTotalSalesDhanmondi + this.cleanCravingsTotalSalesBaridhara + this.cleanCravingsTotalSalesUttara);
       //
        //this.pizzamiaTotal = (this.pizzamiaTotalSalesDhanmondi + this.pizzamiaTotalSalesBaridhara + this.pizzamiaTotalSalesUttara);
        //this.hungryRoosterTotal = (this.hungryRoosterTotalSalesDhanmondi + this.hungryRoosterTotalSalesBaridhara + this.hungryRoosterTotalSalesUttara);
        //this.pimentosTotal = (this.pimentosTotalSalesDhanmondi + this.pimentosTotalSalesBaridhara + this.pimentosTotalSalesUttara);
        
        this.combineTotal = (this.reportOrderData[0].combineBaridhara + this.reportOrderData[0].combineDhanmondi + this.reportOrderData[0].combineUttara);

      }
      
      this.foodPandaCommission =  (this.foodPandaSubtotal * 0.30);
      this.shohozFoodCommission = (this.shohozFoodSubTotal * 0.00)
      this.pathaoCommission =  (this.pathaoSubtotal * 0.23);
      this.foodiCommission =  (this.foodiSubtotal * 0.15);
      this.hungrynakiCommission =  (this.hungryNakiSubtotal * 0.10);
      
      this.totalCommission = (this.foodPandaCommission + this.shohozFoodCommission + this.pathaoCommission + this.foodiCommission + this.hungrynakiCommission);
      this.netTotal = (this.grandTotal - this.totalCommission);      
      this.showLoader = false;
      this.disabledReportBtn = false;
    },
    err => {   
      this.showLoader = false;
      this.disabledReportBtn = false;   
      this.toastr.error(err);
      this.service.sealsOrderReportBodyModel.fromDate = this.datepicker.fromModel(this.service.sealsOrderReportBodyModel.fromDate);
      this.service.sealsOrderReportBodyModel.toDate = this.datepicker.fromModel(this.service.sealsOrderReportBodyModel.toDate);
      
    })
  }

  detailsReport(data: any) {

    this.reportOrderData = [];
    this.detailsReportData = [];

    this.showLoader = true;
    this.disabledReportBtn = true;
    this.service.sealsOrderReportBodyModel.fromDate = this.datepicker.toModel(data.fromDate);
    this.service.sealsOrderReportBodyModel.toDate = this.datepicker.toModel(data.toDate);        

    this.service.getSealsOrderReport().subscribe((data) => {
      
      this.reportOrderData = [];
      this.detailsReportData = data;
      this.service.sealsOrderReportBodyModel.fromDate = this.datepicker.fromModel(this.service.sealsOrderReportBodyModel.fromDate);
      this.service.sealsOrderReportBodyModel.toDate = this.datepicker.fromModel(this.service.sealsOrderReportBodyModel.toDate);
      
      //this.service.sealsOrderReportBodyModel.toDate = this.service.sealsOrderReportBodyModel.toDate + ' 23:59:59';
      
      this.subTotal = 0;
      this.vatTotal = 0;
      this.discountTotal = 0;
      this.deliveryChargeTotal = 0;
      this.grandTotal = 0;

      this.inHouseTotalAmount = 0;
      this.foodPandaTotalAmount = 0;
      this.foodPandaCommission = 0;
      this.shohozFoodTotalAmount = 0;
      this.dhanmondiTotalAmount = 0; 
      this.baridharaTotalAmount = 0;
      this.uttaraTotalAmount = 0;

      this.foodPandaSubtotal = 0;
      this.pathaoSubtotal = 0; 
      this.foodiSubtotal = 0; 
      this.hungryNakiSubtotal = 0;

      this.pizzamiaTotalSalesBaridhara = 0;
      this.pizzamiaTotalSalesDhanmondi = 0;
      this.hungryRoosterTotalSalesBaridhara = 0;
      this.hungryRoosterTotalSalesDhanmondi = 0;
      this.cleanCravingsTotalSalesBaridhara = 0;
      this.cleanCravingsTotalSalesDhanmondi = 0;

      for (let order of this.detailsReportData) {
        if(order.subTotal){
          //this.allSubTotalCalculation(order.subTotal);
          this.subTotal += order.subTotal;
        }
        if(order.incomeTax){
          //this.allIncomeTaxCalculation(order.incomeTax);
          this.vatTotal += order.incomeTax;
        }
        if(order.discount){
          //this.allDiscountCalculation(order.discount);
          this.discountTotal += order.discount;
        }
        if(order.deliveryCharge){
          //this.deliveryChargeTotal(order.deliveryCharge);
          this.deliveryChargeTotal += order.deliveryCharge;
        }
        if(order.grandTotal){
          //this.allTotalCalculation(order.grandTotal);
          this.grandTotal += order.grandTotal;
        }         
        if(order.orderType == 1){
          this.allInHouseCalculation(order.grandTotal);
        }
        if(order.orderType == 2){
          this.allFoodPandaCalculation(order.grandTotal);
          this.foodPandaSubtotal += order.subTotal;
        }
        if(order.orderType == 3){
          this.allShohozFoodCalculation(order.grandTotal);  
          this.shohozFoodSubTotal += order.subTotal;        
        }
        if(order.orderType == 4){
          this.allPathaoCalculation(order.grandTotal); 
          this.pathaoSubtotal += order.subTotal;        
        }
        if(order.orderType == 11){
          this.allFoodiCalculation(order.grandTotal); 
          this.foodiSubtotal += order.subTotal;        
        }
        if(order.orderType == 6){
          this.allHungrynakiCalculation(order.grandTotal); 
          this.hungryNakiSubtotal += order.subTotal;        
        }
        if(order.orderType == 9){
          this.allPandagoCalculation(order.grandTotal); 
          this.pandaGoSubtotal += order.subTotal;        
        }

        if(order.branchID == 1){
          this.baridharaTotalAmount += order.grandTotal;
        }
        if(order.branchID == 2){
          this.dhanmondiTotalAmount += order.grandTotal;
        }
        if(order.branchID == 3){
          this.uttaraTotalAmount += order.grandTotal;
        }
      }
      if(this.detailsReportData.length > 0){
        debugger
        this.pizzamiaTotalSalesBaridhara = this.detailsReportData[0].pizzamiaSalesBaridhara;
        this.pizzamiaTotalSalesDhanmondi = this.detailsReportData[0].pizzamiaSalesDhanmondi;

        this.hungryRoosterTotalSalesBaridhara = this.detailsReportData[0].hungryRoosterSalesBaridhara;
        this.hungryRoosterTotalSalesDhanmondi = this.detailsReportData[0].hungryRoosterSalesDhanmondi;

        //this.pimentosTotalSalesBaridhara = this.reportOrderData[0].pimen
      }

      this.foodPandaCommission =  (this.foodPandaSubtotal * 0.30);
      this.pandaGoCommission =  (this.pandaGoSubtotal * 0.30);
      this.shohozFoodCommission = (this.shohozFoodSubTotal * 0.00)
      this.pathaoCommission =  (this.pathaoSubtotal * 0.23);
      this.foodiCommission =  (this.foodiSubtotal * 0.15);
      this.hungrynakiCommission =  (this.hungryNakiSubtotal * 0.10);

      this.totalCommission = (this.foodPandaCommission + this.shohozFoodCommission + this.pathaoCommission + this.foodiCommission + this.hungrynakiCommission);
      this.netTotal = (this.grandTotal - this.totalCommission);
      this.showLoader = false;
      this.disabledReportBtn = false;
    },
    err => {
      this.showLoader = false;
      this.disabledReportBtn = false;
      this.toastr.error(err);
      this.service.sealsOrderReportBodyModel.fromDate = this.datepicker.fromModel(this.service.sealsOrderReportBodyModel.fromDate);
      this.service.sealsOrderReportBodyModel.toDate = this.datepicker.fromModel(this.service.sealsOrderReportBodyModel.toDate);
      
    })
  }

  allSubTotalCalculation(data:any){
    this.subTotal +=data;
  }
  allIncomeTaxCalculation(data:any){
    this.vatTotal += data;
  }
  allDiscountCalculation(data:any){
    this.discountTotal += data;
  }
  deliveryChargeCalculation(data:any){
    this.deliveryChargeTotal += data;
  }
  allTotalCalculation(data:any){
    this.grandTotal += data;
  }
  
  allInHouseCalculation(data:any){
    this.inHouseTotalAmount += data;
  } 

  allShohozFoodCalculation(data:any){
    this.shohozFoodTotalAmount += data;
  }

  allFoodPandaCalculation(data:any){
    this.foodPandaTotalAmount += data;
  }

  allPathaoCalculation(data:any){
    this.pathaoTotalAmount += data;
  }

  allFoodiCalculation(data:any){
    this.foodiTotalAmount += data;
  }

  allHungrynakiCalculation(data:any){
    this.hungrynakiTotalAmount += data;
  }

  allPandagoCalculation(data:any){
    this.pandaGoTotalAmount += data;
  }

  resetSealsOrderReportBodyModel() {
    this.service.sealsOrderReportBodyModel = {
      fromDate: this.ngbCalendar.getToday(),
      toDate: this.ngbCalendar.getToday(),
      orderType: "",
      customerID: "",
      orderStatusId: "",
      branchID: 0
    }
  }
  resetParameters() {
    this.orderService.parameters = {
      fromDate: "",
      toDate: "",
      branchID: 0,
      orderTypeName: "",
      orderStatusName: "",
      customerName: ""
    }
  }

  //Search Dropdown
  searchCustomerDropDown(){    
    if (this.searchValue.length > 2) {
      this.orderService.parameters.searchValue = this.searchValue;
      this.orderService.parameters.pageSize = this.pageSize;
      this.orderService.parameters.pageIndex = this.pageIndex;
      this.searchCustomerList(this.orderService.parameters);
    }else{
      this.customerDropList = [];
      this.orderService.parameters.customerName = "";
      this.service.sealsOrderReportBodyModel.customerID = 0;
      // if(!this.searchValue){
      //   this.searchOrder();
      // }
    }
    
  }

  searchCustomerList(customer: any){[
    this.orderService.searchCustomerList().subscribe((data) =>{
      this.customerDropList = data;
    })
  ]}  

  searchBoxClicked = function ($event) {
      $event.stopPropagation();
  }
  // Set value to search box
  setValue = function (index, $event) {
    debugger
      this.searchValue = this.customerDropList[index].name;
      this.orderService.parameters.customerName = this.customerDropList[index].name;
      this.service.sealsOrderReportBodyModel.customerID = this.customerDropList[index].customerID;
      this.customerDropList = [];
      $event.stopPropagation();
      // this.searchOrder();
      
  }

  captureScreen() {
    const printContent = document.getElementById("htmlData");
    const WindowPrt = window.open('', '_blank', 'left=0,top=0,toolbar=0,scrollbars=0,height=100%,width=auto,status=0');
    WindowPrt.document.open();
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.print();
  }
  public downloadPDF()  
  {  
    debugger
    let doc = new jsPDF();
    doc.addHTML(this.htmlData.nativeElement, function() {
       doc.save("SalesReport.pdf");
    });  
  } 
  // public openPDF():void {
  //   let DATA = this.htmlData.nativeElement;
  //   let doc = new jsPDF('p','pt', 'a4');
  //   doc.fromHTML(DATA.innerHTML,15,15);
  //   doc.output('dataurlnewwindow');
  // }


  // public downloadPDF():void {
  //   let DATA = this.htmlData.nativeElement;
  //   let doc = new jsPDF('p','px', 'a4');

  //   let handleElement = {
  //     '#editor':function(element,renderer){
  //       return true;
  //     }
  //   };
  //   doc.fromHTML(DATA.innerHTML,12,12,{
  //     'width': 720,
  //     'elementHandlers': handleElement
  //   });

  //   doc.save('SalesReport.pdf');
  // }
  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);      
  }

  generateExcel() {
    this.excelService.generateExcel(this.reportOrderData);
  }


}
