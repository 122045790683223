
<style>
    .table > tfoot > tr > td {
        border: none;
    }
    .filter_content {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.filter_content .filter_col {
    padding: 0 15px;
    -webkit-box-flex: 0;
    flex: 0 0 30%;
    max-width: 30%;
    width: 30%;
}
/* .filter_content .filter_col:first-child {
    padding-left: 0px;
} */

.filter_content .filter_col:nth-child(3) {
    width: 40%;
    -webkit-box-flex: 0;
    flex: 0 0 40%;
    max-width: 40%;
}

.filter_container .filter_content_btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.btn-footer{
    width: 25%;
    float:right;
}

.comments{
    width: 215px;
    padding: 10px;
    margin-top: 10px;
    font-family: Montserrat;
    font-size: 12px;
    /* border: 1px solid !important; */
    height: 78px;
    float: left;
}

    .content-font{
        font-family: Arial, sans-serif;
    }

</style>

<div class="page-container">  
    <app-header></app-header> 
    <div class="page-content-wrapper ">
        <div class="content ">
          <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <!-- START BREADCRUMB -->
                <ul class="breadcrumb">
                  <li>
                    <p>Devour</p>
                  </li>
                  <li><a class="active">Order</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
            <div class="container-fluid container-fixed-lg custom-container">
                <div class="panel panel-transparent">
                    <div class="panel-heading">
                        <div class="panel-title">
                            Order
                        </div>
                
                        <div class="pull-right m-t-15">
                            <div *ngIf="showOrderHiddenField" id="show-modal" class="btn btn-success" (click)="redirectionFunction()">
                                <i class="fa fa-plus pr1"></i>
                                <span>Add New</span>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                
                    <div class="panel-body">
                        <div class="filter_container">
                            <div class="filter_content">

                                <div class="filter_col">
                                    <div class="form-group form-group-default input-group angular-date">
                                        <label>From Date</label>
                                        <input class="form-control" placeholder="yyyy-mm-dd"
                                                 name="dp" [(ngModel)]="service.parameters.fromDate" ngbDatepicker #d="ngbDatepicker" (dateSelect)="searchOrder()">
                                        <span class="input-group-addon" (click)="d.toggle()"><i class="fa fa-calendar"></i></span>
                                      </div>                                      
                                </div>
                                <div class="filter_col">
                                    <div class="form-group form-group-default input-group angular-date">
                                        <label>To Date</label>
                                        <input class="form-control" placeholder="yyyy-mm-dd"
                                                 name="dp" [(ngModel)]="service.parameters.toDate" ngbDatepicker #t="ngbDatepicker" (dateSelect)="searchOrder()">
                                        <span class="input-group-addon" (click)="t.toggle()"><i class="fa fa-calendar"></i></span>
                                      </div>                                      
                                </div>

                                <div class="form-group form-group-default form-group-default-select2 required" style="width: 290px;">
                                    <label>Branch</label>                                        
                                <ng-select bindLabel="branchName"                                    
                                    placeholder="Select Branch"
                                    appendTo="body"  
                                    [(ngModel)]="service.parameters.branchID" 
                                    name="BranchID"
                                    #BranchID="ngModel"
                                    (change)="searchOrder()"
                                    [disabled]= "disableBranch"
                                    [ngModelOptions]="{standalone: true}">                                                                
                                    <ng-option [value]="branch.BranchID" *ngFor="let branch of branchList">
                                        {{branch.Name}}
                                    </ng-option>
                                </ng-select>
                                </div>
                            </div>
                        </div>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th colspan="2" style="padding-right: 0px;color: #e947d5">
                                        TOTAL ORDER'S - {{totalOrder}}
                                    </th>
                                    <th colspan="2" style="padding-right: 0px;color: #e947d5">
                                        NEW CUSTOMER - {{newCustomer}} 
                                    </th>
                                    <th colspan="5" style="padding-right: 0px;">
                                        <input [(ngModel)]="service.parameters.orderID" #OrderID="ngModel" (keypress)="orderNoWiseSearch($event)" class="input-sm form-control topsearch" type="search" title="Input order number and press enter." placeholder="Order Search" style="width: 200px; float: right" />
                                    </th>
                                    <th colspan="2" style="padding-right: 0px;">
                                        <input id="checkOutCardNO" name="BarCode" [(ngModel)]="service.parameters.barCode" #BarCode="ngModel" (change)="searchBarCodeWiseOrder()" class="input-sm form-control topsearch" type="search" placeholder="Barcode Search" style="width: 200px; float: right" />
                                    </th>
                                </tr>
                                <tr>
                                    <!-- <th width="3%" style="text-align: center">Sl</th> -->
                                    <th>Order ID</th>
                                    <th>Order Type</th>
                                    <th>Customer Name</th>
                                    <th>Type</th>
                                    <th>Restaurant</th>
                                    <th>Branch</th>
                                    <th>O.Time</th>
                                    <th>D.Time</th>
                                    <th>Amount</th>
                                    <th width="10%">Status</th>
                                    <th *ngIf="showOrderHiddenField" width="8%" style="text-align: right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let order of orderList | paginate: { itemsPerPage: perPage, currentPage: p }; let sl = index">
                                    <!-- <td style="text-align: center">{{sl+1}}</td> -->
                                    <td>ORD{{order.orderID}}</td>
                                    <td>{{order.orderTypeName}}</td>
                                    <td>{{order.customerName}}</td>
                                    <td *ngIf="order.isNewCustomer == 1" style="color: #e947d5">New</td>
                                    <td *ngIf="order.isNewCustomer != 1">Existing</td>
                                    <td *ngIf="order.kitchenID == 0" style="color: rgb(50, 28, 241);">Combine</td>
                                    <td *ngIf="order.kitchenID == 1">Pizzamia</td>
                                    <td *ngIf="order.kitchenID == 2">Hungry Rooster</td>
                                    <td *ngIf="order.kitchenID == 3">Pimentos</td>
                                    <td *ngIf="order.kitchenID == 4">Shukraan</td>
                                    <td *ngIf="order.kitchenID == 5">Jhotpot</td>
                                    <td *ngIf="order.kitchenID == 6">Clean Cravings</td>
                                    <td *ngIf="order.branchID == 0" style="color: rgb(235, 109, 25);">{{order.branchName}}</td>
                                    <td *ngIf="order.branchID == 1" style="color: rgb(235, 109, 25);">{{order.branchName}}</td>
                                    <td *ngIf="order.branchID == 2" style="color: rgb(50, 28, 241);">{{order.branchName}}</td>
                                    <td *ngIf="order.branchID == 3" style="color: rgb(0, 135, 81);">{{order.branchName}}</td>
                                    <td>{{order.orderTime | date: 'shortTime'}}</td>
                                    <td>{{order.deliveryTime}}</td>
                                    <td>{{order.grandTotal}}</td>
                                    <td>  
                                        <ng-select bindLabel="name"
                                            placeholder="Select Status"
                                            appendTo="body"                            
                                            [searchable]="false" 
                                            [clearable]="true"
                                            [(ngModel)]="order.orderStatus"
                                            name="OrderStatus"
                                            #OrderStatus="ngModel"
                                            (change)="updateOrderStatus(order)"
                                            [ngModelOptions]="{standalone: true}"
                                            [ngClass]="{
                                                'btn-primary':order.orderStatus === 0,
                                                'btn-success':order.orderStatus === 1,
                                                'btn-orange':order.orderStatus === 2,
                                                'btn-purple':order.orderStatus === 3,
                                                'btn-green':order.orderStatus === 4,
                                                'btn-danger':order.orderStatus === 5
                                              }" [disabled]="statusCombo">                            
                                            <ng-option [value]="status.id" *ngFor="let status of statusList">
                                                {{status.name}}
                                            </ng-option>
                                        </ng-select>
                                    </td>
                                    <td align="right" *ngIf="showOrderHiddenField">
                                        <!-- <button type="button" class="btn btn-primary btn-xs" (click)="viewOrder(order)"  data-toggle="modal" data-target="#myModal">
                                            <i class="fa fa-eye"></i>
                                        </button> -->
                                        <button type="button" class="btn btn-primary btn-xs" (click)="orderWiseAllOrderInformation(order)" >
                                            <i class="fa fa-eye"></i>
                                        </button>
                                        <button type="button" class="btn btn-success btn-xs" [disabled]="order.disabledBtn" (click)="updateForm(order)">
                                            <i class="fa fa-pencil"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="10" class="Page-align">
                                        <div><pagination-controls (pageChange)="onPageChange($event)"></pagination-controls></div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>  
                    </div>
                
                    
                
                <div class="modal fade" id="myModalReport" data-backdrop="static" data-keyboard="false" role="dialog" aria-hidden="false">
                    <div class="modal-dialog" style="width: 738px; margin-top: 60px !important;height: calc(100vh - 120px);overflow-y: auto;">
                      <div class="modal-content">  
                        <!-- Modal Header -->
                        <div class="modal-header text-left">
                            <button type="button" class="close" (click)="reportClose()" aria-hidden="true">
                                    <i class="pg-close fs-14"></i>
                                </button>
                            <div class="bold">Order Preview33</div>
                        </div>
                  
                        <!-- Modal body -->
                        <div class="modal-body" style="margin-top: 25px;padding: 0px;">
                            <div id="report_viewer" *ngIf="reportOrderData" style="width: 720px;height: auto; margin: 0;display: inline-block;float: left;padding-left: 15px;">
                                <!-- height: 950px; -->
                                <style>
                                    .report_order_details_data .table tbody tr td, .report_order_details_data .table thead tr th, .report_order_details_data .table tfoot tr td {
                                        font-size: 12px !important;
                                    }
                                </style>
                                <div class="report_container report_viewer" style="background: #fff;padding-right: 20px;width: 235px; height: auto;margin: 0;display: inline-block;flex-direction: column;float: left;padding-top: 0px;" 
                                *ngFor="let count of countReceipt">
                                <div class="report_logo">
                                    <img *ngIf="reportOrderData.orderDataModels.kitchenID == 0" src="../assets/img/hungry_rooster_logo.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;">
                                    <img  *ngIf="reportOrderData.orderDataModels.kitchenID == 1" src="../assets/img/pizza-mia-logo.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;">
                                    <img *ngIf="reportOrderData.orderDataModels.kitchenID == 2" src="../assets/img/hungry_rooster_logo.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;">
                                    <img *ngIf="reportOrderData.orderDataModels.kitchenID == 3" src="../assets/img/pimentos-logo.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;">                                
                                    <img *ngIf="reportOrderData.orderDataModels.kitchenID == 4" src="../assets/img/shukraan-g.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;">                                
                                    <img *ngIf="reportOrderData.orderDataModels.kitchenID == 5" src="../assets/img/Jhotpot.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;"> 
                                    <img *ngIf="reportOrderData.orderDataModels.kitchenID == 6" src="../assets/img/cleanCravings.png" alt="logo" class="brand" style="height: 50px;padding-left: 8px;padding-bottom: 0px;">                                
                                </div>
                                    <div class="report_order_content" style="padding: 0 8px; margin-top: 20px;">
                                        <div style="font-weight: bold;font-size: 14px;color: #000;font-family: 'Montserrat';">Devour Limited</div>
                                        <div style="font-weight: bold;font-size: 12px;color: #000;font-family: 'Montserrat';">Sender Details</div>
                                        <div style="font-family: 'Montserrat';font-size: 12px;"><span>Order:</span><span style="font-family: Arial, sans-serif;"> ORD{{reportOrderData.orderDataModels.orderID}}</span></div>
                                        <div style="font-family: 'Montserrat';font-size: 12px;"><span>Order Date:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.orderDate | date}}</span></div>
                                        <div *ngIf="reportOrderData.orderDataModels.deliveryTime" style="font-family: 'Montserrat';font-size: 12px;"><span>Delivery Time:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.deliveryTime}}</span></div>
                                        <div style="font-family: 'Montserrat';font-size: 12px;"><span>Brand:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.kitchenName}}</span></div>
                                        <div style="font-family: 'Montserrat';font-size: 12px;"><span>Order Type:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.orderTypeName}}</span></div>
                                        <div style="font-family: 'Montserrat';font-size: 12px;"><span>VAT Reg:</span><span style="font-family: Arial, sans-serif;"> 002906561-0101</span></div>
                                        <div style="font-family: 'Montserrat';font-size: 12px;"><span>MUSAK:</span><span style="font-family: Arial, sans-serif;"> 6.3</span></div>

                                        <div style="font-weight: bold;font-size: 12px;color: #000;font-family: 'Montserrat';margin-top: 20px;">Recipient Details</div>
                                        <div style="font-family: 'Montserrat';font-size: 12px;"><span>Name:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.customerName}}</span></div>
                                        <div style="font-family: 'Montserrat';font-size: 12px;"><span>Mobile:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.customerMobile}}</span></div>
                                        <div style="font-family: 'Montserrat';font-size: 12px;"><span>Address:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.fullAddress}}</span></div>
                                    </div>
                                    <div class="report_order_details_content" style="display: flex;flex-direction: column;justify-content: space-between;height: auto;">
                                        <div class="report_order_details_data">
                                            <table id="example" class="table" style="width:100%; margin-top: 6px;background: #fff;border-spacing: 0;border-collapse: collapse;" *ngIf="reportOrderData">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 45%; font-weight: bold;color: #000;text-transform: uppercase;border-bottom: 1px solid #000;text-align: left;font-family: 'Montserrat';padding: 10px 50px !important;">Item</th>
                                                        <!-- <th style="font-weight: bold;color: #000;text-transform: uppercase;border-bottom: 1px solid #000;text-align: left;font-family: 'Montserrat';">Item Type</th> -->
                                                        <th style="width: 5%; font-weight: bold;color: #000;text-transform: uppercase;border-bottom: 1px solid #000;text-align: left;font-family: 'Montserrat';padding: 10px 0px !important;">Qty</th>
                                                        <th style="width: 15%; text-align: right;font-weight: bold;color: #000;text-transform: uppercase;border-bottom: 1px solid #000;text-align: right;font-family: 'Montserrat';padding: 5px 0px !important;">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let c of reportOrderData.orderDetailsDataModels">
                                                        <!-- <td *ngIf="reportOrderData.orderDataModels.kitchenID == 1" style="border-bottom: none;font-size:small;font-family: Arial, sans-serif;padding: 5px 6px !important;"> {{c.itemName}}<span *ngIf="c.itemType"> ({{c.itemType}})</span></td> -->
                                                        <td style="border-bottom: none;font-size:small;font-family: Arial, sans-serif;padding: 5px 6px !important;"> {{c.itemNameNflavour}}</td>
                                                        <!--<span *ngIf="c.itemType"> ({{c.itemType}})</span>-->
                                                        <!-- <td style="border-bottom: 1px solid #000;font-family: Arial, sans-serif;">{{c.itemType}}</td> -->
                                                        <td style="border-bottom: none;font-family: Arial, sans-serif;padding: 10px 6px !important;">{{c.quantity}}</td>
                                                        <td style="text-align: right;border-bottom: none;font-family: Arial, sans-serif;padding: 10px 6px !important;">{{c.unitRate | number: '1.2-2'}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <!-- <td colspan="1" style="border-bottom: none;border-top: none;"></td> -->
                                                        <td colspan="2" style="text-align: left;border-bottom: none;border-top: 1px solid #000 !important;font-family: 'Montserrat';padding: 8px !important;">Sub Total</td>
                                                        <td style="text-align: right;border-bottom: none;border-top: 1px solid #000 !important;padding: 8px !important;">{{reportOrderData.orderDataModels.subTotal | number: '1.2-2'}}</td>
                                                    </tr>
                                                    <tr class="">
                                                        <td colspan="2" style="padding: 8px !important;font-weight: normal;text-align: left;border-top: 1px solid #000;font-family: 'Montserrat';">{{vatLevel}}</td>
                                                        <td style="padding: 8px !important;font-weight: normal;text-align: right;border-top: 1px solid #000;">{{reportOrderData.orderDataModels.incomeTax | number: '1.2-2'}}</td>
                                                    </tr>
                                                    <!-- <tr *ngIf="reportOrderData.orderDataModels.serviceCharge">
                                                        <td colspan="2" style="padding: 8px !important;font-weight: normal;text-align: left;border-top: 1px solid #000;font-family: 'Montserrat';">Service Charge(5%)</td>
                                                        <td style="padding: 8px !important;font-weight: normal;text-align: right;border-top: 1px solid #000;">{{reportOrderData.orderDataModels.serviceCharge | number: '1.2-2'}}</td>
                                                    </tr> -->
                                                    <tr *ngIf="reportOrderData.orderDataModels.serviceCharge">
                                                        <td colspan="2" style="padding: 8px !important;font-weight: normal;text-align: left;border-top: 1px solid #000;font-family: 'Montserrat';">Service Charge(7.5%)</td>
                                                        <td style="padding: 8px !important;font-weight: normal;text-align: right;border-top: 1px solid #000;">{{reportOrderData.orderDataModels.serviceCharge | number: '1.2-2'}}</td>
                                                    </tr>
                                                   
                                                    <tr *ngIf="reportOrderData.orderDataModels.deliveryCharge">
                                                        <!-- <td colspan="1" style="border-bottom: none;border-top: none;"></td> -->
                                                        <td colspan="2" style="text-align: left;border-bottom: 1px solid #000;border-top: none;font-family: 'Montserrat';padding: 8px !important;">Delivery Charge</td>
                                                        <td style="text-align: right;border-bottom: 1px solid #000;border-top: none;padding: 8px !important;">{{reportOrderData.orderDataModels.deliveryCharge | number: '1.2-2'}}</td>
                                                    </tr>
                                                    <!-- <tr *ngIf="reportOrderData.orderDataModels.vat">
                                                        <td colspan="2" style="text-align: left;border-bottom: none;border-top: none;font-family: 'Montserrat';padding: 8px !important;">VAT</td>
                                                        <td style="text-align: right;border-bottom: none;border-top: none;padding: 8px !important;">{{reportOrderData.orderDataModels.vat}}</td>
                                                    </tr> -->
                                                    <tr *ngIf="reportOrderData.orderDataModels.discount">
                                                        <td colspan="2" style="text-align: left;border-bottom: none;border-top: none;font-family: 'Montserrat';padding: 8px !important;">Total</td>
                                                        <td style="text-align: right;border-bottom: none;border-top: none;padding: 8px !important;">{{reportOrderData.orderDataModels.total | number: '1.2-2'}}</td>
                                                        <!-- <td colspan="1" style="border-bottom: none;border-top: none;"></td> -->                                                       
                                                    </tr>
                                                    <tr *ngIf="reportOrderData.orderDataModels.discount">
                                                        <!-- <td colspan="1" style="border-bottom: none;border-top: none;"></td> -->
                                                        <td colspan="2" style="text-align: left;border-bottom: none;border-top: none;font-family: 'Montserrat';padding: 8px !important;">Discount ( - )</td>
                                                        <td style="text-align: right;border-bottom: none;border-top: none;padding: 8px !important;">{{reportOrderData.orderDataModels.discount | number: '1.2-2'}}</td>
                                                    </tr>
                                                    <tr >
                                                        <!-- <td colspan="1" style="border-bottom: none;border-top: none;"></td> -->
                                                        <td colspan="2" style="padding: 8px !important;font-weight: bold;text-align: left;border-bottom: 1px solid #000;border-top: 1px solid #000;font-family: 'Montserrat';">Grand Total</td>
                                                        <td style="padding: 8px !important;font-weight: bold;text-align: right;border-bottom: 1px solid #000;border-top: 1px solid #000;">{{reportOrderData.orderDataModels.grandTotal | number: '1.2-2'}}</td>
                                                    </tr>                                                 
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>                                    
                                </div>
                                <div class="comments" *ngIf="reportOrderData.orderDataModels.comments">                                    
                                    <div style="font-weight: bold;font-size: 12px;color: #000;font-family: 'Montserrat';">Comments:</div>
                                    <div class="content-font">{{reportOrderData.orderDataModels.comments}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer" style="width: 300px;padding: 0px 0px 25px 0px;margin: 0 auto;padding-top: 25px !important;display: flex; flex-direction: column;">
                            <button type="button" class="btn btn-success" (click)="captureScreen()" id="report" style="width: 100%;margin-bottom: 15px;margin-right: 0px;margin-left: 0px;">Print</button>
                            <button type="button" class="btn btn-danger" (click)="reportClose()" style="width: 100%;margin-bottom: 15px;margin-right: 0px;margin-left: 0px;">Close</button>                                                                 
                            <button type="button" id="closeModalReport" data-dismiss="modal" style="display: none">Close</button>
                        </div>
                      </div>
                    </div>
                </div>

                <!--Start:: New order alert model-->
                <div class="modal fade" id="alertModel" data-backdrop="static" data-keyboard="false" role="dialog" aria-hidden="false">
                    <div class="modal-dialog" style="width: 738px; margin-top: 60px !important;height: calc(100vh - 120px);overflow-y: auto;">
                      <div class="modal-content">  
                        <!-- Modal Header -->
                        <div class="bg-color modal-header text-left ">
                            <button type="button" class="close" (click)="alertClose()" aria-hidden="true">
                                    <i class="pg-close fs-14"></i>
                                </button>
                            <div class="bold">New Order Arrived</div>
                        </div>
                  
                        <!-- Modal body -->
                        <div class="modal-body model-bg">
                            <h1 class="model-content"> {{totalNewOrder}} New Order Arrived!</h1>
                            {{remainingTime}}
                        </div>

                        <div class="modal-footer hidden" style="width: 300px;padding: 0px 0px 25px 0px;margin: 0 auto;padding-top: 25px !important;display: flex; flex-direction: column;">                                                               
                            <button type="button" id="closeModalAlert" data-dismiss="modal" style="display: none">Close</button>
                        </div>
                      </div>
                    </div>
                </div>


                <!--End:: New order alert model-->
            </div>
</div>
</div>
<!-- Start Body -->
</div>

</div>
