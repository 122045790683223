import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ],
})
export class HeaderComponent implements OnInit {

  loginValue: any;
  userName: any;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.loginValue = JSON.parse(localStorage.getItem("isLogin"));
    this.userName = this.loginValue.employeeName;
  }

  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    location.reload();
    this.router.navigate(["login"]);
      
  }

}
