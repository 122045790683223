<!-- START PAGE-CONTAINER -->
<div class="page-container ">
    <app-header></app-header>
    <div class="page-content-wrapper ">
      <div class="content ">
        <div class="jumbotron" data-pages="parallax">
          <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
            <div class="inner">
              <ul class="breadcrumb">
                <li>
                  <p>Security</p>
                </li>
                <li><a class="active" style="cursor: pointer;">User</a>
                </li>
              </ul>
              <!-- END BREADCRUMB -->
            </div>
          </div>
        </div>
        <div class="container-fluid container-fixed-lg">

<div class="panel panel-transparent">
    <div class="panel-heading">
        <div class="panel-title">
            User
        </div>

        <div class="pull-right m-t-15">
            <div id="show-modal" class="btn btn-success" (click)="redirectionFunction()">
                <i class="fa fa-plus pr1"></i>
                <a>Add New</a>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
<div>
<input type="text" class="form-control" placeholder="Search" [(ngModel)]="searchText" style="width: 200px; float: right;" />
</div>
    <div class="panel-body">
        <table class="table table-striped cust_table_unSch">
            <thead>
                <tr>

                    <th>Sr.No</th>
                    <th>Employee Name</th>
                    <th>User Name</th>
                    <th>Is Active</th>
                    <th style="text-align:center;">Edit </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of userList| paginate:{itemsPerPage:10,currentPage:p} | filter:searchText;let i=index">
                    <td>{{i+1}}</td>
                    <td>{{row.employeeName}}</td>
                    <td>{{row.userName}}</td>
                    <td>
                        <div class="checkbox_inline">
                            <div class="checkbox check-primary checkbox-circle">
                                <input type="checkbox" id="checkbox{{i+1}}" [(ngModel)]="row.isActive"
                                    [value]="row.isActive" (change)="checkFunction(row.id,row.isActive)">
                                <label for="checkbox{{i+1}}"></label>
                            </div>
                        </div>
                    </td>
                    <td align="center">
                        <button (click)="populateForm(row)" type="button" class="btn btn-success btn-xs">
                            <i class="fa fa-pencil"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="5">
                        <div style="text-align: right;">
                            <pagination-controls (pageChange)="p=$event"></pagination-controls>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>


    </div>
</div>

</div>
</div>
</div>
</div>

<div class="overlay hide" data-pages="search">
</div>

