<div class="header ">
    <!-- START MOBILE CONTROLS -->
    <div class="container-fluid relative">
      <!-- LEFT SIDE -->
      <div class="pull-left full-height visible-sm visible-xs">
        <!-- START ACTION BAR -->
        <div class="header-inner">
          <a href="#" class="btn-link toggle-sidebar visible-sm-inline-block visible-xs-inline-block padding-5" data-toggle="sidebar">
            <span class="icon-set menu-hambuger"></span>
          </a>
        </div>
        <!-- END ACTION BAR -->
      </div>
      <div class="pull-center hidden-md hidden-lg">
        <div class="header-inner">
          <div class="brand inline">
            <img src="assets/built_in/assets/img/logo6.png" alt="logo" data-src="assets/built_in/assets/img/logo6.png" data-src-retina="assets/built_in/assets/img/logo6.png" width="35" height="40">
          </div>
        </div>
      </div>
      <!-- RIGHT SIDE -->
      
    </div>
    <!-- END MOBILE CONTROLS -->
    <div class=" pull-left sm-table hidden-xs hidden-sm">
      <div class="header-inner">
        <div class="brand inline">
          <img src="assets/built_in/assets/img/logo6.png" alt="logo" data-src="assets/built_in/assets/img/logo6.png" data-src-retina="assets/built_in/assets/img/logo6.png" width="48" height="22">
        </div>
     </div>
    </div>

    <!-- Logo Section -->
    <!-- Right Login Menu -->
    <div class=" pull-right">
      <!-- START User Info-->
      <div class="visible-lg visible-md m-t-10">
        <div class="pull-left p-r-10 p-t-10 fs-16 font-heading">
          <span class="semi-bold">Devour user </span> <span class="text-master" style="color:brown !important">{{userName}}</span>
        </div>
        <div class="dropdown pull-right">
          <button class="profile-dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="thumbnail-wrapper d32 circular inline m-t-5">
            <img src="assets/built_in/assets/img/profiles/avatar.jpg" alt="" data-src="assets/built_in/assets/img/profiles/avatar.jpg" data-src-retina="assets/built_in/assets/img/profiles/avatar.jpg" width="32" height="32">
        </span>
          </button>
          <ul class="dropdown-menu profile-dropdown" role="menu">
            <li class="bg-master-lighter">
              <a class="clearfix" (click)="logoutFunction()">
                <span class="pull-left">Logout</span>
                <span class="pull-right"><i class="pg-power"></i></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- END User Info-->
    </div>
    <!-- Right Login Menu -->
</div>
