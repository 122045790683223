import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { KitchenService } from 'src/app/Services/kitchen.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-kitchen-list',
  templateUrl: './kitchen-list.component.html',
  styles: []
})
export class KitchenListComponent implements OnInit {
  userId:number;
  kitchenList: any=[];
  searchText;

  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  constructor(private router: Router
    ,private service: KitchenService) { 
      this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
    }

  ngOnInit(): void {
    let value = JSON.parse(localStorage.getItem("isLogin"));
    if(value != null){
      
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId=value.employeeID;    
        this.loadKitchenList();
      }
      else {
        this.logoutFunction();
      }
    }
    else{
      this.router.navigate(["login"]);
    }

    
  }

  loadKitchenList(){
    this.service.getKitchens().subscribe((data)=>{
      debugger
      this.kitchenList = data;
    })
  }
  updateForm(kitchen: any){
    debugger
    this.service.formData = Object.assign({}, kitchen);
    this.router.navigate(["Kitchen"]);
  }
  redirectionFunction()
  {
    this.router.navigate(["Kitchen"]);
  }
  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);
      
  }
}
