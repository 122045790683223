import { Component, OnInit, Input, Output, ElementRef, ViewChild, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { CustomerService } from 'src/app/Services/customer.service';
import { OrderService } from 'src/app/Services/order.service';
import { KitchenService } from 'src/app/Services/kitchen.service';
import { CommonService } from 'src/app/Services/common.service';
import { DatePipe, formatDate } from '@angular/common';
import { DatepickerAdapterService } from 'src/app/Services/datepicker-adapter.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
//auto refresh
import { Subscription, Observable, timer } from 'rxjs';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styles: [],
  providers: [DatePipe]
})
export class OrderListComponent implements OnInit {


  
  loginValue: any;
  counter: any;
  userId: number=0;
  public customerList: any = [];
  public statusList: any = [];
  public kitchenList: any = [];
  public orderList: any = [];
  public orderDetailsList: any = [];
  public tree_data: any = [];
  public result: any;
  //auto refresh
  private subscription: Subscription;
  @Input() ElapsTime: number = 2;
  @Output() TimerExpired: EventEmitter<any> = new EventEmitter<any>();
  @Input() SearchDate: moment.Moment = moment();

  barCodeWiseBindingData: any;
  barCodeSubmit: any;
  editItem: any;
  searchText;
  p: number = 1;
  perPage: any = 10;

  background = 'transparent';
  font = 'Montserrat';
  // barCodeData: any;

  fDate: number;
  tDate: number;
  disableBranch: boolean;
  showOrderHiddenField: boolean;
  statusCombo: boolean;

  searchValue: any;
  customerDropList: any = [];
  pageIndex: any = 1;
  pageSize: any = 10;
  newCustomer: any = 0;
  totalOrder: any = 0;
  totalNewOrder: any = 0;

  reportOrderData: any;
  countReceipt: any = [];
  countSticker: any = [];
  public branchList: any = [];  
  
  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  public vatLevel: any = "";
  

  everySecond: Observable<number> = timer(0, 1000);
  searchEndDate: moment.Moment;
  remainingTime: number;
  minutes: number;
  seconds: number;


  constructor(private router: Router
    , private customerService: CustomerService
    , private kitchenService: KitchenService
    , private commonService: CommonService
    , public service: OrderService
    , private datePipe: DatePipe
    , private datepicker: DatepickerAdapterService
    , private ngbCalendar: NgbCalendar
    , private toastr: ToastrService
    , private ref: ChangeDetectorRef
    ) {
      
      this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');   
      this.searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");   
  }

  ngOnInit() {

    

    //debugger
    //this.branchList = [{'BranchID': 0, 'Name': 'All'},{'BranchID': 1, 'Name': 'Baridhara'}, {'BranchID': 2, 'Name': 'Dhanmondi'}, {'BranchID': 3, 'Name': 'Uttara'}];
    this.branchList = [{'BranchID': 0, 'Name': 'All'},{'BranchID': 1, 'Name': 'Banani'}, {'BranchID': 2, 'Name': 'Dhanmondi'}, {'BranchID': 3, 'Name': 'Uttara'}];
    
    

    this.counter = JSON.parse(localStorage.getItem("pageCounter"));
    this.showOrderHiddenField = JSON.parse(localStorage.getItem("orderHidenField"));
    if(!this.showOrderHiddenField){
      this.statusCombo = true;
    }

      if (this.counter == 0) {
        location.reload();
        localStorage.removeItem("pageCounter");
      }
    this.resetParameters();    
    this.loginValue = JSON.parse(localStorage.getItem("isLogin"));
    if (this.loginValue != null) {
      this.expirationTime = formatDate(this.loginValue.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId = this.loginValue.employeeID;
        
        this.service.parameters.branchID = JSON.parse(localStorage.getItem("branchID")); 
        this.disableBranch = false;       
        if(this.loginValue.groupName == 'Employee'){
          this.disableBranch = true;          
        }

        // document.getElementById('checkOutCardNO').focus();
        this.loadCustomers();
        this.loadKitchens();
        // this.loadSalesOrder(this.service.parameters.fromDate);
        this.searchSalesOrderInformation(this.service.parameters);
        this.loadStatus();

      }
      else {
        this.logoutFunction();
      }

    }
    else {
      this.router.navigate(["login"]);
    }    


    /* Auto refresh page */
    this.subscription = this.everySecond.subscribe((seconds) => {      
      var currentTime: moment.Moment = moment();
      this.remainingTime = this.searchEndDate.diff(currentTime)
      this.remainingTime = this.remainingTime / 1000;
      
      if (this.remainingTime <= 0) {

      this.SearchDate = moment();
      this.searchEndDate = this.SearchDate.add(this.ElapsTime, "minutes");      
      this.TimerExpired.emit(); 

      this.totalNewOrder = 0;
      this.searchOrder();


      }

    })


  }

  modalClose(): void {
    document.getElementById('close-modal').click();
  }

  modalClose2(): void {
    document.getElementById('close-modal2').click();
  }
  modalClose3(): void {
    document.getElementById('close-modal3').click();
  }
  reportClose(): void {
    document.getElementById('closeModalReport').click();
  }

  alertClose(): void {
    document.getElementById('closeModalAlert').click();
  }
  onPageChange(number: number) {    
    this.p = number;
  }
  viewOrder(data) {    
    this.service.formData = data;
    this.getOrderDetailsInformation(this.service.formData.orderID);
  }
  viewBarCode(data) {
    this.service.formData = data;
  }
  updateForm(data) {    
    let orderDate = this.datepicker.fromModel(data.orderDate);

    this.editItem = {
      addressID: data.addressID,
      orderID: data.orderID,
      orderDate: orderDate,
      orderType: data.orderType,
      kitchenID: data.kitchenID,
      customerID: data.customerID,
      customerName: data.customerName,
      customerMobile: data.customerMobile,
      flatNo: data.flatNo,
      houseNo: data.houseNo,
      apartmentNo: data.apartmentNo,
      roadNo: data.roadNo,
      area: data.area,
      coupon: data.coupon,
      orderTime: data.orderTime,
      deliveryTime: data.deliveryTime,
      comments: data.comments,
      orderStatus: data.orderStatus,
      preparingStartBarCode: data.preparingStartBarCode,
      foodReadyBarCode: data.foodReadyBarCode,
      dispatchedBarCode: data.dispatchedBarCode,
      searchBarCode: data.searchBarCode,
      subTotal: data.subTotal,
      vat: data.vat,
      discount: data.discount,
      deliveryCharge: data.deliveryCharge,
      incomeTax: data.incomeTax,
      serviceCharge: data.serviceCharge,
      grandTotal: data.grandTotal,
      orderFlavourDataModels: []
    }
    this.service.formData = Object.assign({}, this.editItem);
    // this.getOrderDetailsInformation(this.service.formData.orderID)
    this.router.navigate(["Order"]);
  }

  barCodeWiseOrderUpdate() {    
    this.updateOrderInformation(this.service.formData);
  }
  updateOrderInformation(form: NgForm) {
    this.service.updateOrderInformation().subscribe(res => {      
      this.toastr.success("Update Successfully");
      this.resetForm();
      this.searchSalesOrderInformation(this.service.parameters);
      this.modalClose2();
    },
      err => {
        this.toastr.error(err);
      }
    )
  }
  

  loadCustomers() {
    this.customerService.getCustomers().subscribe((data) => {
      this.customerList = data;
    })
  }

  loadKitchens() {
    this.kitchenService.getKitchens().subscribe((data) => {
      this.kitchenList = data;
    })
  }
  
  loadStatus(){
    this.statusList = this.commonService.getStatusList();
  }

  loadSalesOrder(data: any) { 
    debugger    
    if(data != ""){      
      this.service.parameters.fromDate = this.datepicker.toModel(data);
      this.service.loadSalesOrderInformation().subscribe((data) => {        
      this.orderList = data;
      this.service.parameters.fromDate = this.datepicker.fromModel(this.service.parameters.fromDate);
      for (let order of this.orderList) {
        let status = +order.orderStatus;
        order.orderStatus = status;        
        order.orderTypeName = this.commonService.convertOrderTypeText(order.orderType);
        order.orderStatusName = this.commonService.convertOrderStatusText(status);
        if (status != 0) {
          order.disabledBtn = true;
        }       
      }
      debugger
    })
    }
    
  }

  getOrderDetailsInformation(id: any) {
    this.service.formData.orderDetailsDataModels = [];
    this.service.loadSalesOrderDetailsInformation().subscribe((data) => {
      this.orderDetailsList = data;      
      for (let details of this.orderDetailsList) {
        this.service.formData.orderDetailsDataModels.push({
          orderDtlsID: details.orderDtlsID,
          orderID: details.orderID,
          itemID: details.itemID,
          quantity: details.quantity,
          unitRate: details.unitRate,
          itemNameNflavour: details.itemNameNflavour
        })
      }
    })
  }

  orderWiseAllOrderInformation(row: any) {
    
    this.vatLevel = "VAT (5%)"; 
    
    this.service.parameters.orderID = row.orderID;
    this.service.orderWiseAllOrderInformation().subscribe((data) => {     
      
      this.countReceipt = [];
      this.countSticker = [];
      this.reportOrderData = data;      

      if(row.orderDate < '2022-06-18'){
        this.vatLevel = "VAT (10%)";        
      }
      
      // 15% tax Calculation
      //this.reportOrderData.orderDataModels.incomeTax = ((this.reportOrderData.orderDataModels.subTotal * 15)/100);
      // this.reportOrderData.orderDataModels.serviceCharge = ((this.reportOrderData.orderDataModels.subTotal * 5)/100);
     
      this.reportOrderData.orderDataModels.incomeTax = this.reportOrderData.orderDataModels.incomeTax;
      
      // this.reportOrderData.orderDataModels.serviceCharge = this.reportOrderData.orderDataModels.serviceCharge;
      if (this.reportOrderData.orderDataModels.orderType == 8) {
        this.reportOrderData.orderDataModels.serviceCharge = (this.reportOrderData.orderDataModels.subTotal * 7.5) / 100; 
    } 
     
      this.reportOrderData.orderDataModels.total = (this.reportOrderData.orderDataModels.subTotal + this.reportOrderData.orderDataModels.incomeTax +this.reportOrderData.orderDataModels.serviceCharge +  this.reportOrderData.orderDataModels.deliveryCharge);
      if (this.reportOrderData.orderDataModels.orderType == 8) {
        this.reportOrderData.orderDataModels.serviceCharge = (this.reportOrderData.orderDataModels.subTotal * 7.5) / 100; 
    } else {
      this.reportOrderData.orderDataModels.serviceCharge = (this.reportOrderData.orderDataModels.grandTotal * 0); 
  }
      this.reportOrderData.orderDataModels.grandTotal = (this.reportOrderData.orderDataModels.subTotal + this.reportOrderData.orderDataModels.incomeTax + this.reportOrderData.orderDataModels.serviceCharge + this.reportOrderData.orderDataModels.deliveryCharge) - this.reportOrderData.orderDataModels.discount;

      this.reportOrderData.orderDataModels.orderTypeName = this.commonService.convertOrderTypeText(this.reportOrderData.orderDataModels.orderType);
      this.reportOrderData.orderDataModels.kitchenName = this.commonService.convertKitchenText(this.reportOrderData.orderDataModels.kitchenID);

      this.countReceipt.push(this.reportOrderData.orderDataModels.preparingStartBarCode);
      this.countReceipt.push(this.reportOrderData.orderDataModels.foodReadyBarCode);  
      this.countReceipt.push(this.reportOrderData.orderDataModels.dispatchedBarCode);
      //this.countReceipt.push(this.reportOrderData.orderDataModels.searchBarCode);

        this.reportOrderData.orderDataModels.customerAddress = "";
        if(this.reportOrderData.orderDataModels.flatNo){
          this.reportOrderData.orderDataModels.customerAddress += "Flat " + "#" + this.reportOrderData.orderDataModels.flatNo;
        }
        if(this.reportOrderData.orderDataModels.houseNo){
          this.reportOrderData.orderDataModels.customerAddress += ", " + "House " + "#" + this.reportOrderData.orderDataModels.houseNo;
        }       
        if(this.reportOrderData.orderDataModels.roadNo){
          this.reportOrderData.orderDataModels.customerAddress +=  ", " +"Road " + "#" +  this.reportOrderData.orderDataModels.roadNo;
        }
        if(this.reportOrderData.orderDataModels.block){
          this.reportOrderData.orderDataModels.customerAddress += ", " + "Block " + " - " + this.reportOrderData.orderDataModels.block;
        } 
        if(this.reportOrderData.orderDataModels.area){
          this.reportOrderData.orderDataModels.customerAddress += ", " + "Area" + " - " + this.reportOrderData.orderDataModels.area;
        }        

        //this.reportOrderData.orderDetailsDataModels
        for(var i =0; i <this.reportOrderData.orderDetailsDataModels.length; i++){
          if(this.reportOrderData.orderDetailsDataModels[i].itemNameNflavour == null){
            this.reportOrderData.orderDetailsDataModels[i].itemNameNflavour = this.reportOrderData.orderDetailsDataModels[i].itemName;
          } 
        }
        //this.orderDetailsList = this.reportOrderData.orderDetailsDataModels;


      //var unqueList = this.getUniqueList(this.reportOrderData.orderDetailsDataModels);


      for (let details of this.reportOrderData.orderDetailsDataModels) {
        let i = 0;
        i = (+details.quantity);
        
        for (let index = 0; index < i; index++) {
          this.countSticker.push({
            id: i,
            itemName: details.itemName,
            itemNameNflavour: details.itemNameNflavour,
            orderID: 'ORD' + this.reportOrderData.orderDataModels.orderID,
            itemType: details.itemType

          });
        }
      }
      
      $("#myModalReport").modal('show');
    })
  }

  captureScreen() {
    const printContent = document.getElementById("report_viewer");    
    const WindowPrt = window.open('', '_blank', 'left=0,top=0,toolbar=0,scrollbars=0,height=100%,width=auto,status=0');
    WindowPrt.document.open();
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.print();
  }
  redirectionFunction() {
    this.service.formData = null;
    this.router.navigate(["Order"]);
  }

  searchOrder() {    
    if (this.service.parameters.fromDate != null && this.service.parameters.toDate != null) {
      let fDate = Date.parse(this.datepicker.toModel(this.service.parameters.fromDate));
      let tDate = Date.parse(this.datepicker.toModel(this.service.parameters.toDate));
      if (fDate > tDate) {
        this.toastr.error("from date can't be greater then to date !!");
        return;
      }      
    }

    this.searchSalesOrderInformation(this.service.parameters);
  }

  searchBarCodeWiseOrder() {
    this.barCodeWiseOrderInformation(this.service.parameters);
  }

  barCodeWiseOrderInformation(data: any) {
    this.service.barCodeWiseOrderInformation().subscribe((data) => {
      this.orderDetailsList = [];
      this.barCodeWiseBindingData = data;
      if (this.barCodeWiseBindingData.orderDataModels != null) {
        $("#myModal2").modal('show');
        this.orderDetailsList = this.barCodeWiseBindingData.orderDetailsDataModels;
        this.service.formData = {
          orderID: this.barCodeWiseBindingData.orderDataModels.orderID,
          orderDate: this.barCodeWiseBindingData.orderDataModels.orderDate,
          orderType: this.barCodeWiseBindingData.orderDataModels.orderType,
          kitchenID: this.barCodeWiseBindingData.orderDataModels.kitchenID,
          customerID: this.barCodeWiseBindingData.orderDataModels.customerID,
          customerName: this.barCodeWiseBindingData.orderDataModels.customerName,
          customerMobile: this.barCodeWiseBindingData.orderDataModels.customerMobile,
          flatNo: this.barCodeWiseBindingData.orderDataModels.flatNo,
          houseNo: this.barCodeWiseBindingData.orderDataModels.houseNo,
          apartmentNo: this.barCodeWiseBindingData.orderDataModels.apartmentNo,
          roadNo: this.barCodeWiseBindingData.orderDataModels.roadNo,
          area: this.barCodeWiseBindingData.orderDataModels.area,
          coupon: this.barCodeWiseBindingData.orderDataModels.coupon,
          orderTime: this.barCodeWiseBindingData.orderDataModels.orderTime,
          orderStatus: this.barCodeWiseBindingData.orderDataModels.orderStatus,
          preparingStartBarCode: this.barCodeWiseBindingData.orderDataModels.preparingStartBarCode,
          foodReadyBarCode: this.barCodeWiseBindingData.orderDataModels.foodReadyBarCode,
          dispatchedBarCode: this.barCodeWiseBindingData.orderDataModels.dispatchedBarCode,
          searchBarCode: this.barCodeWiseBindingData.orderDataModels.searchBarCode,
          subTotal: this.barCodeWiseBindingData.orderDataModels.subTotal,
          vat: this.barCodeWiseBindingData.orderDataModels.vat,
          discount: this.barCodeWiseBindingData.orderDataModels.discount,
          deliveryCharge: this.barCodeWiseBindingData.orderDataModels.deliveryCharge,
          incomeTax: this.barCodeWiseBindingData.orderDataModels.incomeTax,
          serviceCharge: this.barCodeWiseBindingData.orderDataModels.serviceCharge,
          grandTotal: this.barCodeWiseBindingData.orderDataModels.grandTotal
        }
        this.service.formData.orderDetailsDataModels = [];
        for (let details of this.orderDetailsList) {
          this.service.formData.orderDetailsDataModels.push({
            orderDtlsID: details.orderDtlsID,
            orderID: details.orderID,
            itemID: details.itemID,
            quantity: details.quantity,
            unitRate: details.unitRate,
            itemNameNflavour: details.itemNameNflavour
          })
        }
        let status = +this.service.formData.orderStatus;
        if (status == 0) {
          this.barCodeSubmit = "Preparing Food";
          this.service.formData.orderStatus = 1;
        }
        if (status == 1) {
          this.barCodeSubmit = "Food Is Ready";
          this.service.formData.orderStatus = 2;
        }
        if (status == 2) {
          this.barCodeSubmit = "Dispatched";
          this.service.formData.orderStatus = 3;
        }
        if (status == 3) {
          this.barCodeSubmit = "Delivered";
          this.service.formData.orderStatus = 4;
        }
      }

      this.resetParameters();
    })
  }

  statusWiseOrderUpdate(data: any) {   
      this.service.formData = {
      orderID: data.orderID,
      orderDate: data.orderDate,
      orderType: data.orderType,
      kitchenID: data.kitchenID,
      customerID: data.customerID,
      customerName: data.customerName,
      customerMobile: data.customerMobile,
      flatNo: data.flatNo,
      houseNo: data.houseNo,
      apartmentNo: data.apartmentNo,
      roadNo: data.roadNo,
      area: data.area,
      coupon: data.coupon,
      subTotal: data.subTotal,
      vat: data.vat,
      discount: data.discount,
      deliveryCharge: data.deliveryCharge,
      incomeTax: data.incomeTax,
      serviceCharge: data.serviceCharge,
      grandTotal: data.grandTotal,
      orderTime: data.orderTime,
      orderStatus: data.orderStatus,
      preparingStartBarCode: data.preparingStartBarCode,
      foodReadyBarCode: data.foodReadyBarCode,
      dispatchedBarCode: data.dispatchedBarCode,
      searchBarCode: data.searchBarCode
    }
    
    if(this.service.formData.orderID){
      this.service.formData.orderDetailsDataModels = [];
      this.service.loadSalesOrderDetailsInformation().subscribe((data) => {
        this.orderDetailsList = data;       
        for (let details of this.orderDetailsList) {
          this.service.formData.orderDetailsDataModels.push({
            orderDtlsID: details.orderDtlsID,
            orderID: details.orderID,
            itemID: details.itemID,
            quantity: details.quantity,
            unitRate: details.unitRate,
            itemNameNflavour: details.itemNameNflavour
          })
        }
        if(this.service.formData.orderDetailsDataModels.length > 0){
          this.updateOrderInformation(this.service.formData);
        }
      })      
    } 
  }

  updateOrderStatus(row: any) {     
    this.service.formData = {
      orderID: row.orderID,
      orderType: row.orderType,
      orderStatus: row.orderStatus,
      customerName: row.customerName,
      kitchenID: row.kitchenID,
      mobile: row.customerMobile,
      requiredDeliveryTime: row.requiredDeliveryTime,
      grandTotal: row.grandTotal
    }

    if(this.service.formData.orderID > 0){      
      this.updateOrderStatus2(this.service.formData);       
    } 
  }

  updateOrderStatus2(form: NgForm) { 

    
    if(this.service.formData.orderStatus === 3 && this.service.formData.orderType === 1){
      if(confirm("Do you want to sent SMS")){
        this.sentSMS();
        }  
    } 

    this.service.updateOrderStatus().subscribe((data) => {           
      
      if(data == "Success"){
        this.toastr.success("Update Successfully ");
        this.resetForm();         
        this.searchSalesOrderInformation(this.service.parameters);
        //this.modalClose2();
        if(this.service.formData.orderStatus === 3 && this.service.formData.orderType === 1){
          

         if(confirm("Do you want to send SMS")){
          this.sentSMS();
          }          
        }        
      }     
    },
      err => {       

        this.resetForm();         
        this.searchSalesOrderInformation(this.service.parameters);
        
        //this.modalClose2();        
      }
    )
  }


  sentSMS(){   

        var kitchenID = this.service.formData.kitchenID;
        //var kitchenID = 4;
        var masking = "";
        var brand = "";
        if (kitchenID == 2) {
            masking = "HUNGRY ROOS";
            brand = "Hungry Rooster.";
        }
        else if (kitchenID == 3) {
            masking = "PIMENTOS";
            brand = "Pimentos.";
        }
        else if (kitchenID == 4) {
            masking = "SHUKRAAN";
            brand = "Shukraan.";
        }
        else if (kitchenID == 5) {
          masking = "JHOTPOT";
          brand = "Jhotpot.";
      }
      else if (kitchenID == 6) {
        masking = "CLEAN CRAVINGS";
        brand = "Clean Cravings.";
    }

        var messagePart1 = ", your order has been dispatched.";
        var messagePart2 = " You will receive your order within " + this.service.formData.requiredDeliveryTime + " minutes." + "\n"+ "\n";    
        var messagePart3 = "Your total bill is BDT" + this.service.formData.grandTotal + "/-" + "\n"+ "\n";
        var messagePart4 = "Thank you" + '\n' + brand;

        var message = 'Dear ' + this.service.formData.customerName + messagePart1 + messagePart2 + messagePart3 + messagePart4;      
        

        //var message = 'Dear Kamrul Islam' + messagePart1 + messagePart2 + " 30 minutes." + messagePart3;
        
        $.ajax({
            //url: 'http://sms.naxhost.com/smsapi',
            url: 'https://smpp.ajuratech.com:7790/sendtext?',
            data: { apikey: 'bdbf8d55380f630f', secretkey: '986f81c6', callerID: masking, toUser: this.service.formData.mobile, messageContent: message },
            //data: { apikey: 'bdbf8d55380f630f', secretkey: '986f81c6', callerID: masking, toUser: '01735217660', messageContent: message },
            cache: false,
            type: "GET",
            success: function (response) {
                //alert('Success - ' + JSON.stringify(response))
            },
            error: function (req, status, err) {
                //alert('Error - ' + JSON.stringify(err) + JSON.stringify(req) + JSON.stringify(status))
            }
        }); 
      }



  
  cancelOrder(data: any){
    let isConfirmed = confirm("Are you Sure!");
    if(isConfirmed){
      data.orderStatus = 5;
      this.statusWiseOrderUpdate(data);
    }
  }
  statusWiseOrderInfo(data: any){

      this.statusWiseOrderUpdate(data.OrderID);    
  }
  searchSalesOrderInformation(data: any) {

    this.orderList = [];
    this.service.parameters.fromDate = this.datepicker.toModel(data.fromDate);
    this.service.parameters.toDate = this.datepicker.toModel(data.toDate);  
    this.service.searchSalesOrderInformation().subscribe((data) => { 
           
      this.orderList = data;
      this.service.parameters.fromDate = this.datepicker.fromModel(this.service.parameters.fromDate);
      this.service.parameters.toDate = this.datepicker.fromModel(this.service.parameters.toDate);
      
      this.newCustomer = 0;
      this.totalOrder = 0;

      var hasNewORder = false;

      for (let order of this.orderList) {
        let status = +order.orderStatus;
        order.orderStatus = status;        
        order.orderTypeName = this.commonService.convertOrderTypeText(order.orderType);
        order.orderStatusName = this.commonService.convertOrderStatusText(status);
        if (status != 0) {
          order.disabledBtn = true;
        }
        order.branchName = 'Banani';
        if(order.branchID == 2){
          order.branchName = 'Dhanmondi';
        }
        if(order.branchID == 3){
          order.branchName = 'Uttara';
        }        
        if(order.isNewCustomer == 1){
          this.newCustomer += 1;
        }
        this.totalOrder += 1;

        if(status == 0){
          hasNewORder = true;
          this.totalNewOrder += 1;
        }
      }
      debugger
      if(hasNewORder){
        this.playAlert();
      }
      // this.resetParameters();
    })
  }
  onDateSelection(date: NgbDate) {    
    date;
  }

  resetParameters() {
    this.service.parameters = {
      orderID: "",
      fromDate: this.ngbCalendar.getToday(),
      toDate: this.ngbCalendar.getToday(),
      orderType: "",
      kitchenID: "",
      barCode: "",
      customerID: "",
      orderStatus: "",
      customerName: "",
      searchValue: "",
      pageSize: 0,
      pageIndex: 0
    }
  }

  resetForm(form?: NgForm) {

    if (this.service.formData == null) {
      this.service.formData = {
        orderID: 0,
        orderDate: this.ngbCalendar.getToday(),
        orderType: 0,
        kitchenID: null,
        customerID: 0,
        customerName: "",
        customerMobile: "",
        flatNo: "",
        houseNo: "",
        apartmentNo: "",
        roadNo: "",
        area: "",
        coupon: "",
        orderTime: "",
        orderStatus: "",
        preparingStartBarCode: "",
        foodReadyBarCode: "",
        dispatchedBarCode: "",
        searchBarCode: "",
        subTotal: 0,
        vat: 0,
        discount: 0,
        deliveryCharge: 0,
        incomeTax: 0,
        serviceCharge: 0,
        grandTotal: 0,
        orderDetailsDataModels: []
      }
    }
  }

        //Search Dropdown
        searchCustomerDropDown(){          
          if (this.searchValue.length > 2) {
            this.service.parameters.searchValue = this.searchValue;
            this.service.parameters.pageSize = this.pageSize;
            this.service.parameters.pageIndex = this.pageIndex;
            this.searchCustomerList(this.service.parameters);
          }else{
            this.customerDropList = [];
            this.service.parameters.customerName = "";
            this.service.parameters.customerID = "";
            if(!this.searchValue){
              this.searchSalesOrderInformation(this.service.parameters);
            }
          }
          
        }
  
        searchCustomerList(customer: any){[
          this.service.searchCustomerList().subscribe((data) =>{
            this.customerDropList = data;
          })
        ]}  
  
        searchBoxClicked = function ($event) {
            $event.stopPropagation();
        }
        // Set value to search box
        setValue = function (index, $event) {          
            this.searchValue = this.customerDropList[index].name;
            this.service.parameters.customerName = this.customerDropList[index].name;
            this.service.parameters.customerID = this.customerDropList[index].customerID;
            this.customerDropList = [];
            $event.stopPropagation();
            this.searchSalesOrderInformation(this.service.parameters);
            
        }

  commonRedirectionFunction() {
    this.router.navigate(["OrderList"]);
  }
  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);
      
  }

  getUniqueList(value: any, args?: any): any {

    // Remove the duplicate elements
    let uniqueArray = value.filter(function (el, index, array) { 
      return array.indexOf (el) == index;
    });
    return uniqueArray;
  }


 orderNoWiseSearch(event: any){
   
   if(event.keyCode === 13){
    this.searchSalesOrderInformation(this.service.parameters);
   }  
 }

 playAlert(){
  let audio = new Audio();
  audio.src = '../assets/audio/order-tone.wav';
  audio.load();
  audio.play();
  audio.play();
  audio.play();
  audio.play();
  $("#alertModel").modal('show');
 }
 

}
