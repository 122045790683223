import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { KitchenService } from 'src/app/Services/kitchen.service';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-kitchen',
  templateUrl: './kitchen.component.html',
  styles: []
})
export class KitchenComponent implements OnInit {
  userId:number;

  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  constructor(private toastr: ToastrService
    ,public service: KitchenService
    , private router: Router) {
      this.resetForm();
      this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
     }

     
  ngOnInit() {
    let value = JSON.parse(localStorage.getItem("isLogin"));
    if(value != null){
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId=value.employeeID;
      }
      else {
        this.logoutFunction();
      }  
    }
    else{
      this.router.navigate(["login"]);
    }
  }
  onSubmit(form: NgForm){
    debugger
      if(this.service.formData.kitchenID == 0){        
        this.insertKichenInfo(form);
      }else{
        debugger
        this.updateKichenInfo(form);
      }
  }
  resetForm(form?: NgForm) {
    debugger
    
     if (this.service.formData == null) {
      this.service.formData = {
        kitchenID: 0,
        name:'',
        address:''
       }
     }
   }
   clearData(){
     this.resetForm();
   }

   insertKichenInfo(form: NgForm){
    this.service.insertKichenInfo().subscribe( res=>{
        this.resetForm(form);
        this.toastr.success('Save successfully');
        this.router.navigate(["KitchenList"]);
    },
    err=>
    {
        this.toastr.error(err);
    }
    )
   }
   
   updateKichenInfo(form: NgForm){
    this.service.updateKitchenInfo().subscribe( res=>{
        this.resetForm(form);
        this.toastr.success('Update successfully');
        this.router.navigate(["KitchenList"]);
    },
    err=>
    {
        this.toastr.error(err);
    }
    )
   }
   commonRedirectionFunction() {
    this.router.navigate(["KitchenList"]);
  }
  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);
      
  }
}
