import { Injectable } from '@angular/core';
import { GlobalVariable } from '../Classes/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  readonly rootURL = GlobalVariable.ONLINE_CORE_URL;
  pageList :any=[];
  formData: any;
  constructor(private http: HttpClient) { }

  getAllPages() {
    this.pageList=[]
    let response = this.http.get(this.rootURL + 'page/GetAllPages')
    response.toPromise()
    .then(res => this.pageList = res);
    return response;
  }

  GetAllModule() {
    let response = this.http.get(this.rootURL + 'page/GetAllModule')
    return response;
  }

  addPage() {
    let response = this.http.post(this.rootURL + 'page/addpage', this.formData)
    return response;
  }
}
