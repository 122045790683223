import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ChangepasswordService } from 'src/app/Services/changepassword.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-password-change-by-admin',
  templateUrl: './password-change-by-admin.component.html',
  styleUrls: ['./password-change-by-admin.component.css']
})
export class PasswordChangeByAdminComponent implements OnInit {

  userId: number;
  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  constructor(public service:ChangepasswordService
    ,private toastr: ToastrService
    ,private router: Router) {
      this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
     }

  ngOnInit() {
    let value = JSON.parse(localStorage.getItem("isLogin"));
    if (value != null) {
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId = value.employeeID;
        this.resetForm();
      }
      else {
        this.logoutFunction();
      }

    }
    else {
      this.router.navigate(["login"]);
    }
    
  }
  resetForm(form?: NgForm) {
    if (this.service.formData == null) {
      this.service.formData = {
        userName: '',
        password: '',
        updatedBy:1
         
       }
    }
   }

   clearData(){
    //debugger;
    this.resetForm();
  }

  onSubmit(form:NgForm){
    //debugger;
    if(this.service.formData.userName !='' || this.service.formData.userName !=null)
      {
        this.updateUserPasswordByAdmin(form);
      }
  }

  updateUserPasswordByAdmin(form:NgForm){
    //debugger
    this.service.updateUserPasswordByAdmin().subscribe(
      res => {
       // debugger;
        this.resetForm(form);
        this.toastr.success('updated');
        
      },
      err => {
        //debugger;
        console.log(err);
        this.toastr.error(err.message);
      }
    )
  }
  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);
      
  }

}
