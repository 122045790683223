
<div class="page-container ">
    <!-- START HEADER -->
    <app-header></app-header>
    <div class="page-content-wrapper ">
      <div class="content ">
        <!-- END JUMBOTRON -->
        <!-- START CONTAINER FLUID -->
        <div class="container-fluid container-fixed-lg">
          <!-- BEGIN PlACE PAGE CONTENT HERE -->


<form autocomplete="off" id="form-personal">
    <div class="panel panel-transparent">
        <div class="panel-heading">
            <div class="panel-title">
                User
            </div>
        </div>
        <div class="panel-body">
            <ag-grid-angular style="width: 100%; height: 550px" class="ag-theme-balham" #grid [gridOptions]="gridOptions" [columnDefs]="columnDefs"
                [rowData]="rowData">
            </ag-grid-angular>

        </div>
    </div>


</form>

</div>
</div>

</div>
</div>
