import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/Services/users.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  constructor(public service:UsersService,private router:Router,private toastr:ToastrService) {
    this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
   }
  userList:any=[];
  isactive:any=1;
  searchText;
  p:number=1;
  userId: number;
  

  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  ngOnInit() {    
    let value = JSON.parse(localStorage.getItem("isLogin"));
    if (value != null) {
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId = value.employeeID;        
        this.getAllUserInformation();
      }
      else {
        this.logoutFunction();
      }

    }
    else {
      this.router.navigate(["login"]);
    }
    
  }
  
  getAllUserInformation()
  {
    this.userList = [];
    this.service.getAllUserInformation().subscribe((data) => {      
      this.userList = data;
    });
  }

  checkFunction(iD,isActive){
    this.service.UpdateUserStatus(iD,isActive).subscribe((data) => {
      this.toastr.success('Successfully change into '+ isActive);
    });
  }

  populateForm(userList: any) {
    debugger;
    this.service.updateData = Object.assign({}, userList);
    console.log(this.service.updateData);
    this.router.navigate(["UserEdit"]);
  }

  redirectionFunction() {
    this.router.navigate(["User"]);
  }

  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);
      
  }

}
