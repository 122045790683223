<!-- <app-navbar></app-navbar> -->

<div class="page-container">
    
    <app-header></app-header>
    
    <div class="page-content-wrapper ">
        <div class="content ">
          <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <!-- START BREADCRUMB -->
                <ul class="breadcrumb">
                  <li>
                    <p>Devour</p>
                  </li>
                  <li><a (click)="commonRedirectionFunction()" style="cursor: pointer;">Item</a>
                  </li>
                  <li><a class="active">Item Add</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
            <div class="container-fluid container-fixed-lg custom-container">
<div class="panel panel-transparent">
    <div class="panel-heading">
        <div class="panel-title">
            Items
        </div>
    </div>

    <div class="panel-body">
        <div class="row xl-mlr10 clearfix">
            <form id="form-personal" #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 xl-mb30">
                    <input type="hidden" [value]="service.formData.itemID" />

                    <div class="form-group form-group-default form-group-default-select2 required">
                        <label class="">Kitchen</label>
                        <ng-select bindLabel="name"
                            placeholder="Select Kitchen"
                            appendTo="body"                            
                            [searchable]="false" 
                            [clearable]="true"
                            [(ngModel)]="service.formData.kitchenID"
                            name="KitchenID"
                            #KitchenID="ngModel">                            
                            <ng-option [value]="item.kitchenID" *ngFor="let item of kitchenList">
                                {{item.name}}
                            </ng-option>
                        </ng-select>
                          
                    </div>
                    
                      
                    <div class="form-group form-group-default required">
                        <label>Items Name</label>
                        <input type="text" class="form-control" required name="Name" #Name="ngModel" [(ngModel)]="service.formData.name">
                    </div>
                    <div class="form-group form-group-default">
                        <label>Items Type</label>
                        <input type="text" class="form-control" name="ItemType" #ItemType="ngModel" [(ngModel)]="service.formData.itemType">
                    </div>
                    
                    <div class="form-group form-group-default">
                        <label>Unit Rate</label>
                        <input type="text" class="form-control" name="UnitRate" #UnitRate="ngModel" (keypress)=_keyPress($event)
                        [(ngModel)]="service.formData.unitRate">
                    </div>

                    <div class="filter_col">
                        <div class="form-group form-group-default">
                            <label class="">Have Flavour</label>
                            <ng-select bindLabel="name"
                                placeholder="Select Flavour"
                                appendTo="body"                            
                                [searchable]="false" 
                                [clearable]="true"
                                [(ngModel)]="service.formData.haveFlavour"
                                name="HaveFlavour"
                                #HaveFlavour="ngModel">                            
                                <ng-option [value]="item.id" *ngFor="let item of haveFlavourList">
                                    {{item.name}}
                                </ng-option>
                            </ng-select>
                              
                        </div>
                    </div>

                    <div class="filter_col">
                        <div class="form-group form-group-default">
                            <label class="">Is Active</label>
                            <ng-select bindLabel="name"
                                placeholder="Select Status"
                                appendTo="body"                            
                                [searchable]="false" 
                                [clearable]="true"
                                [(ngModel)]="service.formData.isActive"
                                name="IsActive"
                                #IsActive="ngModel">                            
                                <ng-option [value]="item.id" *ngFor="let item of isActiveList">
                                    {{item.name}}
                                </ng-option>
                            </ng-select>
                              
                        </div>
                    </div>
                   
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group">
                                <div class="form-inner12">
                                    <label for="fname" class="left-col control-label"></label>
                                    <div class="right-col mt-org1" style="text-align:right">
                                        <button class="btn btn-success custom-btn1" type="submit" [disabled]="form.invalid">Submit</button>
                                        <button type="button" [disabled]="form.invalid" class="btn btn-default custom-btn1" name="Clear" (click)="clearData()"><i class="pg-close"></i> Clear</button>
                                    </div>
                                    <div class="clr"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            


            </form>
            
        
        
           
        </div>
        
    </div>
</div>
</div>
</div>
<!-- Start Body -->
</div>

</div>


