export const GlobalVariable = Object.freeze({
    //normal project run  

    //  LOCAL_CORE_URL: 'http://localhost:1133/api/',
    //  ONLINE_CORE_URL: 'http://localhost:1133/api/',

     //Online
    //  LOCAL_CORE_URL: "http://103.91.228.25:82/api/",
    //  ONLINE_CORE_URL: "http://103.91.228.25:82/api/",


     //Temporary Hosting server billing
     //LOCAL_CORE_URL: 'http://devourapilive.rmahmud.com/api/',
     //ONLINE_CORE_URL: 'http://devourapilive.rmahmud.com/api/',
     
    // Main Hosting server
    LOCAL_CORE_URL: 'https://omsapi.devourworld.com/api/',
    ONLINE_CORE_URL: 'https://omsapi.devourworld.com/api/', 

      //VAT Hosting server
    //  LOCAL_CORE_URL: 'http://order-api.devourworld.com/api/',
    //  ONLINE_CORE_URL: 'http://order-api.devourworld.com/api/',   
    
    //Temporary Hosting server vat
    // LOCAL_CORE_URL: 'https://apidevourvat.rmahmud.com/api/',
    // ONLINE_CORE_URL: 'https://apidevourvat.rmahmud.com/api/', 

    //ng build --prod
    // ng build --source-map


})