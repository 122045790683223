import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/Services/users.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  userGrouplist:any=[];
  userId: any;

  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  constructor(public service:UsersService,private toastr: ToastrService,private router:Router ) { 
    this.resetForm();
    this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
  }

  ngOnInit() {
    
    let value = JSON.parse(localStorage.getItem("isLogin"));
    if (value != null) {
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId = value.employeeID; 
        console.log(this.userId);      
        this.getAllUserGroup();
      }
      else {
        this.logoutFunction();
      }

    }
    else {
      this.router.navigate(["login"]);
    }
  }
 

  getAllUserGroup()
  {
    this.userGrouplist=[];
    this.service.getAllUserGroup().subscribe((data) => {
      this.userGrouplist = data;
    });
  }

  clearData(){
    this.resetForm();
  }

  resetForm(form?: NgForm) {
    if(this.service.formData == null){
      this.service.formData = {
        id: 0,
        userFullName:'',
        userName:'',
        address :'',
        email :'',
        phoneNo:'',
        password:'',
        confirmPassword:'',
        userGroupId:0,
        createdBy:this.userId
       };
    }
     

    if (this.service.insesrtData == null) {
      this.service.insesrtData={
        id: 0,
        userFullName:'',
        userName:'',
        address :'',
        email :'',
        phoneNo:'',
        userPassword:'',
        userGroupId:0,
        createdBy:this.userId
       };
    }
     
   }

   onSubmit(form:NgForm){
    debugger;
    if(this.service.formData.id==0)
      {
        if(this.service.formData.password==this.service.formData.confirmPassword){
          
          this.insertUser(form);
        }
        else{
          this.toastr.success('PassWord dose not match');
          console.log(this.service.formData);
        }
      }
      else{
        console.log(this.service.formData);
      }
  }

  insertUser(form:NgForm)
  {
    debugger
    this.service.insesrtData.id=this.service.formData.id;
    this.service.insesrtData.userFullName=this.service.formData.userFullName;
    this.service.insesrtData.userName=this.service.formData.userName;
    this.service.insesrtData.address=this.service.formData.address;
    this.service.insesrtData.email=this.service.formData.email;
    this.service.insesrtData.phoneNo=this.service.formData.phoneNo;
    this.service.insesrtData.userGroupId=this.service.formData.userGroupId;
    this.service.insesrtData.userPassword=this.service.formData.password;
    this.service.insesrtData.createdBy=this.service.formData.createdBy;

    if (this.service.insesrtData != null)
    {
      this.service.insertUser().subscribe(
        () => {
         // debugger;
          this.resetForm(form);
          this.toastr.success('Done');
          this.router.navigate(["user"]);
        },
        err => {
          
          this.toastr.error(err)
          
        }
      )
    }
  }
  redirectionFunction(requesLink:any){
    this.router.navigate([requesLink]);
  }
  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);
      
  }
  }
