import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KitchenComponent } from './UserInterface/Setup/kitchen/kitchen.component';
import { AppComponent } from './app.component';
import { ItemsComponent } from './UserInterface/Setup/items/items.component';
import { ItemListComponent } from './UserInterface/Setup/item-list/item-list.component';
import { KitchenListComponent } from './UserInterface/Setup/kitchen-list/kitchen-list.component';
import { OrderComponent } from './UserInterface/Order/order/order.component';
import { OrderListComponent } from './UserInterface/Order/order-list/order-list.component';
import { IngredientListComponent } from './UserInterface/Ingredient/ingredient-list/ingredient-list.component';
import { CustomerListComponent } from './UserInterface/Order/customer-list/customer-list.component';
import { TotalIngredientListComponent } from './UserInterface/Ingredient/total-ingredient-list/total-ingredient-list.component';
import { LoginComponent } from './UserInterface/login/login.component';
import { UserListComponent } from './UserInterface/Security/Users/user-list/user-list.component';
import { UserComponent } from './UserInterface/Security/Users/user/user.component';
import { UserEditComponent } from './UserInterface/Security/Users/user-edit/user-edit.component';
import { ChangePasswordComponent } from './UserInterface/Security/ChangePassword/change-password/change-password.component';
import { PasswordChangeByAdminComponent } from './UserInterface/Security/ChangePassword/password-change-by-admin/password-change-by-admin.component';
import { PageComponent } from './UserInterface/Security/ChangePassword/Pages/page/page.component';
import { PageListComponent } from './UserInterface/Security/ChangePassword/Pages/page-list/page-list.component';
import { UsergroupComponent } from './UserInterface/Security/UserGroup/usergroup/usergroup.component';
import { UsergroupEditComponent } from './UserInterface/Security/UserGroup/usergroup-edit/usergroup-edit.component';
import { UsergroupListComponent } from './UserInterface/Security/UserGroup/usergroup-list/usergroup-list.component';
import { CustomerComponent } from './UserInterface/Order/customer/customer.component';
import { SalesOrderReportComponent } from './UserInterface/Reports/sales-order-report/sales-order-report.component';


const routes: Routes = [
  {path: "",pathMatch:'full', component: OrderListComponent},
  {path: "login", component: LoginComponent},
  {path: "Kitchen", component: KitchenComponent},
  {path: "KitchenList", component: KitchenListComponent},
  {path: "Item", component: ItemsComponent},
  {path: "ItemList", component: ItemListComponent},
  {path: "Order", component: OrderComponent},
  {path: "OrderList", component: OrderListComponent},
  {path: "Ingredient", component: IngredientListComponent},
  {path: "CustomerList", component: CustomerListComponent},
  {path: "TotalIngredientList", component: TotalIngredientListComponent},
  {path:'Customer',component:CustomerComponent},
  //Reports
  {path:'SalesReport',component:SalesOrderReportComponent},
  //Security
  { path:'UserList',component:UserListComponent},
  { path:'User',component:UserComponent},
  { path:'UserEdit',component:UserEditComponent},
  { path:'ChangePassword',component:ChangePasswordComponent},
  { path:'PasswordChangeByAdmin',component:PasswordChangeByAdminComponent},
  { path:'Page',component:PageComponent},
  { path:'PageList',component:PageListComponent},
  { path:'UserGroup',component:UsergroupComponent},
  { path:'UserGroupList',component:UsergroupListComponent},
  { path:'UserGroupEdit',component:UsergroupEditComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
 export const RoutingComponents = [
  // AppComponent,
  KitchenComponent,
  KitchenListComponent,
  ItemsComponent,
  ItemListComponent,
  OrderComponent,
  OrderListComponent,
  IngredientListComponent,
  CustomerListComponent,
  CustomerComponent,
  TotalIngredientListComponent,
  LoginComponent
]
