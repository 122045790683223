import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { NgSelect2Module } from 'ng-select2';
import { ToastrModule } from 'ngx-toastr';
// import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxBarcode6Module } from 'ngx-barcode6';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {NgxPrintModule} from 'ngx-print';
// import {AngularTreeGridModule} from 'angular-tree-grid';
import { AgGridModule } from 'ag-grid-angular';
import { NgrxFormsModule } from 'ngrx-forms';
//nice select 
import { NiceSelectModule } from "ng-nice-select";

import { StoreModule } from '@ngrx/store';
import { reducer } from './reducer';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NZ_I18N, en_US } from 'ng-zorro-antd';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KitchenComponent } from './UserInterface/Setup/kitchen/kitchen.component';
import { ItemsComponent } from './UserInterface/Setup/items/items.component';
import { ItemListComponent } from './UserInterface/Setup/item-list/item-list.component';
import { KitchenListComponent } from './UserInterface/Setup/kitchen-list/kitchen-list.component';
import { OrderComponent } from './UserInterface/Order/order/order.component';
import { OrderListComponent } from './UserInterface/Order/order-list/order-list.component';
import { IngredientListComponent } from './UserInterface/Ingredient/ingredient-list/ingredient-list.component';
import { CustomerListComponent } from './UserInterface/Order/customer-list/customer-list.component';
import { TotalIngredientListComponent } from './UserInterface/Ingredient/total-ingredient-list/total-ingredient-list.component';
import { LoginComponent } from './UserInterface/login/login.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderComponent } from './header/header.component';
import { JwtInterceptor } from './Classes/jwt.interceptor';
import { UserListComponent } from './UserInterface/Security/Users/user-list/user-list.component';
import { UserEditComponent } from './UserInterface/Security/Users/user-edit/user-edit.component';
import { UserComponent } from './UserInterface/Security/Users/user/user.component';
import { ChangePasswordComponent } from './UserInterface/Security/ChangePassword/change-password/change-password.component';
import { PageListComponent } from './UserInterface/Security/ChangePassword/Pages/page-list/page-list.component';
import { PageComponent } from './UserInterface/Security/ChangePassword/Pages/page/page.component';
import { PasswordChangeByAdminComponent } from './UserInterface/Security/ChangePassword/password-change-by-admin/password-change-by-admin.component';
import { UsergroupListComponent } from './UserInterface/Security/UserGroup/usergroup-list/usergroup-list.component';
import { UsergroupEditComponent } from './UserInterface/Security/UserGroup/usergroup-edit/usergroup-edit.component';
import { UsergroupComponent } from './UserInterface/Security/UserGroup/usergroup/usergroup.component';
import { ConfirmequalvalidatorDirective } from './Services/confirmequalvalidator.directive';
import { EditorComponent } from './editor/editor/editor.component';
import { CustomerComponent } from './UserInterface/Order/customer/customer.component';
import { SalesOrderReportComponent } from './UserInterface/Reports/sales-order-report/sales-order-report.component';
//import { VatComponent } from './UserInterface/Reports/sales-order-report/vat-report.component';

@NgModule({
  declarations: [
    AppComponent,
    KitchenComponent,
    ItemsComponent,
    ItemListComponent,
    KitchenListComponent,
    OrderComponent,
    OrderListComponent,
    IngredientListComponent,
    CustomerListComponent,
    TotalIngredientListComponent,
    LoginComponent,
    NavbarComponent,
    HeaderComponent,
    UserListComponent,
    UserEditComponent,
    UserComponent,
    ChangePasswordComponent,
    PageListComponent,
    PageComponent,
    PasswordChangeByAdminComponent,
    UsergroupListComponent,
    UsergroupEditComponent,
    UsergroupComponent,
    ConfirmequalvalidatorDirective,
    EditorComponent,
    CustomerComponent,
    SalesOrderReportComponent
    //VatComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      // timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    NgSelectModule,
    FormsModule,
    NgbModule,
    // NgxBarcodeModule,
    NgxBarcode6Module,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgxPrintModule,
    AppRoutingModule,
    NgrxFormsModule,
    StoreModule.forRoot({data: reducer}),
    NgZorroAntdModule,
    AgGridModule,
    NiceSelectModule
    // AngularTreeGridModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {provide: NZ_I18N, useValue: en_US}
  ],
  
  entryComponents: [EditorComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
