
<div class="page-container">
    
    <app-header></app-header>
    
    <div class="page-content-wrapper ">
        <div class="content ">
          <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <!-- START BREADCRUMB -->
                <ul class="breadcrumb">
                  <li>
                    <p>Devour</p>
                  </li>
                  <li><a class="active" style="cursor: pointer;">Ingredient</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
            <div class="container-fluid container-fixed-lg custom-container">


<div class="panel panel-transparent">
    <div class="panel-heading">
        <div class="panel-title">
            Ingredient List
        </div>
        <!-- <div class="pull-right m-t-15">
            <div id="show-modal" class="btn btn-success" (click)="redirectionFunction()">
                <i class="fa fa-plus pr1"></i>
                <span>Add New</span>
            </div>
        </div>
        <div class="clearfix"></div> -->
    </div>

    <div class="panel-body">
        <div class="row">
          
            <div class="col-lg-4">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-action div-info">
                      Ciao Bella
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Tomato sauce
                      <!-- <span class="badge badge-primary badge-pill">14</span> -->
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Mozzarella
                      <!-- <span class="badge badge-primary badge-pill">2</span> -->
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Basil
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Parmesan
                    </li>
                  </ul>
            </div>
            <div class="col-lg-4">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-action div-info">
                      Mamma Mia
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Tometo Sauce
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Mozzarella
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Parmesan
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Pepperoni
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Oregano
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Chilli Flakes
                    </li>
                  </ul>
            </div>
            <div class="col-lg-4">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-action div-info">
                      Jerry's Dream
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Tomato Sauce
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Mozzarella
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Parmesan
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Cheddar
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Pomir
                    </li>
                  </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-action div-info">
                      I Love Mexico
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Tomato sauce
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Cheddar
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Mozzarella
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Beef Mince
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Mezican mix
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Peppers
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Coriander
                    </li>
                  </ul>
            </div>
            <div class="col-lg-4">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-action div-info">
                      Ei Murghiyaaa 
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Chickien Breast
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      jalapeno
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Corn
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Tomato Sauce
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Caramelised Onions
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Mozzarella
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Parmesan
                    </li>
                  </ul>
            </div>
            <div class="col-lg-4">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-action div-info">
                        Mario's Magic Mushrooms
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      White Sauce
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Oyster Mushrooms
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Shitake
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Button Mushrooms
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Black Olives
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Parmesan
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Mozzarella
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Oregano
                    </li>
                  </ul>
            </div>
        </div>
        
        <div class="row">
            <div class="col-lg-4">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-action div-info">
                      Creaammy Lemon
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      White sauce
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Parmesan
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Mozzarella
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Shimp
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Cearlic
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Lemon
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Mayo
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Chilli Flakes
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Peppers
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Tomatoes
                    </li>
                  </ul>
            </div>
            <div class="col-lg-4">
                <ul class="list-group">
                    <li class="list-group-item list-group-item-action div-info">
                      Moc & Cheese Pizza
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Mozzarella
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Cheddar
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      White sauce
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Macaroni
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Bacon
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Cheese Slice
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Breadcrumbs
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Oregano
                    </li>
                  </ul>
            </div>
        </div>
    </div>

</div>
</div>
</div>
<!-- Start Body -->
</div>

</div>