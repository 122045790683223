import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-total-ingredient-list',
  templateUrl: './total-ingredient-list.component.html',
  styles: []
})
export class TotalIngredientListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
