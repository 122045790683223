import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/Classes/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SalesReportService {

  salesOrderReportList: any = [];
  sealsOrderReportBodyModel: any;
  readonly rootUrl = GlobalVariable.ONLINE_CORE_URL;
  constructor(private http: HttpClient) { }
  getSealsOrderReport(){    
    return this.http.post(this.rootUrl + 'sales/SalesOrderReport/', this.sealsOrderReportBodyModel);     
  }

  getVATReport(){    
    return this.http.post('61.247.185.66:85/#!/SalesReportVAT', 0);     
  }

  

}
