import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariable } from '../Classes/global';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  formData : any;
  flavourData : any;
  parameters: any;
  barCodeWiseBindingData: any;
  itemsList: any = [];  
  orderList: any = []; 
  orderDetailsList: any = [];
  customerDropList: any = [];
  orderInformationList: any = [];
  addressList: any = [];
  areaList: any = [];
  flavourList: any = [];
  result: any;
  deliveryTime: any;

  smsParameters : any = {};

  readonly rootUrl = GlobalVariable.ONLINE_CORE_URL;

  constructor(private http: HttpClient) { }

  getKitchenItemDetails(){
    let response = this.http.get(this.rootUrl + 'item/GetKitchenItemInformation/'+ this.formData.kitchenID);
    response.toPromise().then(res=> this.itemsList = res);
    return response;
  }

  loadSalesOrderDetailsInformation() {
    let response = this.http.get(this.rootUrl + 'order/LoadSalesOrderDetailsInformation/'+ this.formData.orderID);
    response.toPromise().then(res=> this.orderDetailsList = res);
    return response;
  }
  
 loadSalesOrderInformation(){
   debugger
    let response = this.http.get(this.rootUrl + 'order/LoadSalesOrderInformation/'+ this.parameters.fromDate);
    response.toPromise().then(res=> this.orderList = res);
    return response;
  }
  searchCustomerList(){
    let response = this.http.get(this.rootUrl + 'customer/SearchCustomerList/'+ this.parameters.searchValue + '/' + this.parameters.pageSize + '/' + this.parameters.pageIndex);
    response.toPromise().then(res=> this.customerDropList = res);
    return response;
  }
  searchSalesOrderInformation(){
    let response = this.http.post(this.rootUrl + 'order/SearchSalesOrderInformation/', this.parameters);
    response.toPromise().then(res=> this.orderInformationList = res);
    return response;
  }
  
  barCodeWiseOrderInformation(){
    let response = this.http.get(this.rootUrl + 'order/BarCodeWiseOrderInformation/'+ this.parameters.barCode);
    response.toPromise().then(res=> this.barCodeWiseBindingData = res);
    return response;
  }  
  orderWiseAllOrderInformation(){
    let response = this.http.get(this.rootUrl + 'order/OrderWiseAllOrderInformation/'+ this.parameters.orderID);
    response.toPromise().then(res=> this.barCodeWiseBindingData = res);
    return response;
  }
  insertOrderInformation(){
    debugger
    var areaName = JSON.parse(localStorage.getItem("areaName"));
    this.formData.area = areaName;
    //this.formData.deliveryTime = this.deliveryTime;
    debugger 
    return this.http.post(this.rootUrl + 'order/AddNewOrderInformation', this.formData);
  }
  updateOrderInformation(){  
    debugger 
    var areaName = JSON.parse(localStorage.getItem("areaName"));
    this.formData.area = areaName; 
    return this.http.post(this.rootUrl + 'order/UpdateOrderInformation/'+ this.formData.orderID, this.formData);
    
  }

  updateOrderStatus(){    
    let response = this.http.get(this.rootUrl + 'order/UpdateOrderStatus/'+ this.formData.orderID + '/' + this.formData.orderStatus);
    response.toPromise().then(res=> this.result = res);        
    return response;
  }

  getCustomerAddress(){
    debugger
    let response = this.http.get(this.rootUrl + 'customer/GetCustomerAddress/' + this.formData.customerID)
    response.toPromise().then(data=> this.addressList = data);
    return response;
  }

  getArea(keyword, branchID){    
    debugger
    //var branchID = JSON.parse(localStorage.getItem("branchID"));
    keyword = "all";
    let response = this.http.get(this.rootUrl + 'customer/GetArea/' + keyword + '/'+ branchID);
    response.toPromise().then(data=> this.areaList = data);
    return response;
  }

  getArea2(keyword){    
    debugger
    let response = this.http.get(this.rootUrl + 'customer/GetExistingArea/' + keyword);
    response.toPromise().then(data=> this.areaList = data);
    return response;
  }

  getFlavours(){
    let response = this.http.get(this.rootUrl + 'flavour/GetFlavours/')
    response.toPromise().then(data=> this.flavourList = data);
    return response;
  }
}



