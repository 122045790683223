import { Injectable } from '@angular/core';
import { GlobalVariable } from '../Classes/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  formData: any;
  readonly rootURL = GlobalVariable.ONLINE_CORE_URL;
  constructor(private http: HttpClient) { }

  userlogin() {

    return this.http.post(this.rootURL + 'user/userlogin', this.formData)
  }
}
