import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PagesService } from 'src/app/Services/pages.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  moduleList:any=[];
  userId: number;

  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  constructor(public service:PagesService,private toastr: ToastrService,private router:Router) {
    this.resetForm();
    this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
   }

  ngOnInit() {
    let value = JSON.parse(localStorage.getItem("isLogin"));
    if (value != null) {
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId = value.employeeID;
        this.getAllModule();
      }
      else {
        this.logoutFunction();
      }

    }
    else {
      this.router.navigate(["login"]);
    }
    
  }

  clearData(){
    this.resetForm();
  }

  resetForm(form?: NgForm) {
    if (this.service.formData == null) {
      this.service.formData = {
        id: 0,
        name:'',
        uiPageName:'',
        moduleId:0,
        urlID:1
      };
    }
    
  }


getAllModule()
{
  this.moduleList=[];
  this.service.GetAllModule().subscribe((data) => {
    debugger
    this.moduleList = data;
  });
}

onSubmit(form:NgForm){
  debugger;
  if(this.service.formData.id==0)
    {
        this.addPage(form);
    }
    else{
      console.log(this.service.formData);
      this.router.navigate(["page"]);
    }
}

addPage(form:NgForm)
{
  debugger
  if (this.service.formData != null)
  {
    this.service.addPage().subscribe(
      () => {
       // debugger;
        this.resetForm(form);
        this.toastr.success('Done');
        this.router.navigate(["page"]);
      },
      err => {
        
        this.toastr.error(err)
        
      }
    )
  }
}
redirectionFunction(requesLink:any){
  this.router.navigate([requesLink]);
}
logoutFunction(){
  localStorage.removeItem("isLogin");
  localStorage.clear();
  this.router.navigate(["login"]);
    
}
}
