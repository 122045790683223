import { Injectable } from '@angular/core';
import { GlobalVariable } from '../Classes/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class KitchenService {
  formData: any;
  kitchenList: any = [];
  readonly rootURL = GlobalVariable.ONLINE_CORE_URL;
  constructor(private http: HttpClient) { }

  getKitchens(){
    let response = this.http.get(this.rootURL + 'kitchen/GetKitchens');
    response.toPromise().then(res=> this.kitchenList = res );
    return response;
  }

  insertKichenInfo() {
    return this.http.post(this.rootURL + 'kitchen/AddKitchen', this.formData)
  }
  updateKitchenInfo()
  {
    return this.http.put(this.rootURL + 'kitchen/UpdateKitchen/'+ this.formData.kitchenID, this.formData)
  }

  getCoupons(){    
    let response = this.http.get(this.rootURL + 'order/LoadCoupon'); 
    response.toPromise().then(res=> this.kitchenList = res );
    return response;
  }
}
