
<div class="page-container">
    
    <app-header></app-header>
    
    <div class="page-content-wrapper ">
        <div class="content ">
          <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <!-- START BREADCRUMB -->
                <ul class="breadcrumb">
                  <li>
                    <p>Devour</p>
                  </li>
                  <li><a (click)="commonRedirectionFunction()" style="cursor: pointer;">Customer</a>
                  </li>
                  <li><a class="active">Customer Create</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
            <div class="container-fluid container-fixed-lg custom-container">
                <div class="panel panel-transparent">
                    <div class="panel-heading">
                        <div class="panel-title">
                            Customer
                        </div>
                    </div>
                
                    <div class="panel-body">
                        <div class="row xl-mlr10 clearfix">
                            <form id="form-personal" #form="ngForm" (submit)="onSubmit(form)" autocomplete="off">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 xl-mb30">
                                    <input type="hidden" [value]="service.formData.customerID" />
                                    
                                    <div class="form-group form-group-default required">
                                        <label>Customer/Company Name</label>
                                        <input type="text" class="form-control" name="Name" [(ngModel)]="service.formData.name" #Name="ngModel" (change)="customerName()" required>
                                    </div>
                                    <div class="form-group form-group-default required">
                                        <label>Mobile</label>
                                        <input class="form-control" name="Mobile" [(ngModel)]="service.formData.mobile" (keypress)=_keyPress($event) #Mobile="ngModel" required>  
                                          
                                    </div>
                                    <div class="bold padding-10">Address:</div>  
                                                                     
                                    <div class="form-group form-group-default ">
                                        <label>Flat No</label>
                                        <input class="form-control" [(ngModel)]="service.formData.flatNo" name="FlatNo" #FlatNo="ngModel">
                                    </div>
                                    <div class="form-group form-group-default ">
                                        <label>House No</label>
                                        <input class="form-control" [(ngModel)]="service.formData.houseNo" name="HouseNo" #HouseNo="ngModel">
                                    </div>   
                                    <div class="form-group form-group-default ">
                                        <label>Apartment No</label>
                                        <input class="form-control" [(ngModel)]="service.formData.apartmentNo" name="ApartmentNo" #ApartmentNo="ngModel">
                                    </div>                                  
                                    <div class="form-group form-group-default ">
                                        <label>Road No</label>
                                        <input class="form-control" [(ngModel)]="service.formData.roadNo" name="RoadNo" #RoadNo="ngModel">
                                    </div>
                                    <div class="form-group">
                                        <label>Area</label>
                                        <textarea rows="4" class="form-control" [(ngModel)]="service.formData.area" name="Area" #Area="ngModel"></textarea>
                                    </div>
                                    
                                    <div class="form-group">
                                        <div class="form-inner12">
                                            <label for="fname" class="left-col control-label"></label>
                                            <div class="right-col mt-org1" style="text-align:right">
                                                <button class="btn btn-success custom-btn1" type="submit" [disabled]="form.invalid">Submit</button>
                                                <button type="button" [disabled]="form.invalid" class="btn btn-default custom-btn1" name="Clear" (click)="clearData()"><i class="pg-close"></i> Clear</button>
                                            </div>
                                            <div class="clr"></div>
                                        </div>
                                    </div>
                
                                </div>
                            <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                
                            </div> -->
                
                            </form>
                            
                        
                        
                           
                        </div>
                    </div>
                </div>
                

</div>
</div>
<!-- Start Body -->
</div>

</div>

