import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
// import * as $ from 'jquery';
import { KitchenService } from 'src/app/Services/kitchen.service';
import { ItemsService } from 'src/app/Services/items.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { CommonService } from 'src/app/Services/common.service';


@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styles: ['./items.component.cs']
})
export class ItemsComponent implements OnInit {
  userId:number;
  kitchenList: any = [];
  isActiveList: any = [];
  haveFlavourList: any = [];
  startValue;

  today = new Date();
  currentDateTime: any;
  expirationTime: any;
  constructor(private kitchenService: KitchenService
    ,public service: ItemsService
    ,private toastr: ToastrService
    , private commonService: CommonService
    ,private router: Router) {
      this.resetForm();
      this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
     }
  ngOnInit() {
    let value = JSON.parse(localStorage.getItem("isLogin"));
    this.isActiveList = this.commonService.getIsActiveList();
    this.haveFlavourList = this.commonService.getHaveFlavourList();
    if(value != null){
      
      this.expirationTime = formatDate(value.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId=value.employeeID;
        this.getKitchenList();
      }
      else {
        this.logoutFunction();
      }
    }
    else{

      this.router.navigate(["login"]);
    }

    
  }

  getKitchenList(){
    debugger
    this.kitchenService.getKitchens().subscribe((data)=>{
      debugger
      this.kitchenList = data;
    })
  }

  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  resetForm(form?: NgForm){
    if(this.service.formData == null){
      this.service.formData= {
        itemID: 0,
        kitchenID: 0,
        name: '',
        itemType: '',
        sizeId: 0
      } 
      
    }
  }

  onSubmit(form: NgForm){
    if (this.service.formData.itemID == 0) {
      debugger
      this.insertItemsInfo(form);
    }else{
      debugger
      this.updateItemsInfo(form);
    }
  }

  insertItemsInfo(form: NgForm){
    this.service.insertItemsInfo().subscribe(
      res=>{
        this.resetForm();
        this.toastr.success("Save Successfully");
        this.router.navigate(["ItemList"]);
      },
      err=>{
        this.toastr.error(err);
      }
    )
  }

  updateItemsInfo(form: NgForm){
    this.service.updateItemsInfo().subscribe(
      res=>{
        this.resetForm();
        this.toastr.success("Update Successfully");
        this.router.navigate(["ItemList"]);
      },
      err=>{
        this.toastr.error(err);
      }
    )
  }

  clearData(){
    this.service.formData= {
      itemID: 0,
      kitchenID: 0,
      name: '',
      itemType: '',
      unitRate: 0,
      sizeId: 0

    }
  }

  commonRedirectionFunction() {
    this.router.navigate(["ItemList"]);
  }
  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);
      
  }
}
