<style>
    .checkbox_vertical {
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .checkbox_vertical label {
            margin: 0;
        }
    .table>tfoot>tr>td {
        border: none;
        
    }
    .table>tbody>tr>td {
        vertical-align: middle;
    }
    .form-inner13 .left-col {
        width: 195px;
    }
    .form-inner13 .right-col {
        width: calc(100% - 205px);
    }
    .table tbody tr td span {
        color: #6d6666;
    }
    .redio_back_design{
        background-color: #fff !important;
    }
    .redio_back_design label.fade{
        opacity: 1 !important;
    }

    .comments{
        width: 215px;
        padding: 10px;
        margin-top: 10px;
        font-family: Montserrat;
        font-size: 12px;
        /* border: 1px solid !important; */
        height: 78px;
        float: left;
    }

    .content-font{
        font-family: Arial, sans-serif;
    }

</style>
<!-- <link href="assets/search-dropdown.css" rel="stylesheet" /> -->
<!-- <app-navbar></app-navbar> -->
<div class="page-container">
    
    <app-header></app-header>
    
    <div class="page-content-wrapper ">
        <div class="content ">
          <div class="jumbotron" data-pages="parallax">
            <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
              <div class="inner">
                <!-- START BREADCRUMB -->
                <ul class="breadcrumb">
                  <li>
                    <p>Devour</p>
                  </li>
                  <li><a (click)="commonRedirectionFunction()" style="cursor: pointer;">Order</a>
                  </li>
                  <li><a class="active">Order Create</a>
                  </li>
                </ul>
                <!-- END BREADCRUMB -->
              </div>
            </div>
          </div>
            <div class="container-fluid container-fixed-lg custom-container">
                <div class="panel panel-transparent">
                    <div class="panel-heading">
                        <div class="panel-title">
                            Order
                        </div>
                    </div>
                
                    <div class="panel-body">
                        <div class="row xl-mlr10 clearfix">
                            <form id="form-personal" #form="ngForm" (submit)="onSubmit(form)" autocomplete="off">
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 xl-mb30">
                                    <input type="hidden" [value]="service.formData.orderID" />
                                    <div class="form-group form-group-default input-group angular-date required">
                                        <label>Order Date</label>
                                        <input class="form-control" placeholder="dd/mm/yyyy"
                                        name="d2" #c2="ngModel" [(ngModel)]="service.formData.orderDate" ngbDatepicker #d2="ngbDatepicker">
                                        <span class="input-group-addon" (click)="d2.toggle()"><i class="fa fa-calendar"></i></span>
                                    </div>
                                    <div class="form-group form-group-default form-group-default-select2 required">
                                        <label class="">Branch</label>
                                        <ng-select bindLabel="name"
                                            placeholder="Select Branch"
                                            appendTo="body"                            
                                            [searchable]="false" 
                                            [clearable]="true"
                                            [(ngModel)]="service.formData.branchID"
                                            name="BranchID"
                                            #BranchID="ngModel"
                                            required      
                                            (change)="searchArea()"                                      
                                            [ngModelOptions]="{standalone: true}">                            
                                            <ng-option [value]="branch.BranchID" *ngFor="let branch of branchList">
                                                {{branch.Name}}
                                            </ng-option>
                                        </ng-select>                                          
                                    </div>
                                    <div class="form-group form-group-default form-group-default-select2 required">
                                        <label class="">Restaurant</label>
                                        <ng-select bindLabel="name"
                                            placeholder="Select Restaurant"
                                            appendTo="body"                            
                                            [searchable]="false" 
                                            [clearable]="true"
                                            [(ngModel)]="service.formData.kitchenID"
                                            name="KitchenID"
                                            #KitchenID="ngModel"
                                            required
                                            (change)="getKitchenItemDetails($event)"
                                            [ngModelOptions]="{standalone: true}">                            
                                            <ng-option [value]="item.kitchenID" *ngFor="let item of kitchenList">
                                                {{item.name}}
                                            </ng-option>
                                        </ng-select>                                          
                                    </div>
                                        
                                    <div class="form-group form-group-default full-width required redio_back_design">
                                        <label>Order Type</label>
                                        <div>
                                            <div class="radio radio-primary">
                                                <input type="radio" name="1st" id="radio1" [(ngModel)]="service.formData.orderType" [value]="1" name="OrderType" #OrderType="ngModel" required (change)="checkOrderType(service.formData.orderType)">
                                                <label for="radio1" style="font-family: Arial, sans-serif;text-transform: capitalize" >In-house Delivery</label>
                                            </div>
                                            <div class="radio radio-primary">
                                                <input type="radio" name="1st" id="radio8" [(ngModel)]="service.formData.orderType" [value]="8" name="OrderType" #OrderType="ngModel" required (change)="checkOrderType(service.formData.orderType)">
                                                <label for="radio8" style="font-family: Arial, sans-serif;text-transform: capitalize">Dine-In</label>
                                            </div>
                                            <div class="radio radio-primary">
                                                <input type="radio" name="1st" id="radio5" [(ngModel)]="service.formData.orderType" [value]="5" name="OrderType" #OrderType="ngModel" required (change)="checkOrderType(service.formData.orderType)">
                                                <label for="radio5" style="font-family: Arial, sans-serif;text-transform: capitalize">Pick-up</label>
                                            </div>
                                             <div class="radio radio-primary">
                                                <input type="radio" name="1st" id="radio3" [(ngModel)]="service.formData.orderType" [value]="3" name="OrderType" #OrderType="ngModel" required (change)="checkOrderType(service.formData.orderType)">
                                                <label for="radio3" style="font-family: Arial, sans-serif;text-transform: capitalize">Meal Plan </label>
                                            </div>
                                          
                                            <label style="height: 1.5px; width: 140px;background-color: #6d6666;"></label>
                                            <div class="radio radio-primary">
                                                <input type="radio" name="1st" id="radio2" [(ngModel)]="service.formData.orderType" [value]="2" name="OrderType" #OrderType="ngModel" required (change)="checkOrderType(service.formData.orderType)">
                                                <label for="radio2" style="font-family: Arial, sans-serif;text-transform: capitalize">Food Panda</label>
                                            </div>
                                            <div class="radio radio-primary">
                                                <input type="radio" name="1st" id="radio9" [(ngModel)]="service.formData.orderType" [value]="9" name="OrderType" #OrderType="ngModel" required (change)="checkOrderType(service.formData.orderType)">
                                                <label for="radio9" style="font-family: Arial, sans-serif;text-transform: capitalize">Pandago</label>
                                            </div>
                                            <!-- <div class="radio radio-primary">
                                                <input type="radio" name="1st" id="radio3" [(ngModel)]="service.formData.orderType" [value]="3" name="OrderType" #OrderType="ngModel" required (change)="checkOrderType(service.formData.orderType)">
                                                <label for="radio3" style="font-family: Arial, sans-serif;text-transform: capitalize">Shohoz Foods</label>
                                            </div> -->
                                            <div class="radio radio-primary">
                                                <input type="radio" name="1st" id="radio4" [(ngModel)]="service.formData.orderType" [value]="4" name="OrderType" #OrderType="ngModel" required (change)="checkOrderType(service.formData.orderType)">
                                                <label for="radio4" style="font-family: Arial, sans-serif;text-transform: capitalize">Pathao</label>
                                            </div>

                                             <div class="radio radio-primary">
                                                <input type="radio" name="1st" id="radio11" [(ngModel)]="service.formData.orderType" [value]="11" name="OrderType" #OrderType="ngModel" required (change)="checkOrderType(service.formData.orderType)">
                                                <label for="radio11" style="font-family: Arial, sans-serif;text-transform: capitalize">Foodi</label>
                                            </div>
                                            
                                            <!-- <div class="radio radio-primary">
                                                <input type="radio" name="1st" id="radio6" [(ngModel)]="service.formData.orderType" [value]="6" name="OrderType" #OrderType="ngModel" required (change)="checkOrderType(service.formData.orderType)">
                                                <label for="radio6" style="font-family: Arial, sans-serif;text-transform: capitalize">HungryNaki</label>
                                            </div> -->
                                            <div class="radio radio-primary">
                                                <input type="radio" name="1st" id="radio7" [(ngModel)]="service.formData.orderType" [value]="7" name="OrderType" #OrderType="ngModel" required (change)="checkOrderType(service.formData.orderType)">
                                                <label for="radio7" style="font-family: Arial, sans-serif;text-transform: capitalize">Free Delivery</label>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div class="form-group form-group-default" style="overflow: visible">
                                        <label>Customer/Company Search</label>
                                        <input type='text'
                                               (keyup)="searchCustomerDropDown()"
                                               (click)="searchBoxClicked($event)"
                                               [(ngModel)]="searchValue"
                                               name="SearchValue"
                                                #SearchValue="ngModel"
                                                minlength="3"
                                               placeholder='Search Name/Mobile' class="form-control cust_intput">
                                        <ul id='customerDropList' class="cust_dropdown" *ngIf="customerDropList">
                                            <li (click)='setValue(i, $event)'
                                                *ngFor="let customer of customerDropList; let i = index">
                                                {{ customer.name }}
                                            </li>
                                        </ul>
                                        <!-- <span class="input-group-addon" style="cursor: pointer;" data-toggle="modal" data-target="#myModal" (click)="customerModal()"><i class="fa fa-plus"></i></span> -->
                                    </div>

                                    <div class="form-group form-group-default" style="overflow: visible">
                                        <label>Customer Address</label>
                                        <ng-select bindLabel="fullAddress"
                                                        placeholder="Address"
                                                        appendTo="body"                            
                                                        [searchable]="true" 
                                                        [clearable]="true"
                                                        [(ngModel)]="service.formData.addressID"
                                                        name="FullAddress"
                                                        #FullAddress="ngModel"
                                                        (change)="setAddressDtls()"                                                         
                                                        [ngModelOptions]="{standalone: true}">  
                                                        <ng-option [value]="0">
                                                            Select Address
                                                        </ng-option>                          
                                                        <ng-option [value]="address.addressID" *ngFor="let address of customerAddressList;">
                                                            {{address.fullAddress}}
                                                        </ng-option>
                                                    </ng-select>                                        
                                    </div>

                                    <!-- <div class="form-group form-group-default required hidden">
                                        <label>Customer/Company Name</label>
                                        <input type="text" class="form-control" name="CustomerName" [(ngModel)]="service.formData.customerName" #CustomerName="ngModel" (change)="customerName()" required>
                                    </div>
                                    <div class="form-group form-group-default required hidden">
                                        <label>Full Address</label>
                                        <input type="text" class="form-control" name="FullAddress" [(ngModel)]="service.formData.fullAddress" #FullAddress="ngModel" required>
                                    </div> -->
                                    <div class="form-group form-group-default required">
                                        <label>Mobile</label>
                                        <input class="form-control" name="CustomerMobile" [(ngModel)]="service.formData.customerMobile" (keypress)=_keyPress($event) #CustomerMobile="ngModel" required>  
                                          
                                    </div>                   
                                    <div class="form-group form-group-default ">
                                        <label>Flat Number</label>
                                        <input class="form-control" [(ngModel)]="service.formData.flatNo" name="FlatNo" #FlatNo="ngModel">
                                    </div>
                                    <div class="form-group form-group-default ">
                                        <label>House Number</label>
                                        <input class="form-control" [(ngModel)]="service.formData.houseNo" name="HouseNo" #HouseNo="ngModel">
                                    </div>                                                                       
                                    <div class="form-group form-group-default ">
                                        <label>Road Number</label>
                                        <input class="form-control" [(ngModel)]="service.formData.roadNo" name="RoadNo" #RoadNo="ngModel">
                                    </div>
                                    <div class="form-group form-group-default ">
                                        <label>Block</label>
                                        <input class="form-control" [(ngModel)]="service.formData.block" name="Block" #Block="ngModel">
                                    </div>
                                    
                                    <!-- <div class="form-group form-group-default" style="overflow: visible">
                                        <label>Area</label>
                                        <ng-select bindLabel="areaName" 
                                        placeholder="Area"
                                        appendTo = "body"
                                        [searchable] = "true"
                                        [clearable] = "true"
                                        [(ngModel)] = "service.formData.Area"
                                        name ="Area"
                                        #Area = "ngModel"
                                       
                                        [ngModelOptions] = "standalone: true">
                                        <ng-option [value]="0">Select Area</ng-option>
                                        <ng-option [value]="area.Area" *ngFor = "let area of areaDropList; let i = index">
                                            {{area.areaName}}
                                        </ng-option>
                                       </ng-select> -->

                                       <div class="form-group form-group-default" style="overflow: visible">
                                            <label>Area</label>
                                            <!-- <input class="form-control hidden" [(ngModel)]="service.formData.area" name="Area" #Block="ngModel"> -->
                                            <ng-select bindLabel="Area"
                                                            placeholder="Area"
                                                            appendTo="body"                            
                                                            [searchable]="true" 
                                                            [clearable]="true"
                                                            [(ngModel)]="service.formData.areaID"
                                                            name="AreaName"
                                                            #AreaName="ngModel"
                                                            (change)="setValueArea()"                                                         
                                                            [ngModelOptions]="{standalone: true}">  
                                                            <ng-option [value]="0">
                                                                Select Area
                                                            </ng-option>                          
                                                            <ng-option [value]="area.areaID" *ngFor="let area of areaDropList; let i = index">
                                                                {{area.areaTime}}
                                                            </ng-option>
                                            </ng-select>
                                    </div>
                                    
                                    <div class="form-group form-group-default form-group-default-select2 required">
                                        <label class="">Discount</label>
                                        <ng-select bindLabel="name"
                                            placeholder="Select Discount"
                                            appendTo="body"                            
                                            [searchable]="false" 
                                            [clearable]="true"
                                            [(ngModel)]="service.formData.coupon"
                                            name="Coupon"
                                            #Coupon="ngModel"
                                            (change)="getCouponCode($event)"
                                            [ngModelOptions]="{standalone: true}">                            
                                            <ng-option [value]="item" *ngFor="let item of couponList">
                                                {{item.couponCode}}
                                            </ng-option>
                                        </ng-select>                                          
                                    </div>


                                    <div class="form-group form-group-default ">
                                        <label>Delivery Time</label>
                                        <input type="text" class="form-control" placeholder="Delivery Time" [(ngModel)]="service.formData.deliveryTime" name="deliveryTime" #DeliveryTime="ngModel">
                                    </div>
                                    <div class="form-group form-group-default">
                                        <label>Comments</label>
                                        <textarea class="form-control" placeholder="Comments" [(ngModel)]="service.formData.comments" name="comments" #Comments="ngModel"></textarea>
                                    </div>                                                                       
                
                                </div>
 <!--  startPopup  -->
   <!-- End The popup  -->
                                
                            
                                
                                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 xl-mb30">
                                    <table class="table table-striped table-bordered" style="margin-top: 0px;">
                                        <thead>
                                            <tr>
                                                <th style="width: 10%; text-align: center">Select</th>
                                                <th width="100px">Qty</th>
                                                <th>Flavour</th>
                                                <th>Items Name</th>
                                                <th style="text-align: right;width: 80px">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                             <tr *ngFor="let i of itemsList; let sl = index">
                                                <td style="text-align: center">
                                                    <div class="checkbox_vertical">
                                                        <div class="checkbox check-primary checkbox-circle">
                                                            <input type="checkbox" id="checkbox{{sl+1}}" [(ngModel)]="i.selected" [value]="i.selected" [ngModelOptions]="{standalone: true}" (change)="createItemsData(sl, i)">
                                                            <label for="checkbox{{sl+1}}"></label>
                                                        </div>
                                                    </div>                
                                                </td>
                                                <td style="text-align: center">
                                                    <ng-select bindLabel="qtyName"
                                                        placeholder="Qty"
                                                        appendTo="body"                            
                                                        [searchable]="true" 
                                                        [clearable]="true"
                                                        [(ngModel)]="i.quantity"
                                                        name="Quantity"
                                                        #Quantity="ngModel"
                                                        (change)="changeItemData(i)"
                                                        [disabled]="i.qtyDisabled"
                                                        [ngModelOptions]="{standalone: true}">                            
                                                        <ng-option [value]="item.id" *ngFor="let item of qtyList">
                                                            {{item.qtyName}}
                                                        </ng-option>
                                                    </ng-select>
                                                </td>
                                                <td style="text-align: center">
                                                    <button *ngIf="i.haveFlavour == 1"  type="button" class="btn btn-success" (click)="openFlavourPopup(i)" >Flavour</button>              
                                                </td>
                                                <td>{{i.name}} <span>{{i.itemType}}</span></td>
                                                <td style="text-align: right">{{i.total | number: '1.2-2'}}</td>
                                            </tr> 
                                        </tbody>
                                        <tfoot *ngIf="service.formData.subTotal">
                                            <tr>
                                                <td colspan="4" style="text-align: right;border-right: 1px solid #ddd;font-weight: 500;font-family: 'Montserrat';">Sub Total</td>
                                                <td style="text-align: right;font-weight: 500">{{service.formData.subTotal | number :'1.2-2'}}</td>
                                            </tr>
                                            <tr class="">
                                                <td colspan="4" style="text-align: right;border-top: 1px solid #ddd; border-right: 1px solid #ddd;font-weight: bold;font-family: 'Montserrat';">VAT (5%)</td>
                                                <td style="text-align: right;border-top: 1px solid #ddd;">{{service.formData.incomeTax | number :'1.2-2'}}</td>
                                            </tr>
                                            <tr  *ngIf="service.formData.serviceCharge">
                                                <td colspan="4" style="text-align: right;border-top: 1px solid #ddd; border-right: 1px solid #ddd;font-weight: bold;font-family: 'Montserrat';">Service Charge(7.5%)</td>
                                                <td style="text-align: right;border-top: 1px solid #ddd;">{{service.formData.serviceCharge | number :'1.2-2'}}</td>
                                            </tr>
                                            
                                            <tr  *ngIf="service.formData.deliveryCharge">
                                                <td colspan="4" style="text-align: right;border-right: 1px solid #ddd;font-weight: 500;font-family: 'Montserrat';">Delivery Charge</td>
                                                <td style="text-align: right;font-weight: 500">{{service.formData.deliveryCharge | number :'1.2-2'}}</td>
                                            </tr>
                                            <!-- <tr *ngIf="service.formData.orderType==8">
                                                <td colspan="4" style="text-align: right;border-top: 1px solid #ddd; border-right: 1px solid #ddd;font-weight: bold;font-family: 'Montserrat';">Service Charge (5%)</td>
                                                <td style="text-align: right;border-top: 1px solid #ddd;">
                                                    {{ (service.formData.subTotal * 0.05) | number :'1.2-2' }}
                                                    this.service.formData.incomeTax = (this.service.formData.subTotal * 5)/100;
                                                </td>
                                            </tr> -->
                                            <!-- <tr *ngIf="service.formData.vat">
                                                <td colspan="4" style="text-align: right;border-right: 1px solid #ddd;font-weight: 500;font-family: 'Montserrat';">VAT</td>
                                                <td style="text-align: right;font-weight: 500">{{service.formData.vat | number :'1.2-2'}}</td>
                                            </tr> -->
                                            <tr>
                                                <td colspan="4" style="text-align: right;border-right: 1px solid #ddd;font-weight: 500;font-family: 'Montserrat';">Discount</td>
                                                <td style="text-align: right;font-weight: 500">{{service.formData.discount | number :'1.2-2'}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="4" style="text-align: right;border-top: 1px solid #ddd; border-right: 1px solid #ddd;font-weight: bold;font-family: 'Montserrat';">Grand Total</td>
                                                <td style="text-align: right;border-top: 1px solid #ddd;font-weight: bold">{{service.formData.grandTotal | number :'1.2-2'}}</td>
                                            </tr>
                                            
                                        </tfoot>
                                    </table>
                 
                                </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div class="form-group">
                                    <div class="form-inner12">
                                        <label for="fname" class="left-col control-label"></label>
                                        <div class="right-col mt-org1" style="text-align:right">
                                            <button class="btn btn-success custom-btn1" type="submit" [disabled] = "service.formData.disableSubmit" >Submit</button>
                                            <button type="button" [disabled]="form.invalid" class="btn btn-default custom-btn1" name="Clear" (click)="clearData()"><i class="pg-close"></i> Clear</button>
                                        </div>
                                        <div class="clr"></div>
                                    </div>
                                </div>
                            </div>                
                            </form>   
                        </div>
                        <div class="modal fade" id="myModalReport" data-backdrop="static" data-keyboard="false" role="dialog" aria-hidden="false">
                            <div class="modal-dialog" style="width: 738px; margin-top: 60px !important;height: calc(100vh - 120px);overflow-y: auto;">
                              <div class="modal-content">  
                                <!-- Modal Header -->
                                <div class="modal-header text-left">
                                    <button type="button" class="close" (click)="reportClose()" aria-hidden="true">
                                            <i class="pg-close fs-14"></i>
                                        </button>
                                    <div class="bold">Order Preview</div>
                                </div>
                          
                                <!-- Modal body -->
                                <div class="modal-body" style="margin-top: 25px;padding: 0px;">
                                    <div id="report_viewer" *ngIf="reportOrderData" style="width: 750px;height: auto; margin: 0;display: inline-block;float: left;padding-left: 15px;">
                                        <!-- height: 950px; -->
                                        <style>
                                            .report_order_details_data .table tbody tr td, .report_order_details_data .table thead tr th, .report_order_details_data .table tfoot tr td {
                                                font-size: 12px !important;
                                            }
                                            /* .report_order_details_data .table tbody tr:last-child td {
                                                border-bottom: 1px solid #000 !important;
                                            } */
                                        </style>
                                        <div class="report_container report_viewer" style="background: #fff;padding-right: 20px;width: 235px; height: auto;margin: 0;display: inline-block;flex-direction: column;float: left;padding-top: 0px;" 
                                        *ngFor="let count of countReceipt">
                                        <div class="report_logo">
                                            <img *ngIf="selectedKitchenID == 0" src="../assets/img/hungry_rooster_logo.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;">                                            
                                            <img *ngIf="selectedKitchenID == 1" src="../assets/img/pizza-mia-logo.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;">
                                            <img *ngIf="selectedKitchenID == 2" src="../assets/img/hungry_rooster_logo.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;">
                                            <img *ngIf="selectedKitchenID == 3" src="../assets/img/pimentos-logo.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;">
                                            <img *ngIf="selectedKitchenID == 4" src="../assets/img/shukraan-g.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;">
                                            <img *ngIf="selectedKitchenID == 5" src="../assets/img/jotpot-logo.png" alt="logo" class="brand" style="height: 80px;padding-left: 8px;padding-bottom: 0px;">
                                            <img *ngIf="selectedKitchenID == 6" src="../assets/img/cleanCravings.png" alt="logo" class="brand" style="height: 50px;padding-left: 8px;padding-bottom: 0px;">
                                        </div>
                                            <div class="report_order_content" style="padding: 0 8px; margin-top: 20px;">
                                                <div style="font-weight: bold;font-size: 14px;color: #000;font-family: 'Montserrat';">Devour Limited</div>
                                                <div style="font-weight: bold;font-size: 12px;color: #000;font-family: 'Montserrat';">Sender Details</div>
                                                <div style="font-family: 'Montserrat';font-size: 12px;"><span>Order:</span><span style="font-family: Arial, sans-serif;"> ORD{{reportOrderData.orderDataModels.orderID}}</span></div>
                                                <div style="font-family: 'Montserrat';font-size: 12px;"><span>Order Date:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.orderDate | date}}</span></div>
                                                <div *ngIf="reportOrderData.orderDataModels.deliveryTime" style="font-family: 'Montserrat';font-size: 12px;"><span>Delivery Time:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.deliveryTime}}</span></div>
                                                <div style="font-family: 'Montserrat';font-size: 12px;"><span>Brand:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.kitchenName}}</span></div>
                                                <div style="font-family: 'Montserrat';font-size: 12px;"><span>Order Type:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.orderTypeName}}</span></div>
                                                <div style="font-family: 'Montserrat';font-size: 12px;"><span>VAT Reg:</span><span style="font-family: Arial, sans-serif;"> 002906561-0101</span></div>
                                                <div style="font-family: 'Montserrat';font-size: 12px;"><span>MUSAK:</span><span style="font-family: Arial, sans-serif;"> 6.3</span></div>

                                                <div style="font-weight: bold;font-size: 12px;color: #000;font-family: 'Montserrat';margin-top: 20px;">Recipient Details</div>
                                                <div style="font-family: 'Montserrat';font-size: 12px;"><span>Name:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.customerName}}</span></div>
                                                <div style="font-family: 'Montserrat';font-size: 12px;"><span>Mobile:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.customerMobile}}</span></div>
                                                <div style="font-family: 'Montserrat';font-size: 12px;"><span>Address:</span><span style="font-family: Arial, sans-serif;"> {{reportOrderData.orderDataModels.fullAddress}}</span></div>
                                                
                                            </div>
                                            <div class="report_order_details_content" style="display: flex;flex-direction: column;justify-content: space-between;height: auto;">
                                                <div class="report_order_details_data">
                                                    <table id="example" class="table" style="width:100%; margin-top: 6px;background: #fff;border-spacing: 0;border-collapse: collapse;" *ngIf="reportOrderData">
                                                        <thead>
                                                            <tr>
                                                                <th style="width: 45%;font-weight: bold;color: #000;text-transform: uppercase;border-bottom: 1px solid #000;text-align: left;font-family: 'Montserrat';padding: 10px 50px !important;">Item</th>
                                                                <!-- <th style="font-weight: bold;color: #000;text-transform: uppercase;border-bottom: 1px solid #000;text-align: left;font-family: 'Montserrat';">Item Type</th> -->
                                                                <th style="width: 5%; font-weight: bold;color: #000;text-transform: uppercase;border-bottom: 1px solid #000;text-align: left;font-family: 'Montserrat';padding: 10px 0px !important;">Qty</th>
                                                                <th style="width: 15%; text-align: right;font-weight: bold;color: #000;text-transform: uppercase;border-bottom: 1px solid #000;text-align: right;font-family: 'Montserrat';padding: 10px 0px !important;">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let c of reportOrderData.orderDetailsDataModels">
                                                                <td style="width: 45%;border-bottom: none;font-size:small;font-family: Arial, sans-serif;padding: 5px 6px !important;">{{c.itemNameNflavour}}<span *ngIf="service.formData.kitchenID == 1">{{c.ItemType}} </span></td>
                                                                <!-- <td style="border-bottom: 1px solid #000;font-family: Arial, sans-serif;">{{c.itemType}}</td> -->
                                                                <td style="width: 10%;border-bottom: none;font-family: Arial, sans-serif;padding: 5px 6px !important;">{{c.quantity}}</td>
                                                                <td style="width: 25%;text-align: right;border-bottom: none;font-family: Arial, sans-serif;padding: 10px 6px !important;">{{c.unitRate | number: '1.2-2'}}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <!-- <td colspan="1" style="border-bottom: none;border-top: none;"></td> -->
                                                                <td colspan="2" style="text-align: left;border-bottom: none;border-top: 1px solid #000 !important;font-family: 'Montserrat';padding: 8px !important;">Sub Total</td>
                                                                <td style="text-align: right;border-bottom: none;border-top: 1px solid #000 !important;padding: 8px !important;">{{reportOrderData.orderDataModels.subTotal | number: '1.2-2'}}</td>
                                                            </tr>
                                                            <tr class="">
                                                                <td colspan="2" style="padding: 8px !important;font-weight: normal;text-align: left;border-top: 1px solid #000;font-family: 'Montserrat';">VAT (5%)</td>
                                                                <td style="padding: 8px !important;font-weight: normal;text-align: right;border-top: 1px solid #000;">{{reportOrderData.orderDataModels.incomeTax | number: '1.2-2'}}</td>
                                                            </tr>
                                                           
                                                            <tr *ngIf="reportOrderData.orderDataModels.deliveryCharge">
                                                                <!-- <td colspan="1" style="border-bottom: none;border-top: none;"></td> -->
                                                                <td colspan="2" style="text-align: left;border-bottom: none;border-top: none;font-family: 'Montserrat';padding: 8px !important;">Delivery Charge</td>
                                                                <td style="text-align: right;border-bottom: none;border-top: none;padding: 8px !important;">{{reportOrderData.orderDataModels.deliveryCharge | number: '1.2-2'}}</td>
                                                            </tr>
                                                            <tr *ngIf="reportOrderData.orderDataModels.serviceCharge">
                                                                <td colspan="2" style="padding: 8px !important;font-weight: normal;text-align: left;border-top: 1px solid #000;font-family: 'Montserrat';">Service Charge(7.5%)</td>
                                                                <td style="padding: 8px !important;font-weight: normal;text-align: right;border-top: 1px solid #000;">{{reportOrderData.orderDataModels.serviceCharge | number: '1.2-2'}}</td>
                                                            </tr>
                                                            <!-- <tr *ngIf="reportOrderData.orderType == 8">
                                                                
                                                                <td colspan="2" style="text-align: left;border-bottom: none;border-top: none;font-family: 'Montserrat';padding: 8px !important;">Service Charge(5%)</td>
                                                                <td style="text-align: right;border-bottom: none;border-top: none;padding: 8px !important;">{{reportOrderData.grandTotal *0.05 | number: '1.2-2'}} </td>
                                                            </tr> -->
                                                            <tr *ngIf="reportOrderData.orderDataModels.discount">
                                                                <td colspan="2" style="text-align: left;border-bottom: none;border-top: 1px solid #000;font-family: 'Montserrat';padding: 8px !important;">Total</td>
                                                                <td style="text-align: right;border-bottom: none;border-top: 1px solid #000;padding: 8px !important;">{{reportOrderData.orderDataModels.total | number: '1.2-2'}}</td>
                                                                <!-- <td colspan="1" style="border-bottom: none;border-top: none;"></td> -->                                                       
                                                            </tr>
                                                            <tr *ngIf="reportOrderData.orderDataModels.discount">
                                                                <!-- <td colspan="1" style="border-bottom: none;border-top: none;"></td> -->
                                                                <td colspan="2" style="text-align: left;border-bottom: none;border-top: none;font-family: 'Montserrat';padding: 8px !important;">Discount ( - )</td>
                                                                <td style="text-align: right;border-bottom: none;border-top: none;padding: 8px !important;">{{reportOrderData.orderDataModels.discount | number: '1.2-2'}}</td>
                                                            </tr>
                                                            <tr >
                                                                <!-- <td colspan="1" style="border-bottom: none;border-top: none;"></td> -->
                                                                <td colspan="2" style="padding: 8px !important;font-weight: bold;text-align: left;border-bottom: 1px solid #000;border-top: 1px solid #000;font-family: 'Montserrat';">Grand Total</td>
                                                                <td style="padding: 8px !important;font-weight: bold;text-align: right;border-bottom: 1px solid #000;border-top: 1px solid #000;">{{reportOrderData.orderDataModels.grandTotal | number: '1.2-2'}}</td>
                                                            </tr>
                                                            <!-- <tr class="hidden">
                                                                <td colspan="3">All Prices Are Inclusive Of 5% Vat.</td>
                                                            </tr> -->
                                                            
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>                                            
                                        </div>
                                        <span>.</span>
                                        <div class="comments" *ngIf="reportOrderData.orderDataModels.comments">                                            
                                            <div style="font-weight: bold;font-size: 12px;color: #000;font-family: 'Montserrat';margin-top: 5px;"><span>Comments:</span></div>
                                            <div class="content-font"><span>{{reportOrderData.orderDataModels.comments}}</span></div>
                                        </div>                                            
                                    </div>
                                </div>
                          
                                <!-- Modal footer -->
                                <div class="modal-footer" style="width: 300px;padding: 0px 0px 25px 0px;margin: 0 auto;padding-top: 25px !important;display: flex; flex-direction: column;">
                                    <button type="button" class="btn btn-success" (click)="captureScreen()" id="report" style="width: 100%;margin-bottom: 15px;margin-right: 0px;margin-left: 0px;">Print</button>
                                    <button type="button" class="btn btn-danger" (click)="reportClose()" style="width: 100%;margin-bottom: 15px;margin-right: 0px;margin-left: 0px;">Close</button>  
                                    <!-- this button is hidden, used to close from typescript -->
                                    <button type="button" id="closeModalReport" data-dismiss="modal" style="display: none">Close2</button>                                
                                </div>
                              </div>
                            </div>
                        </div>

                        <!--Choose Flavour Model Start-->
                        <div class="modal fade" id="flavourModal" data-backdrop="static" data-keyboard="false" role="dialog" aria-hidden="false">
                            <div class="modal-dialog" style="width: 738px; margin-top: 60px !important;height: calc(100vh - 120px);overflow-y: auto;">
                              <div class="modal-content">  
                                <!-- Modal Header -->
                                <div class="modal-header text-left">
                                    <button type="button" class="close" (click)="flavourModalClose()" aria-hidden="true">
                                            <i class="pg-close fs-14"></i>
                                        </button>
                                    <div class="bold">Choose Flavour For <span style="color:coral">{{selectedItemName}}</span> </div>
                                    <div class="bold">Existing Flavour : {{selectedItemNameFlavour}}</div>
                                </div>
                          
                                <!-- Modal body -->
                                <div class="modal-body" style="margin-top: 25px;padding: 0px;">
                                    <table class="table table-striped table-bordered" style="margin-top: 0px;">
                                        <thead>
                                            <tr>
                                                <th style="width: 10%; text-align: center">Select</th>
                                                <th>Flavour</th>
                                                <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let row of flavourList; let indx = index">
                                                <td style="text-align: center">
                                                    <div class="checkbox_vertical">
                                                        <div class="checkbox check-primary checkbox-circle">
                                                            <input type="checkbox" id="checkboxFL{{indx+1}}" [value]= "row.selected" [(ngModel)]="row.selected" (change)="createFlavourData(indx, row)">
                                                            <label for="checkboxFL{{indx +1}}"></label>
                                                        </div>
                                                    </div>                
                                                </td>
                                                <td>{{row.flavour}} <span>{{row.description}}</span></td>
                                                <td style="text-align: right">
                                                    <input type="text" class="form-control"  [(ngModel)]="row.flavourQty" (change)="changeFlavourQty(indx, row)" required>
                                                </td>
                                            </tr>
                                        </tbody>                                        
                                    </table> 
                                </div>                          
                                <!-- Modal footer -->
                                <div class="modal-footer" style="width: 300px;padding: 0px 0px 25px 0px;margin: 0 auto;padding-top: 25px !important;display: flex; flex-direction: column;">                                   
                                  <!-- this button is hidden, used to close from typescript -->
                                  <button type="button" id="closeFlavourModal" data-dismiss="modal" style="display: none">Close</button>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                

</div>
</div>
<!-- Start Body -->
</div>
</div>


<script src="https://code.jquery.com/jquery-3.6.0.min.js"  integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous"></script>

<script src="src/assets/js/custom.js"></script>
