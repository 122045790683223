import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appConfirmequalvalidator]'
})
export class ConfirmequalvalidatorDirective  implements Validator {
  @Input() appConfirmEqualValidator: string;
  validate(control: AbstractControl): { [key: string]: any } | null {
    debugger
    console.log(this.appConfirmEqualValidator);
      const controlToCompare = control.parent.get(this.appConfirmEqualValidator);
      if (controlToCompare && controlToCompare.value !== control.value) {
          return { 'notEqual': true };
      }

      return null;
  }

}
