import { Injectable } from '@angular/core';
import { GlobalVariable } from '../Classes/global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  readonly rootURL = GlobalVariable.ONLINE_CORE_URL;
  constructor(private http: HttpClient) { }
  formData: any;
  statusModel: any = [];
  insesrtData: any;
  users: any;
  updateData: any;
  updateBodyModel:any=[];
  getAllUserInformation() {
    //debugger;
    let response = this.http.get(this.rootURL + 'user/GetAllUserInformation');
    response.toPromise()
      .then(res => this.users = res);
    return response;
  }

  UpdateUserStatus(iD, isactive) {
    this.statusModel = [];

    this.statusModel = {
      IsActive: isactive
    };

    let response = this.http.put(this.rootURL + 'user/UpdateUserStatus/' + iD, this.statusModel)
    return response;
  }

  getAllUserGroup() {
    let response = this.http.get(this.rootURL + 'user/GetAllUserGroup')
    return response;
  }
  insertUser() {
    let response = this.http.post(this.rootURL + 'user/UserRegistration', this.insesrtData)
    return response;
  }
  updateUserInfo(id, userUpdateBodyModel) {
    debugger
    this.updateBodyModel=[]
this.updateBodyModel={
  Id:userUpdateBodyModel.id,
  UserName:userUpdateBodyModel.userName,
  UserGroupID:userUpdateBodyModel.userGroupID
}

    let response = this.http.put(this.rootURL + 'user/updateUserInfo/' + id, this.updateBodyModel);
    return response;
  }
}
