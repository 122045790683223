import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { CustomerService } from 'src/app/Services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

  clDisabled : boolean = true;
  loginValue: any;
  counter: any;
  userId: number=0;
  today = new Date();
  currentDateTime: any;
  expirationTime: any;

  constructor(private router: Router
    ,public service: CustomerService
    ,private toastr: ToastrService) {
      this.currentDateTime = formatDate(this.today, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
     }

  ngOnInit(): void {
    debugger
    this.counter = JSON.parse(localStorage.getItem("pageCounter"));
      if (this.counter == 0) {
        location.reload();
        localStorage.removeItem("pageCounter");
      }
    this.resetForm();

    this.loginValue = JSON.parse(localStorage.getItem("isLogin"));
    if (this.loginValue != null) {
      this.expirationTime = formatDate(this.loginValue.expirationTime, 'yyyy-MM-dd HH:mm:ss', 'en-US', '+0600');
      if (this.expirationTime > this.currentDateTime) {
        this.userId = this.loginValue.employeeID;
      }
      else {
        this.logoutFunction();
      }

    }
    else {
      this.router.navigate(["login"]);
    }
  }
  onSubmit(form: NgForm){
    debugger
    if (this.service.formData.customerID > 0) {
        this.service.formData.UpdatedBy = this.userId;
        this.updateCustomerInformation(form);
    } 
  }
  updateCustomerInformation(form: NgForm){
    this.service.updateCustomerInformation().subscribe(res=>{
      this.toastr.success("Update Successfully");
      debugger
      this.clearData(); 
      
    },
    err=>
    {
      this.toastr.error(err);
    } 
    )
  }
  customerName(){
    if (this.service.formData.name && this.service.formData.mobile)
        {
            this.clDisabled = false;
        } else {
            this.clDisabled = true;
        }
  }
  _keyPress(event: any) {
    debugger
    // const pattern = /[0-9\+\-\ ]/;
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();      
    }
    this.customerName();
  }
  clearData(){
    this.service.formData = null;
    this.resetForm();
  }
  resetForm(form?: NgForm) {

    if (this.service.formData == null) {
      this.service.formData = {
        customerID: 0,
        name: "",
        mobile: "",
        flatNo: "",
        houseNo: "",
        apartmentNo: "",
        roadNo: "",
        area: "",
        UpdatedBy: 0
      }
    }
  }
  logoutFunction(){
    localStorage.removeItem("isLogin");
    localStorage.clear();
    this.router.navigate(["login"]);
      
  }
  commonRedirectionFunction() {
    this.router.navigate(["CustomerList"]);
  }

}
